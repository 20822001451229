import { Container, Grid, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import { Facebook, Twitter } from '@material-ui/icons';
import { Discord } from 'mdi-material-ui';
import * as React from 'react';
import { Link } from 'react-router-dom';
import AppStoreBadge from '../../assets/app-store.svg';
import GooglePlayBadge from '../../assets/google-play.svg';
import { StyledAppDefaultMargins } from '../shared/app-main-container.styles';
import SiteLogo from '../site.logo';
import SiteName from '../site.name';
import {
    StyledAppLink,
    StyledFooter,
    StyledFooterContent,
    StyledLogoText,
    StyledSiteInfoContainer,
    StyledSocialMediaContainer,
} from './styles';

//#region : Helper local function components to reduce main component code length

/** Custom. Returns copyright text */
function Copyright() {
    return (
        <Typography align="left">
            {'Copyright © '}
            <Link to="/">Wuxiaworld</Link> {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

/** Custom. Helper function to create list links */
function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}
//#endregion : Helper local function components to reduce main component code length

//#region : Main component

/**
 * Custom.
 *
 * Footer contains site logo, name, links to social media,
 * android & ios app and other misc site links.
 */
function Footer() {
    return (
        <footer css={StyledFooter}>
            <Container css={StyledAppDefaultMargins}>
                <div css={StyledFooterContent}>
                    <div css={StyledSiteInfoContainer}>
                        <Typography css={StyledLogoText} component="h2" variant="h4" align="center" noWrap>
                            <SiteLogo /> <SiteName />
                        </Typography>
                        <Grid container spacing={2} css={StyledSocialMediaContainer}>
                            <Grid item>
                                <a
                                    href="https://www.facebook.com/WuxiaworldWebnovels/"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    aria-label="Facebook"
                                >
                                    <Facebook aria-hidden />
                                </a>
                            </Grid>
                            <Grid item>
                                <a
                                    href="https://twitter.com/Wuxiaworld_Ltd"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    aria-label="Twitter"
                                >
                                    <Twitter aria-hidden />
                                </a>
                            </Grid>
                            <Grid item>
                                <a
                                    href="https://discord.gg/wuxiaworld"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    aria-label="Discord"
                                >
                                    <Discord aria-hidden />
                                </a>
                            </Grid>
                        </Grid>
                        <Copyright />
                    </div>
                    <div>
                        <a
                            css={StyledAppLink}
                            href="https://apps.apple.com/us/app/wuxiaworld/id1503356244?mt=8"
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            <img
                                loading="lazy"
                                src={AppStoreBadge}
                                alt="Get it on the App Store"
                                height="60"
                                width="202"
                            />
                        </a>
                        <a
                            css={StyledAppLink}
                            href="https://play.google.com/store/apps/details?id=com.wuxiaworld.mobile&hl=en_US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            <img
                                loading="lazy"
                                src={GooglePlayBadge}
                                alt="Get it on Google Play"
                                height="60"
                                width="202"
                            />
                        </a>
                    </div>
                    <div>
                        <Grid container>
                            <Grid item>
                                <List component="div">
                                    <ListItemLink component={Link} to="/about">
                                        <ListItemText primary="About Us" />
                                    </ListItemLink>
                                    <ListItemLink component={Link} to="/contact-us">
                                        <ListItemText primary="Contact Us" />
                                    </ListItemLink>
                                    <ListItemLink component={Link} to="/news">
                                        <ListItemText primary="Announcements" />
                                    </ListItemLink>
                                </List>
                            </Grid>
                            <Grid item>
                                <List component="div">
                                    <ListItemLink component={Link} to="/general-faq">
                                        <ListItemText primary="General FAQ" />
                                    </ListItemLink>
                                    <ListItemLink component={Link} to="/terms-of-service">
                                        <ListItemText primary="Terms of Service" />
                                    </ListItemLink>
                                    <ListItemLink component={Link} to="/terms-of-service-and-privacy-policy">
                                        <ListItemText primary="Privacy Policy" />
                                    </ListItemLink>
                                </List>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </Container>
        </footer>
    );
}

/**
 * Custom.
 *
 * Footer contains site logo, name, links to social media,
 * android & ios app and other misc site links.
 */
export default React.memo(Footer);

//#endregion : Main component
