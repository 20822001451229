// source: Protos/vips.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var Protos_types_pb = require('../Protos/types_pb.js');
goog.object.extend(proto, Protos_types_pb);
var Protos_products_pb = require('../Protos/products_pb.js');
goog.object.extend(proto, Protos_products_pb);
goog.exportSymbol('proto.wuxiaworld.api.v2.GetVipPackagesResponse', null, global);
goog.exportSymbol('proto.wuxiaworld.api.v2.VipItem', null, global);
goog.exportSymbol('proto.wuxiaworld.api.v2.VipItem.VipBenefits', null, global);
goog.exportSymbol('proto.wuxiaworld.api.v2.VipItem.VipType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wuxiaworld.api.v2.GetVipPackagesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wuxiaworld.api.v2.GetVipPackagesResponse.repeatedFields_, null);
};
goog.inherits(proto.wuxiaworld.api.v2.GetVipPackagesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wuxiaworld.api.v2.GetVipPackagesResponse.displayName = 'proto.wuxiaworld.api.v2.GetVipPackagesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wuxiaworld.api.v2.VipItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wuxiaworld.api.v2.VipItem.repeatedFields_, null);
};
goog.inherits(proto.wuxiaworld.api.v2.VipItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wuxiaworld.api.v2.VipItem.displayName = 'proto.wuxiaworld.api.v2.VipItem';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wuxiaworld.api.v2.GetVipPackagesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wuxiaworld.api.v2.GetVipPackagesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wuxiaworld.api.v2.GetVipPackagesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wuxiaworld.api.v2.GetVipPackagesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.GetVipPackagesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.wuxiaworld.api.v2.VipItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wuxiaworld.api.v2.GetVipPackagesResponse}
 */
proto.wuxiaworld.api.v2.GetVipPackagesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wuxiaworld.api.v2.GetVipPackagesResponse;
  return proto.wuxiaworld.api.v2.GetVipPackagesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wuxiaworld.api.v2.GetVipPackagesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wuxiaworld.api.v2.GetVipPackagesResponse}
 */
proto.wuxiaworld.api.v2.GetVipPackagesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.wuxiaworld.api.v2.VipItem;
      reader.readMessage(value,proto.wuxiaworld.api.v2.VipItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wuxiaworld.api.v2.GetVipPackagesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wuxiaworld.api.v2.GetVipPackagesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wuxiaworld.api.v2.GetVipPackagesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.GetVipPackagesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.wuxiaworld.api.v2.VipItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated VipItem items = 1;
 * @return {!Array<!proto.wuxiaworld.api.v2.VipItem>}
 */
proto.wuxiaworld.api.v2.GetVipPackagesResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.wuxiaworld.api.v2.VipItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wuxiaworld.api.v2.VipItem, 1));
};


/**
 * @param {!Array<!proto.wuxiaworld.api.v2.VipItem>} value
 * @return {!proto.wuxiaworld.api.v2.GetVipPackagesResponse} returns this
*/
proto.wuxiaworld.api.v2.GetVipPackagesResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.wuxiaworld.api.v2.VipItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wuxiaworld.api.v2.VipItem}
 */
proto.wuxiaworld.api.v2.GetVipPackagesResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.wuxiaworld.api.v2.VipItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wuxiaworld.api.v2.GetVipPackagesResponse} returns this
 */
proto.wuxiaworld.api.v2.GetVipPackagesResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wuxiaworld.api.v2.VipItem.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wuxiaworld.api.v2.VipItem.prototype.toObject = function(opt_includeInstance) {
  return proto.wuxiaworld.api.v2.VipItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wuxiaworld.api.v2.VipItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.VipItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    planid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: (f = msg.getDescription()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    price: (f = msg.getPrice()) && Protos_types_pb.DecimalValue.toObject(includeInstance, f),
    interval: jspb.Message.getFieldWithDefault(msg, 6, 0),
    enabled: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    visible: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    type: jspb.Message.getFieldWithDefault(msg, 9, 0),
    benefitsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    product: (f = msg.getProduct()) && Protos_products_pb.ProductItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wuxiaworld.api.v2.VipItem}
 */
proto.wuxiaworld.api.v2.VipItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wuxiaworld.api.v2.VipItem;
  return proto.wuxiaworld.api.v2.VipItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wuxiaworld.api.v2.VipItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wuxiaworld.api.v2.VipItem}
 */
proto.wuxiaworld.api.v2.VipItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlanid(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 5:
      var value = new Protos_types_pb.DecimalValue;
      reader.readMessage(value,Protos_types_pb.DecimalValue.deserializeBinaryFromReader);
      msg.setPrice(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInterval(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVisible(value);
      break;
    case 9:
      var value = /** @type {!proto.wuxiaworld.api.v2.VipItem.VipType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 10:
      var values = /** @type {!Array<!proto.wuxiaworld.api.v2.VipItem.VipBenefits>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addBenefits(values[i]);
      }
      break;
    case 11:
      var value = new Protos_products_pb.ProductItem;
      reader.readMessage(value,Protos_products_pb.ProductItem.deserializeBinaryFromReader);
      msg.setProduct(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wuxiaworld.api.v2.VipItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wuxiaworld.api.v2.VipItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wuxiaworld.api.v2.VipItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.VipItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPlanid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPrice();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Protos_types_pb.DecimalValue.serializeBinaryToWriter
    );
  }
  f = message.getInterval();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getEnabled();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getVisible();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getBenefitsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      10,
      f
    );
  }
  f = message.getProduct();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      Protos_products_pb.ProductItem.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.wuxiaworld.api.v2.VipItem.VipType = {
  SILVER: 0,
  GOLD: 1,
  DIAMOND: 2
};

/**
 * @enum {number}
 */
proto.wuxiaworld.api.v2.VipItem.VipBenefits = {
  NONE: 0,
  NOADS: 1,
  FREENOVEL: 2,
  COMPLETEDNOVELS: 3,
  NOVELVOUCHER: 4
};

/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.wuxiaworld.api.v2.VipItem.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wuxiaworld.api.v2.VipItem} returns this
 */
proto.wuxiaworld.api.v2.VipItem.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.wuxiaworld.api.v2.VipItem.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wuxiaworld.api.v2.VipItem} returns this
 */
proto.wuxiaworld.api.v2.VipItem.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string planId = 3;
 * @return {string}
 */
proto.wuxiaworld.api.v2.VipItem.prototype.getPlanid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wuxiaworld.api.v2.VipItem} returns this
 */
proto.wuxiaworld.api.v2.VipItem.prototype.setPlanid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.StringValue description = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.wuxiaworld.api.v2.VipItem.prototype.getDescription = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.wuxiaworld.api.v2.VipItem} returns this
*/
proto.wuxiaworld.api.v2.VipItem.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.VipItem} returns this
 */
proto.wuxiaworld.api.v2.VipItem.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.VipItem.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional customTypes.DecimalValue price = 5;
 * @return {?proto.customTypes.DecimalValue}
 */
proto.wuxiaworld.api.v2.VipItem.prototype.getPrice = function() {
  return /** @type{?proto.customTypes.DecimalValue} */ (
    jspb.Message.getWrapperField(this, Protos_types_pb.DecimalValue, 5));
};


/**
 * @param {?proto.customTypes.DecimalValue|undefined} value
 * @return {!proto.wuxiaworld.api.v2.VipItem} returns this
*/
proto.wuxiaworld.api.v2.VipItem.prototype.setPrice = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.VipItem} returns this
 */
proto.wuxiaworld.api.v2.VipItem.prototype.clearPrice = function() {
  return this.setPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.VipItem.prototype.hasPrice = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int32 interval = 6;
 * @return {number}
 */
proto.wuxiaworld.api.v2.VipItem.prototype.getInterval = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.wuxiaworld.api.v2.VipItem} returns this
 */
proto.wuxiaworld.api.v2.VipItem.prototype.setInterval = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional bool enabled = 7;
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.VipItem.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wuxiaworld.api.v2.VipItem} returns this
 */
proto.wuxiaworld.api.v2.VipItem.prototype.setEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool visible = 8;
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.VipItem.prototype.getVisible = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wuxiaworld.api.v2.VipItem} returns this
 */
proto.wuxiaworld.api.v2.VipItem.prototype.setVisible = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional VipType type = 9;
 * @return {!proto.wuxiaworld.api.v2.VipItem.VipType}
 */
proto.wuxiaworld.api.v2.VipItem.prototype.getType = function() {
  return /** @type {!proto.wuxiaworld.api.v2.VipItem.VipType} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.wuxiaworld.api.v2.VipItem.VipType} value
 * @return {!proto.wuxiaworld.api.v2.VipItem} returns this
 */
proto.wuxiaworld.api.v2.VipItem.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * repeated VipBenefits benefits = 10;
 * @return {!Array<!proto.wuxiaworld.api.v2.VipItem.VipBenefits>}
 */
proto.wuxiaworld.api.v2.VipItem.prototype.getBenefitsList = function() {
  return /** @type {!Array<!proto.wuxiaworld.api.v2.VipItem.VipBenefits>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<!proto.wuxiaworld.api.v2.VipItem.VipBenefits>} value
 * @return {!proto.wuxiaworld.api.v2.VipItem} returns this
 */
proto.wuxiaworld.api.v2.VipItem.prototype.setBenefitsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {!proto.wuxiaworld.api.v2.VipItem.VipBenefits} value
 * @param {number=} opt_index
 * @return {!proto.wuxiaworld.api.v2.VipItem} returns this
 */
proto.wuxiaworld.api.v2.VipItem.prototype.addBenefits = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wuxiaworld.api.v2.VipItem} returns this
 */
proto.wuxiaworld.api.v2.VipItem.prototype.clearBenefitsList = function() {
  return this.setBenefitsList([]);
};


/**
 * optional ProductItem product = 11;
 * @return {?proto.wuxiaworld.api.v2.ProductItem}
 */
proto.wuxiaworld.api.v2.VipItem.prototype.getProduct = function() {
  return /** @type{?proto.wuxiaworld.api.v2.ProductItem} */ (
    jspb.Message.getWrapperField(this, Protos_products_pb.ProductItem, 11));
};


/**
 * @param {?proto.wuxiaworld.api.v2.ProductItem|undefined} value
 * @return {!proto.wuxiaworld.api.v2.VipItem} returns this
*/
proto.wuxiaworld.api.v2.VipItem.prototype.setProduct = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.VipItem} returns this
 */
proto.wuxiaworld.api.v2.VipItem.prototype.clearProduct = function() {
  return this.setProduct(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.VipItem.prototype.hasProduct = function() {
  return jspb.Message.getField(this, 11) != null;
};


goog.object.extend(exports, proto.wuxiaworld.api.v2);
