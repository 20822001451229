import AbortController from 'abort-controller';
import { Message } from 'google-protobuf';
import type { Empty } from 'google-protobuf/google/protobuf/empty_pb';
import * as grpc from 'grpc-web';
import { useCallback } from 'react';
import { useAuth } from './auth';
import fetch from './fetch';
import { GrpcClientType, GrpcError, grpcRequest, GrpcRequestFunc } from './grpc';

/**
 * Custom Hook.
 *
 * Use it when you want to directly handle the api request
 * and want to send user authentication token too.
 *
 * Use 'fetch' to deal with old API and use 'grpcRequest' to deal with, well, grpc :D
 */
export const useHttp = () => {
    const { logout, user } = useAuth();

    const fetchImpl = useCallback(
        async function <T>(input: RequestInfo, init?: RequestInit): Promise<T> {
            let requestHeaders: HeadersInit = {
                ...(init?.headers || {}),
                'Content-Type': 'application/json',
            };

            if (typeof input === 'string' && input.startsWith('/')) {
                input = `${process.env.REACT_APP_BASE_URL}${input}`;
            }

            if (typeof input === 'string' && input.startsWith(process.env.REACT_APP_BASE_URL!)) {
                if (user?.accessToken) {
                    requestHeaders = {
                        Authorization: `Bearer ${user.accessToken}`,
                        ...requestHeaders,
                    };
                }
            }

            return await fetch<T>(input, {
                ...init,
                headers: requestHeaders,
            });
        },
        [user?.accessToken]
    );

    /** Custom. */
    const grpcRequestImpl = useCallback(
        async function <TClient, TRequest extends Message = Empty, TResponse extends Message = Empty>(
            Client: GrpcClientType<TClient>,
            methodDefinition: (client: TClient) => GrpcRequestFunc<TRequest, TResponse>,
            request: TRequest,
            metadata: grpc.Metadata | null = null
        ) {
            let requestMetadata: grpc.Metadata = metadata || {};

            if (user?.accessToken) {
                requestMetadata = {
                    ...requestMetadata,
                    Authorization: `Bearer ${user.accessToken}`,
                };
            }

            try {
                return await grpcRequest<TClient, TRequest, TResponse>(
                    Client,
                    methodDefinition,
                    request,
                    requestMetadata
                );
            } catch (e) {
                // If we have an access token and we receive an unauthenticated response, logout the user
                // as the token has most likely expired

                if (user?.accessToken && e instanceof GrpcError && e.status === grpc.StatusCode.UNAUTHENTICATED) {
                    await logout();
                }

                throw e;
            }
        },
        [user?.accessToken, logout]
    );

    return {
        fetch: fetchImpl,
        grpcRequest: grpcRequestImpl,
    };
};

//#region : Download File

/** Custom type. */
type ProgressEvent = { bytesRead: number; totalBytes: number };

/** Custom type. */
type ProgressEventCallback = (evt: ProgressEvent) => void;

/** Custom type. Used as parameter type by 'downloadFile' function. */
export type DownloadResult = {
    promise: Promise<void>;
    abort: () => void;
};

/**
 * Custom function.
 *
 * Use it when you want to download file from a given url and get download progress, if you need it.
 * Allows aborting the download.
 */
export const downloadFile = (url: string, fileName: string, onProgress?: ProgressEventCallback): DownloadResult => {
    const abortController = new AbortController();

    const promise = new Promise<void>((resolve, reject) => {
        var xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.responseType = 'arraybuffer';

        const abort = () => {
            xhr.abort();
        };

        xhr.onload = () => {
            const blob = new Blob([xhr.response], { type: 'application/octet-stream' });
            const a = document.createElement('a');
            document.body.appendChild(a);

            const url = URL.createObjectURL(blob);
            a.href = url;
            a.download = fileName;
            a.click();

            URL.revokeObjectURL(url);

            a.remove();

            resolve();
        };

        xhr.onerror = () => {
            reject(new Error('Network Error'));
        };

        xhr.onabort = () => {
            reject(new Error('Aborted'));
        };

        xhr.onprogress = ({ loaded, total }) => {
            onProgress?.({ bytesRead: loaded, totalBytes: total });
        };

        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE) {
                abortController.signal.removeEventListener('abort', abort);
            }
        };

        abortController.signal.addEventListener('abort', abort);

        xhr.send();
    });

    return {
        abort: () => abortController.abort(),
        promise,
    };
};
//#endregion : Download File
