/**
 * @fileoverview gRPC-Web generated client stub for wuxiaworld.api.v2
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var Protos_types_pb = require('../Protos/types_pb.js')

var Protos_chapters_pb = require('../Protos/chapters_pb.js')

var Protos_sponsors_pb = require('../Protos/sponsors_pb.js')

var Protos_users_pb = require('../Protos/users_pb.js')

var Protos_pagination_pb = require('../Protos/pagination_pb.js')
const proto = {};
proto.wuxiaworld = {};
proto.wuxiaworld.api = {};
proto.wuxiaworld.api.v2 = require('./novels_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.wuxiaworld.api.v2.NovelsClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.wuxiaworld.api.v2.NovelsPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.wuxiaworld.api.v2.SearchNovelsRequest,
 *   !proto.wuxiaworld.api.v2.SearchNovelsResponse>}
 */
const methodDescriptor_Novels_SearchNovels = new grpc.web.MethodDescriptor(
  '/wuxiaworld.api.v2.Novels/SearchNovels',
  grpc.web.MethodType.UNARY,
  proto.wuxiaworld.api.v2.SearchNovelsRequest,
  proto.wuxiaworld.api.v2.SearchNovelsResponse,
  /**
   * @param {!proto.wuxiaworld.api.v2.SearchNovelsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.wuxiaworld.api.v2.SearchNovelsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.wuxiaworld.api.v2.SearchNovelsRequest,
 *   !proto.wuxiaworld.api.v2.SearchNovelsResponse>}
 */
const methodInfo_Novels_SearchNovels = new grpc.web.AbstractClientBase.MethodInfo(
  proto.wuxiaworld.api.v2.SearchNovelsResponse,
  /**
   * @param {!proto.wuxiaworld.api.v2.SearchNovelsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.wuxiaworld.api.v2.SearchNovelsResponse.deserializeBinary
);


/**
 * @param {!proto.wuxiaworld.api.v2.SearchNovelsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.wuxiaworld.api.v2.SearchNovelsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.wuxiaworld.api.v2.SearchNovelsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.wuxiaworld.api.v2.NovelsClient.prototype.searchNovels =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/wuxiaworld.api.v2.Novels/SearchNovels',
      request,
      metadata || {},
      methodDescriptor_Novels_SearchNovels,
      callback);
};


/**
 * @param {!proto.wuxiaworld.api.v2.SearchNovelsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.wuxiaworld.api.v2.SearchNovelsResponse>}
 *     Promise that resolves to the response
 */
proto.wuxiaworld.api.v2.NovelsPromiseClient.prototype.searchNovels =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/wuxiaworld.api.v2.Novels/SearchNovels',
      request,
      metadata || {},
      methodDescriptor_Novels_SearchNovels);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.wuxiaworld.api.v2.GetNovelRequest,
 *   !proto.wuxiaworld.api.v2.GetNovelResponse>}
 */
const methodDescriptor_Novels_GetNovel = new grpc.web.MethodDescriptor(
  '/wuxiaworld.api.v2.Novels/GetNovel',
  grpc.web.MethodType.UNARY,
  proto.wuxiaworld.api.v2.GetNovelRequest,
  proto.wuxiaworld.api.v2.GetNovelResponse,
  /**
   * @param {!proto.wuxiaworld.api.v2.GetNovelRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.wuxiaworld.api.v2.GetNovelResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.wuxiaworld.api.v2.GetNovelRequest,
 *   !proto.wuxiaworld.api.v2.GetNovelResponse>}
 */
const methodInfo_Novels_GetNovel = new grpc.web.AbstractClientBase.MethodInfo(
  proto.wuxiaworld.api.v2.GetNovelResponse,
  /**
   * @param {!proto.wuxiaworld.api.v2.GetNovelRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.wuxiaworld.api.v2.GetNovelResponse.deserializeBinary
);


/**
 * @param {!proto.wuxiaworld.api.v2.GetNovelRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.wuxiaworld.api.v2.GetNovelResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.wuxiaworld.api.v2.GetNovelResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.wuxiaworld.api.v2.NovelsClient.prototype.getNovel =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/wuxiaworld.api.v2.Novels/GetNovel',
      request,
      metadata || {},
      methodDescriptor_Novels_GetNovel,
      callback);
};


/**
 * @param {!proto.wuxiaworld.api.v2.GetNovelRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.wuxiaworld.api.v2.GetNovelResponse>}
 *     Promise that resolves to the response
 */
proto.wuxiaworld.api.v2.NovelsPromiseClient.prototype.getNovel =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/wuxiaworld.api.v2.Novels/GetNovel',
      request,
      metadata || {},
      methodDescriptor_Novels_GetNovel);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.wuxiaworld.api.v2.GetNovelsRequest,
 *   !proto.wuxiaworld.api.v2.GetNovelsResponse>}
 */
const methodDescriptor_Novels_GetNovels = new grpc.web.MethodDescriptor(
  '/wuxiaworld.api.v2.Novels/GetNovels',
  grpc.web.MethodType.UNARY,
  proto.wuxiaworld.api.v2.GetNovelsRequest,
  proto.wuxiaworld.api.v2.GetNovelsResponse,
  /**
   * @param {!proto.wuxiaworld.api.v2.GetNovelsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.wuxiaworld.api.v2.GetNovelsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.wuxiaworld.api.v2.GetNovelsRequest,
 *   !proto.wuxiaworld.api.v2.GetNovelsResponse>}
 */
const methodInfo_Novels_GetNovels = new grpc.web.AbstractClientBase.MethodInfo(
  proto.wuxiaworld.api.v2.GetNovelsResponse,
  /**
   * @param {!proto.wuxiaworld.api.v2.GetNovelsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.wuxiaworld.api.v2.GetNovelsResponse.deserializeBinary
);


/**
 * @param {!proto.wuxiaworld.api.v2.GetNovelsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.wuxiaworld.api.v2.GetNovelsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.wuxiaworld.api.v2.GetNovelsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.wuxiaworld.api.v2.NovelsClient.prototype.getNovels =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/wuxiaworld.api.v2.Novels/GetNovels',
      request,
      metadata || {},
      methodDescriptor_Novels_GetNovels,
      callback);
};


/**
 * @param {!proto.wuxiaworld.api.v2.GetNovelsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.wuxiaworld.api.v2.GetNovelsResponse>}
 *     Promise that resolves to the response
 */
proto.wuxiaworld.api.v2.NovelsPromiseClient.prototype.getNovels =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/wuxiaworld.api.v2.Novels/GetNovels',
      request,
      metadata || {},
      methodDescriptor_Novels_GetNovels);
};


module.exports = proto.wuxiaworld.api.v2;

