import { css, Theme } from '@emotion/react';
import { Button, ButtonProps, CircularProgress } from '@material-ui/core';
import * as React from 'react';

//#region : Styles
export const StyledButtonProgressWrapper = (theme: Theme) =>
    css({
        position: 'relative',
    });

export const StyledButtonProgress = (theme: Theme) =>
    css({
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    });
//#endregion : Styles

//#region : Main component

/**
 * Custom.
 *
 * Main component parameters type.
 * Extend ButtonProps with custom properties.
 */
interface ButtonSpinnerProps extends ButtonProps {
    spinner: boolean;
}

/**
 * Custom helper component.
 *
 * Use this when you want to show spinner over a button
 */
const ButtonSpinner: React.FunctionComponent<ButtonSpinnerProps> = ({ spinner, ...rest }) => {
    return (
        <div css={StyledButtonProgressWrapper}>
            <Button {...rest}>{rest.children}</Button>
            {spinner && <CircularProgress color="secondary" size={24} css={StyledButtonProgress} />}
        </div>
    );
};

export default ButtonSpinner;
//#endregion : Main component
