/**
 * @fileoverview gRPC-Web generated client stub for wuxiaworld.api.v2
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var Protos_vips_pb = require('../Protos/vips_pb.js')
const proto = {};
proto.wuxiaworld = {};
proto.wuxiaworld.api = {};
proto.wuxiaworld.api.v2 = require('./users_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.wuxiaworld.api.v2.UsersClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.wuxiaworld.api.v2.UsersPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.wuxiaworld.api.v2.GetMyUserResponse>}
 */
const methodDescriptor_Users_GetMyUser = new grpc.web.MethodDescriptor(
  '/wuxiaworld.api.v2.Users/GetMyUser',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.wuxiaworld.api.v2.GetMyUserResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.wuxiaworld.api.v2.GetMyUserResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.wuxiaworld.api.v2.GetMyUserResponse>}
 */
const methodInfo_Users_GetMyUser = new grpc.web.AbstractClientBase.MethodInfo(
  proto.wuxiaworld.api.v2.GetMyUserResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.wuxiaworld.api.v2.GetMyUserResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.wuxiaworld.api.v2.GetMyUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.wuxiaworld.api.v2.GetMyUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.wuxiaworld.api.v2.UsersClient.prototype.getMyUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/wuxiaworld.api.v2.Users/GetMyUser',
      request,
      metadata || {},
      methodDescriptor_Users_GetMyUser,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.wuxiaworld.api.v2.GetMyUserResponse>}
 *     Promise that resolves to the response
 */
proto.wuxiaworld.api.v2.UsersPromiseClient.prototype.getMyUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/wuxiaworld.api.v2.Users/GetMyUser',
      request,
      metadata || {},
      methodDescriptor_Users_GetMyUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.wuxiaworld.api.v2.SetAvatarRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Users_SetAvatar = new grpc.web.MethodDescriptor(
  '/wuxiaworld.api.v2.Users/SetAvatar',
  grpc.web.MethodType.UNARY,
  proto.wuxiaworld.api.v2.SetAvatarRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.wuxiaworld.api.v2.SetAvatarRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.wuxiaworld.api.v2.SetAvatarRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_Users_SetAvatar = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.wuxiaworld.api.v2.SetAvatarRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.wuxiaworld.api.v2.SetAvatarRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.wuxiaworld.api.v2.UsersClient.prototype.setAvatar =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/wuxiaworld.api.v2.Users/SetAvatar',
      request,
      metadata || {},
      methodDescriptor_Users_SetAvatar,
      callback);
};


/**
 * @param {!proto.wuxiaworld.api.v2.SetAvatarRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.wuxiaworld.api.v2.UsersPromiseClient.prototype.setAvatar =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/wuxiaworld.api.v2.Users/SetAvatar',
      request,
      metadata || {},
      methodDescriptor_Users_SetAvatar);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.wuxiaworld.api.v2.DeleteAccountRequest,
 *   !proto.wuxiaworld.api.v2.DeleteAccountResponse>}
 */
const methodDescriptor_Users_DeleteAccount = new grpc.web.MethodDescriptor(
  '/wuxiaworld.api.v2.Users/DeleteAccount',
  grpc.web.MethodType.UNARY,
  proto.wuxiaworld.api.v2.DeleteAccountRequest,
  proto.wuxiaworld.api.v2.DeleteAccountResponse,
  /**
   * @param {!proto.wuxiaworld.api.v2.DeleteAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.wuxiaworld.api.v2.DeleteAccountResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.wuxiaworld.api.v2.DeleteAccountRequest,
 *   !proto.wuxiaworld.api.v2.DeleteAccountResponse>}
 */
const methodInfo_Users_DeleteAccount = new grpc.web.AbstractClientBase.MethodInfo(
  proto.wuxiaworld.api.v2.DeleteAccountResponse,
  /**
   * @param {!proto.wuxiaworld.api.v2.DeleteAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.wuxiaworld.api.v2.DeleteAccountResponse.deserializeBinary
);


/**
 * @param {!proto.wuxiaworld.api.v2.DeleteAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.wuxiaworld.api.v2.DeleteAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.wuxiaworld.api.v2.DeleteAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.wuxiaworld.api.v2.UsersClient.prototype.deleteAccount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/wuxiaworld.api.v2.Users/DeleteAccount',
      request,
      metadata || {},
      methodDescriptor_Users_DeleteAccount,
      callback);
};


/**
 * @param {!proto.wuxiaworld.api.v2.DeleteAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.wuxiaworld.api.v2.DeleteAccountResponse>}
 *     Promise that resolves to the response
 */
proto.wuxiaworld.api.v2.UsersPromiseClient.prototype.deleteAccount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/wuxiaworld.api.v2.Users/DeleteAccount',
      request,
      metadata || {},
      methodDescriptor_Users_DeleteAccount);
};


module.exports = proto.wuxiaworld.api.v2;

