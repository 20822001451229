import { css, Theme } from '@emotion/react';
import { Typography } from '@material-ui/core';
import * as React from 'react';

//#region : Styles
const StyledName = (theme: Theme) =>
    css({
        letterSpacing: theme.typography.pxToRem(1.29),
        textTransform: 'uppercase',
    });
//#endregion : Styles

//#region : Main component
/**
 * Custom helper component.
 *
 * Use it when you want to display the site's name, size h4 as declared in theme.
 */
export default function SiteName() {
    return (
        <Typography css={StyledName} variant="h4" component="span">
            Wuxiaworld
        </Typography>
    );
}
//#endregion : Main component
