// source: Protos/novels.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var Protos_types_pb = require('../Protos/types_pb.js');
goog.object.extend(proto, Protos_types_pb);
var Protos_chapters_pb = require('../Protos/chapters_pb.js');
goog.object.extend(proto, Protos_chapters_pb);
var Protos_sponsors_pb = require('../Protos/sponsors_pb.js');
goog.object.extend(proto, Protos_sponsors_pb);
var Protos_users_pb = require('../Protos/users_pb.js');
goog.object.extend(proto, Protos_users_pb);
var Protos_pagination_pb = require('../Protos/pagination_pb.js');
goog.object.extend(proto, Protos_pagination_pb);
goog.exportSymbol('proto.wuxiaworld.api.v2.ByNovelProperty', null, global);
goog.exportSymbol('proto.wuxiaworld.api.v2.ByNovelProperty.BypropertyCase', null, global);
goog.exportSymbol('proto.wuxiaworld.api.v2.GetNovelRequest', null, global);
goog.exportSymbol('proto.wuxiaworld.api.v2.GetNovelResponse', null, global);
goog.exportSymbol('proto.wuxiaworld.api.v2.GetNovelsRequest', null, global);
goog.exportSymbol('proto.wuxiaworld.api.v2.GetNovelsRequest.FilterNovels', null, global);
goog.exportSymbol('proto.wuxiaworld.api.v2.GetNovelsRequest.FilterNovels.FilterCase', null, global);
goog.exportSymbol('proto.wuxiaworld.api.v2.GetNovelsResponse', null, global);
goog.exportSymbol('proto.wuxiaworld.api.v2.NovelInfo', null, global);
goog.exportSymbol('proto.wuxiaworld.api.v2.NovelItem', null, global);
goog.exportSymbol('proto.wuxiaworld.api.v2.SearchNovelsRequest', null, global);
goog.exportSymbol('proto.wuxiaworld.api.v2.SearchNovelsRequest.SortDirection', null, global);
goog.exportSymbol('proto.wuxiaworld.api.v2.SearchNovelsRequest.SortType', null, global);
goog.exportSymbol('proto.wuxiaworld.api.v2.SearchNovelsResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wuxiaworld.api.v2.SearchNovelsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wuxiaworld.api.v2.SearchNovelsRequest.repeatedFields_, null);
};
goog.inherits(proto.wuxiaworld.api.v2.SearchNovelsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wuxiaworld.api.v2.SearchNovelsRequest.displayName = 'proto.wuxiaworld.api.v2.SearchNovelsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wuxiaworld.api.v2.SearchNovelsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wuxiaworld.api.v2.SearchNovelsResponse.repeatedFields_, null);
};
goog.inherits(proto.wuxiaworld.api.v2.SearchNovelsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wuxiaworld.api.v2.SearchNovelsResponse.displayName = 'proto.wuxiaworld.api.v2.SearchNovelsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wuxiaworld.api.v2.GetNovelRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wuxiaworld.api.v2.GetNovelRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wuxiaworld.api.v2.GetNovelRequest.displayName = 'proto.wuxiaworld.api.v2.GetNovelRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wuxiaworld.api.v2.GetNovelResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wuxiaworld.api.v2.GetNovelResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wuxiaworld.api.v2.GetNovelResponse.displayName = 'proto.wuxiaworld.api.v2.GetNovelResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wuxiaworld.api.v2.GetNovelsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wuxiaworld.api.v2.GetNovelsRequest.repeatedFields_, null);
};
goog.inherits(proto.wuxiaworld.api.v2.GetNovelsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wuxiaworld.api.v2.GetNovelsRequest.displayName = 'proto.wuxiaworld.api.v2.GetNovelsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wuxiaworld.api.v2.GetNovelsRequest.FilterNovels = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.wuxiaworld.api.v2.GetNovelsRequest.FilterNovels.oneofGroups_);
};
goog.inherits(proto.wuxiaworld.api.v2.GetNovelsRequest.FilterNovels, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wuxiaworld.api.v2.GetNovelsRequest.FilterNovels.displayName = 'proto.wuxiaworld.api.v2.GetNovelsRequest.FilterNovels';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wuxiaworld.api.v2.GetNovelsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wuxiaworld.api.v2.GetNovelsResponse.repeatedFields_, null);
};
goog.inherits(proto.wuxiaworld.api.v2.GetNovelsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wuxiaworld.api.v2.GetNovelsResponse.displayName = 'proto.wuxiaworld.api.v2.GetNovelsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wuxiaworld.api.v2.NovelInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wuxiaworld.api.v2.NovelInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wuxiaworld.api.v2.NovelInfo.displayName = 'proto.wuxiaworld.api.v2.NovelInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wuxiaworld.api.v2.ByNovelProperty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.wuxiaworld.api.v2.ByNovelProperty.oneofGroups_);
};
goog.inherits(proto.wuxiaworld.api.v2.ByNovelProperty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wuxiaworld.api.v2.ByNovelProperty.displayName = 'proto.wuxiaworld.api.v2.ByNovelProperty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wuxiaworld.api.v2.NovelItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wuxiaworld.api.v2.NovelItem.repeatedFields_, null);
};
goog.inherits(proto.wuxiaworld.api.v2.NovelItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wuxiaworld.api.v2.NovelItem.displayName = 'proto.wuxiaworld.api.v2.NovelItem';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wuxiaworld.api.v2.SearchNovelsRequest.repeatedFields_ = [9,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wuxiaworld.api.v2.SearchNovelsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wuxiaworld.api.v2.SearchNovelsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wuxiaworld.api.v2.SearchNovelsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.SearchNovelsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: (f = msg.getTitle()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    language: (f = msg.getLanguage()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    active: (f = msg.getActive()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    sorttype: jspb.Message.getFieldWithDefault(msg, 4, 0),
    sortdirection: jspb.Message.getFieldWithDefault(msg, 5, 0),
    searchafterid: (f = msg.getSearchafterid()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    count: jspb.Message.getFieldWithDefault(msg, 7, 0),
    randomseed: (f = msg.getRandomseed()) && google_protobuf_wrappers_pb.Int64Value.toObject(includeInstance, f),
    tagsList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
    genresList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    sneakpeek: (f = msg.getSneakpeek()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wuxiaworld.api.v2.SearchNovelsRequest}
 */
proto.wuxiaworld.api.v2.SearchNovelsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wuxiaworld.api.v2.SearchNovelsRequest;
  return proto.wuxiaworld.api.v2.SearchNovelsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wuxiaworld.api.v2.SearchNovelsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wuxiaworld.api.v2.SearchNovelsRequest}
 */
proto.wuxiaworld.api.v2.SearchNovelsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTitle(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setLanguage(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setActive(value);
      break;
    case 4:
      var value = /** @type {!proto.wuxiaworld.api.v2.SearchNovelsRequest.SortType} */ (reader.readEnum());
      msg.setSorttype(value);
      break;
    case 5:
      var value = /** @type {!proto.wuxiaworld.api.v2.SearchNovelsRequest.SortDirection} */ (reader.readEnum());
      msg.setSortdirection(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setSearchafterid(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.Int64Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int64Value.deserializeBinaryFromReader);
      msg.setRandomseed(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.addTags(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addGenres(value);
      break;
    case 11:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setSneakpeek(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wuxiaworld.api.v2.SearchNovelsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wuxiaworld.api.v2.SearchNovelsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wuxiaworld.api.v2.SearchNovelsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.SearchNovelsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getLanguage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getActive();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getSorttype();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getSortdirection();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getSearchafterid();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getRandomseed();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.Int64Value.serializeBinaryToWriter
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      9,
      f
    );
  }
  f = message.getGenresList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
  f = message.getSneakpeek();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.wuxiaworld.api.v2.SearchNovelsRequest.SortType = {
  NONE: 0,
  POPULAR: 1,
  NEW: 2,
  CHAPTERS: 3,
  NAME: 4,
  RANDOM: 5,
  RATING: 6
};

/**
 * @enum {number}
 */
proto.wuxiaworld.api.v2.SearchNovelsRequest.SortDirection = {
  ASC: 0,
  DESC: 1
};

/**
 * optional google.protobuf.StringValue title = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.wuxiaworld.api.v2.SearchNovelsRequest.prototype.getTitle = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.wuxiaworld.api.v2.SearchNovelsRequest} returns this
*/
proto.wuxiaworld.api.v2.SearchNovelsRequest.prototype.setTitle = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.SearchNovelsRequest} returns this
 */
proto.wuxiaworld.api.v2.SearchNovelsRequest.prototype.clearTitle = function() {
  return this.setTitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.SearchNovelsRequest.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue language = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.wuxiaworld.api.v2.SearchNovelsRequest.prototype.getLanguage = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.wuxiaworld.api.v2.SearchNovelsRequest} returns this
*/
proto.wuxiaworld.api.v2.SearchNovelsRequest.prototype.setLanguage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.SearchNovelsRequest} returns this
 */
proto.wuxiaworld.api.v2.SearchNovelsRequest.prototype.clearLanguage = function() {
  return this.setLanguage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.SearchNovelsRequest.prototype.hasLanguage = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.BoolValue active = 3;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.wuxiaworld.api.v2.SearchNovelsRequest.prototype.getActive = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 3));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.wuxiaworld.api.v2.SearchNovelsRequest} returns this
*/
proto.wuxiaworld.api.v2.SearchNovelsRequest.prototype.setActive = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.SearchNovelsRequest} returns this
 */
proto.wuxiaworld.api.v2.SearchNovelsRequest.prototype.clearActive = function() {
  return this.setActive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.SearchNovelsRequest.prototype.hasActive = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional SortType sortType = 4;
 * @return {!proto.wuxiaworld.api.v2.SearchNovelsRequest.SortType}
 */
proto.wuxiaworld.api.v2.SearchNovelsRequest.prototype.getSorttype = function() {
  return /** @type {!proto.wuxiaworld.api.v2.SearchNovelsRequest.SortType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wuxiaworld.api.v2.SearchNovelsRequest.SortType} value
 * @return {!proto.wuxiaworld.api.v2.SearchNovelsRequest} returns this
 */
proto.wuxiaworld.api.v2.SearchNovelsRequest.prototype.setSorttype = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional SortDirection sortDirection = 5;
 * @return {!proto.wuxiaworld.api.v2.SearchNovelsRequest.SortDirection}
 */
proto.wuxiaworld.api.v2.SearchNovelsRequest.prototype.getSortdirection = function() {
  return /** @type {!proto.wuxiaworld.api.v2.SearchNovelsRequest.SortDirection} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.wuxiaworld.api.v2.SearchNovelsRequest.SortDirection} value
 * @return {!proto.wuxiaworld.api.v2.SearchNovelsRequest} returns this
 */
proto.wuxiaworld.api.v2.SearchNovelsRequest.prototype.setSortdirection = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional google.protobuf.Int32Value searchAfterId = 6;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.wuxiaworld.api.v2.SearchNovelsRequest.prototype.getSearchafterid = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 6));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.wuxiaworld.api.v2.SearchNovelsRequest} returns this
*/
proto.wuxiaworld.api.v2.SearchNovelsRequest.prototype.setSearchafterid = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.SearchNovelsRequest} returns this
 */
proto.wuxiaworld.api.v2.SearchNovelsRequest.prototype.clearSearchafterid = function() {
  return this.setSearchafterid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.SearchNovelsRequest.prototype.hasSearchafterid = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int32 count = 7;
 * @return {number}
 */
proto.wuxiaworld.api.v2.SearchNovelsRequest.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.wuxiaworld.api.v2.SearchNovelsRequest} returns this
 */
proto.wuxiaworld.api.v2.SearchNovelsRequest.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional google.protobuf.Int64Value randomSeed = 8;
 * @return {?proto.google.protobuf.Int64Value}
 */
proto.wuxiaworld.api.v2.SearchNovelsRequest.prototype.getRandomseed = function() {
  return /** @type{?proto.google.protobuf.Int64Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int64Value, 8));
};


/**
 * @param {?proto.google.protobuf.Int64Value|undefined} value
 * @return {!proto.wuxiaworld.api.v2.SearchNovelsRequest} returns this
*/
proto.wuxiaworld.api.v2.SearchNovelsRequest.prototype.setRandomseed = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.SearchNovelsRequest} returns this
 */
proto.wuxiaworld.api.v2.SearchNovelsRequest.prototype.clearRandomseed = function() {
  return this.setRandomseed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.SearchNovelsRequest.prototype.hasRandomseed = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated string tags = 9;
 * @return {!Array<string>}
 */
proto.wuxiaworld.api.v2.SearchNovelsRequest.prototype.getTagsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wuxiaworld.api.v2.SearchNovelsRequest} returns this
 */
proto.wuxiaworld.api.v2.SearchNovelsRequest.prototype.setTagsList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wuxiaworld.api.v2.SearchNovelsRequest} returns this
 */
proto.wuxiaworld.api.v2.SearchNovelsRequest.prototype.addTags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wuxiaworld.api.v2.SearchNovelsRequest} returns this
 */
proto.wuxiaworld.api.v2.SearchNovelsRequest.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * repeated string genres = 10;
 * @return {!Array<string>}
 */
proto.wuxiaworld.api.v2.SearchNovelsRequest.prototype.getGenresList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wuxiaworld.api.v2.SearchNovelsRequest} returns this
 */
proto.wuxiaworld.api.v2.SearchNovelsRequest.prototype.setGenresList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wuxiaworld.api.v2.SearchNovelsRequest} returns this
 */
proto.wuxiaworld.api.v2.SearchNovelsRequest.prototype.addGenres = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wuxiaworld.api.v2.SearchNovelsRequest} returns this
 */
proto.wuxiaworld.api.v2.SearchNovelsRequest.prototype.clearGenresList = function() {
  return this.setGenresList([]);
};


/**
 * optional google.protobuf.BoolValue sneakPeek = 11;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.wuxiaworld.api.v2.SearchNovelsRequest.prototype.getSneakpeek = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 11));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.wuxiaworld.api.v2.SearchNovelsRequest} returns this
*/
proto.wuxiaworld.api.v2.SearchNovelsRequest.prototype.setSneakpeek = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.SearchNovelsRequest} returns this
 */
proto.wuxiaworld.api.v2.SearchNovelsRequest.prototype.clearSneakpeek = function() {
  return this.setSneakpeek(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.SearchNovelsRequest.prototype.hasSneakpeek = function() {
  return jspb.Message.getField(this, 11) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wuxiaworld.api.v2.SearchNovelsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wuxiaworld.api.v2.SearchNovelsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wuxiaworld.api.v2.SearchNovelsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wuxiaworld.api.v2.SearchNovelsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.SearchNovelsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.wuxiaworld.api.v2.NovelItem.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wuxiaworld.api.v2.SearchNovelsResponse}
 */
proto.wuxiaworld.api.v2.SearchNovelsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wuxiaworld.api.v2.SearchNovelsResponse;
  return proto.wuxiaworld.api.v2.SearchNovelsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wuxiaworld.api.v2.SearchNovelsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wuxiaworld.api.v2.SearchNovelsResponse}
 */
proto.wuxiaworld.api.v2.SearchNovelsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.wuxiaworld.api.v2.NovelItem;
      reader.readMessage(value,proto.wuxiaworld.api.v2.NovelItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wuxiaworld.api.v2.SearchNovelsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wuxiaworld.api.v2.SearchNovelsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wuxiaworld.api.v2.SearchNovelsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.SearchNovelsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.wuxiaworld.api.v2.NovelItem.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated NovelItem items = 1;
 * @return {!Array<!proto.wuxiaworld.api.v2.NovelItem>}
 */
proto.wuxiaworld.api.v2.SearchNovelsResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.wuxiaworld.api.v2.NovelItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wuxiaworld.api.v2.NovelItem, 1));
};


/**
 * @param {!Array<!proto.wuxiaworld.api.v2.NovelItem>} value
 * @return {!proto.wuxiaworld.api.v2.SearchNovelsResponse} returns this
*/
proto.wuxiaworld.api.v2.SearchNovelsResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.wuxiaworld.api.v2.NovelItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wuxiaworld.api.v2.NovelItem}
 */
proto.wuxiaworld.api.v2.SearchNovelsResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.wuxiaworld.api.v2.NovelItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wuxiaworld.api.v2.SearchNovelsResponse} returns this
 */
proto.wuxiaworld.api.v2.SearchNovelsResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional int64 total = 2;
 * @return {number}
 */
proto.wuxiaworld.api.v2.SearchNovelsResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wuxiaworld.api.v2.SearchNovelsResponse} returns this
 */
proto.wuxiaworld.api.v2.SearchNovelsResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wuxiaworld.api.v2.GetNovelRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wuxiaworld.api.v2.GetNovelRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wuxiaworld.api.v2.GetNovelRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.GetNovelRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    byproperty: (f = msg.getByproperty()) && proto.wuxiaworld.api.v2.ByNovelProperty.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wuxiaworld.api.v2.GetNovelRequest}
 */
proto.wuxiaworld.api.v2.GetNovelRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wuxiaworld.api.v2.GetNovelRequest;
  return proto.wuxiaworld.api.v2.GetNovelRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wuxiaworld.api.v2.GetNovelRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wuxiaworld.api.v2.GetNovelRequest}
 */
proto.wuxiaworld.api.v2.GetNovelRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.wuxiaworld.api.v2.ByNovelProperty;
      reader.readMessage(value,proto.wuxiaworld.api.v2.ByNovelProperty.deserializeBinaryFromReader);
      msg.setByproperty(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wuxiaworld.api.v2.GetNovelRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wuxiaworld.api.v2.GetNovelRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wuxiaworld.api.v2.GetNovelRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.GetNovelRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getByproperty();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.wuxiaworld.api.v2.ByNovelProperty.serializeBinaryToWriter
    );
  }
};


/**
 * optional ByNovelProperty byProperty = 1;
 * @return {?proto.wuxiaworld.api.v2.ByNovelProperty}
 */
proto.wuxiaworld.api.v2.GetNovelRequest.prototype.getByproperty = function() {
  return /** @type{?proto.wuxiaworld.api.v2.ByNovelProperty} */ (
    jspb.Message.getWrapperField(this, proto.wuxiaworld.api.v2.ByNovelProperty, 1));
};


/**
 * @param {?proto.wuxiaworld.api.v2.ByNovelProperty|undefined} value
 * @return {!proto.wuxiaworld.api.v2.GetNovelRequest} returns this
*/
proto.wuxiaworld.api.v2.GetNovelRequest.prototype.setByproperty = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.GetNovelRequest} returns this
 */
proto.wuxiaworld.api.v2.GetNovelRequest.prototype.clearByproperty = function() {
  return this.setByproperty(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.GetNovelRequest.prototype.hasByproperty = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wuxiaworld.api.v2.GetNovelResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wuxiaworld.api.v2.GetNovelResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wuxiaworld.api.v2.GetNovelResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.GetNovelResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    item: (f = msg.getItem()) && proto.wuxiaworld.api.v2.NovelItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wuxiaworld.api.v2.GetNovelResponse}
 */
proto.wuxiaworld.api.v2.GetNovelResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wuxiaworld.api.v2.GetNovelResponse;
  return proto.wuxiaworld.api.v2.GetNovelResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wuxiaworld.api.v2.GetNovelResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wuxiaworld.api.v2.GetNovelResponse}
 */
proto.wuxiaworld.api.v2.GetNovelResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.wuxiaworld.api.v2.NovelItem;
      reader.readMessage(value,proto.wuxiaworld.api.v2.NovelItem.deserializeBinaryFromReader);
      msg.setItem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wuxiaworld.api.v2.GetNovelResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wuxiaworld.api.v2.GetNovelResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wuxiaworld.api.v2.GetNovelResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.GetNovelResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItem();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.wuxiaworld.api.v2.NovelItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional NovelItem item = 1;
 * @return {?proto.wuxiaworld.api.v2.NovelItem}
 */
proto.wuxiaworld.api.v2.GetNovelResponse.prototype.getItem = function() {
  return /** @type{?proto.wuxiaworld.api.v2.NovelItem} */ (
    jspb.Message.getWrapperField(this, proto.wuxiaworld.api.v2.NovelItem, 1));
};


/**
 * @param {?proto.wuxiaworld.api.v2.NovelItem|undefined} value
 * @return {!proto.wuxiaworld.api.v2.GetNovelResponse} returns this
*/
proto.wuxiaworld.api.v2.GetNovelResponse.prototype.setItem = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.GetNovelResponse} returns this
 */
proto.wuxiaworld.api.v2.GetNovelResponse.prototype.clearItem = function() {
  return this.setItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.GetNovelResponse.prototype.hasItem = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wuxiaworld.api.v2.GetNovelsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wuxiaworld.api.v2.GetNovelsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wuxiaworld.api.v2.GetNovelsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wuxiaworld.api.v2.GetNovelsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.GetNovelsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filtersList: jspb.Message.toObjectList(msg.getFiltersList(),
    proto.wuxiaworld.api.v2.GetNovelsRequest.FilterNovels.toObject, includeInstance),
    pageinfo: (f = msg.getPageinfo()) && Protos_pagination_pb.PageInfoRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wuxiaworld.api.v2.GetNovelsRequest}
 */
proto.wuxiaworld.api.v2.GetNovelsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wuxiaworld.api.v2.GetNovelsRequest;
  return proto.wuxiaworld.api.v2.GetNovelsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wuxiaworld.api.v2.GetNovelsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wuxiaworld.api.v2.GetNovelsRequest}
 */
proto.wuxiaworld.api.v2.GetNovelsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.wuxiaworld.api.v2.GetNovelsRequest.FilterNovels;
      reader.readMessage(value,proto.wuxiaworld.api.v2.GetNovelsRequest.FilterNovels.deserializeBinaryFromReader);
      msg.addFilters(value);
      break;
    case 2:
      var value = new Protos_pagination_pb.PageInfoRequest;
      reader.readMessage(value,Protos_pagination_pb.PageInfoRequest.deserializeBinaryFromReader);
      msg.setPageinfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wuxiaworld.api.v2.GetNovelsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wuxiaworld.api.v2.GetNovelsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wuxiaworld.api.v2.GetNovelsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.GetNovelsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.wuxiaworld.api.v2.GetNovelsRequest.FilterNovels.serializeBinaryToWriter
    );
  }
  f = message.getPageinfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_pagination_pb.PageInfoRequest.serializeBinaryToWriter
    );
  }
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.wuxiaworld.api.v2.GetNovelsRequest.FilterNovels.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.wuxiaworld.api.v2.GetNovelsRequest.FilterNovels.FilterCase = {
  FILTER_NOT_SET: 0,
  HASSPONSORPLANS: 1,
  NAME: 2
};

/**
 * @return {proto.wuxiaworld.api.v2.GetNovelsRequest.FilterNovels.FilterCase}
 */
proto.wuxiaworld.api.v2.GetNovelsRequest.FilterNovels.prototype.getFilterCase = function() {
  return /** @type {proto.wuxiaworld.api.v2.GetNovelsRequest.FilterNovels.FilterCase} */(jspb.Message.computeOneofCase(this, proto.wuxiaworld.api.v2.GetNovelsRequest.FilterNovels.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wuxiaworld.api.v2.GetNovelsRequest.FilterNovels.prototype.toObject = function(opt_includeInstance) {
  return proto.wuxiaworld.api.v2.GetNovelsRequest.FilterNovels.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wuxiaworld.api.v2.GetNovelsRequest.FilterNovels} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.GetNovelsRequest.FilterNovels.toObject = function(includeInstance, msg) {
  var f, obj = {
    hassponsorplans: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wuxiaworld.api.v2.GetNovelsRequest.FilterNovels}
 */
proto.wuxiaworld.api.v2.GetNovelsRequest.FilterNovels.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wuxiaworld.api.v2.GetNovelsRequest.FilterNovels;
  return proto.wuxiaworld.api.v2.GetNovelsRequest.FilterNovels.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wuxiaworld.api.v2.GetNovelsRequest.FilterNovels} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wuxiaworld.api.v2.GetNovelsRequest.FilterNovels}
 */
proto.wuxiaworld.api.v2.GetNovelsRequest.FilterNovels.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHassponsorplans(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wuxiaworld.api.v2.GetNovelsRequest.FilterNovels.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wuxiaworld.api.v2.GetNovelsRequest.FilterNovels.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wuxiaworld.api.v2.GetNovelsRequest.FilterNovels} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.GetNovelsRequest.FilterNovels.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool hasSponsorPlans = 1;
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.GetNovelsRequest.FilterNovels.prototype.getHassponsorplans = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wuxiaworld.api.v2.GetNovelsRequest.FilterNovels} returns this
 */
proto.wuxiaworld.api.v2.GetNovelsRequest.FilterNovels.prototype.setHassponsorplans = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.wuxiaworld.api.v2.GetNovelsRequest.FilterNovels.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.GetNovelsRequest.FilterNovels} returns this
 */
proto.wuxiaworld.api.v2.GetNovelsRequest.FilterNovels.prototype.clearHassponsorplans = function() {
  return jspb.Message.setOneofField(this, 1, proto.wuxiaworld.api.v2.GetNovelsRequest.FilterNovels.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.GetNovelsRequest.FilterNovels.prototype.hasHassponsorplans = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.wuxiaworld.api.v2.GetNovelsRequest.FilterNovels.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wuxiaworld.api.v2.GetNovelsRequest.FilterNovels} returns this
 */
proto.wuxiaworld.api.v2.GetNovelsRequest.FilterNovels.prototype.setName = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.wuxiaworld.api.v2.GetNovelsRequest.FilterNovels.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.GetNovelsRequest.FilterNovels} returns this
 */
proto.wuxiaworld.api.v2.GetNovelsRequest.FilterNovels.prototype.clearName = function() {
  return jspb.Message.setOneofField(this, 2, proto.wuxiaworld.api.v2.GetNovelsRequest.FilterNovels.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.GetNovelsRequest.FilterNovels.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated FilterNovels filters = 1;
 * @return {!Array<!proto.wuxiaworld.api.v2.GetNovelsRequest.FilterNovels>}
 */
proto.wuxiaworld.api.v2.GetNovelsRequest.prototype.getFiltersList = function() {
  return /** @type{!Array<!proto.wuxiaworld.api.v2.GetNovelsRequest.FilterNovels>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wuxiaworld.api.v2.GetNovelsRequest.FilterNovels, 1));
};


/**
 * @param {!Array<!proto.wuxiaworld.api.v2.GetNovelsRequest.FilterNovels>} value
 * @return {!proto.wuxiaworld.api.v2.GetNovelsRequest} returns this
*/
proto.wuxiaworld.api.v2.GetNovelsRequest.prototype.setFiltersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.wuxiaworld.api.v2.GetNovelsRequest.FilterNovels=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wuxiaworld.api.v2.GetNovelsRequest.FilterNovels}
 */
proto.wuxiaworld.api.v2.GetNovelsRequest.prototype.addFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.wuxiaworld.api.v2.GetNovelsRequest.FilterNovels, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wuxiaworld.api.v2.GetNovelsRequest} returns this
 */
proto.wuxiaworld.api.v2.GetNovelsRequest.prototype.clearFiltersList = function() {
  return this.setFiltersList([]);
};


/**
 * optional PageInfoRequest pageInfo = 2;
 * @return {?proto.PageInfoRequest}
 */
proto.wuxiaworld.api.v2.GetNovelsRequest.prototype.getPageinfo = function() {
  return /** @type{?proto.PageInfoRequest} */ (
    jspb.Message.getWrapperField(this, Protos_pagination_pb.PageInfoRequest, 2));
};


/**
 * @param {?proto.PageInfoRequest|undefined} value
 * @return {!proto.wuxiaworld.api.v2.GetNovelsRequest} returns this
*/
proto.wuxiaworld.api.v2.GetNovelsRequest.prototype.setPageinfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.GetNovelsRequest} returns this
 */
proto.wuxiaworld.api.v2.GetNovelsRequest.prototype.clearPageinfo = function() {
  return this.setPageinfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.GetNovelsRequest.prototype.hasPageinfo = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wuxiaworld.api.v2.GetNovelsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wuxiaworld.api.v2.GetNovelsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wuxiaworld.api.v2.GetNovelsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wuxiaworld.api.v2.GetNovelsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.GetNovelsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.wuxiaworld.api.v2.NovelItem.toObject, includeInstance),
    pageinfo: (f = msg.getPageinfo()) && Protos_pagination_pb.PageInfoResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wuxiaworld.api.v2.GetNovelsResponse}
 */
proto.wuxiaworld.api.v2.GetNovelsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wuxiaworld.api.v2.GetNovelsResponse;
  return proto.wuxiaworld.api.v2.GetNovelsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wuxiaworld.api.v2.GetNovelsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wuxiaworld.api.v2.GetNovelsResponse}
 */
proto.wuxiaworld.api.v2.GetNovelsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.wuxiaworld.api.v2.NovelItem;
      reader.readMessage(value,proto.wuxiaworld.api.v2.NovelItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new Protos_pagination_pb.PageInfoResponse;
      reader.readMessage(value,Protos_pagination_pb.PageInfoResponse.deserializeBinaryFromReader);
      msg.setPageinfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wuxiaworld.api.v2.GetNovelsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wuxiaworld.api.v2.GetNovelsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wuxiaworld.api.v2.GetNovelsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.GetNovelsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.wuxiaworld.api.v2.NovelItem.serializeBinaryToWriter
    );
  }
  f = message.getPageinfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_pagination_pb.PageInfoResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated NovelItem items = 1;
 * @return {!Array<!proto.wuxiaworld.api.v2.NovelItem>}
 */
proto.wuxiaworld.api.v2.GetNovelsResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.wuxiaworld.api.v2.NovelItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wuxiaworld.api.v2.NovelItem, 1));
};


/**
 * @param {!Array<!proto.wuxiaworld.api.v2.NovelItem>} value
 * @return {!proto.wuxiaworld.api.v2.GetNovelsResponse} returns this
*/
proto.wuxiaworld.api.v2.GetNovelsResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.wuxiaworld.api.v2.NovelItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wuxiaworld.api.v2.NovelItem}
 */
proto.wuxiaworld.api.v2.GetNovelsResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.wuxiaworld.api.v2.NovelItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wuxiaworld.api.v2.GetNovelsResponse} returns this
 */
proto.wuxiaworld.api.v2.GetNovelsResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional PageInfoResponse pageInfo = 2;
 * @return {?proto.PageInfoResponse}
 */
proto.wuxiaworld.api.v2.GetNovelsResponse.prototype.getPageinfo = function() {
  return /** @type{?proto.PageInfoResponse} */ (
    jspb.Message.getWrapperField(this, Protos_pagination_pb.PageInfoResponse, 2));
};


/**
 * @param {?proto.PageInfoResponse|undefined} value
 * @return {!proto.wuxiaworld.api.v2.GetNovelsResponse} returns this
*/
proto.wuxiaworld.api.v2.GetNovelsResponse.prototype.setPageinfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.GetNovelsResponse} returns this
 */
proto.wuxiaworld.api.v2.GetNovelsResponse.prototype.clearPageinfo = function() {
  return this.setPageinfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.GetNovelsResponse.prototype.hasPageinfo = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wuxiaworld.api.v2.NovelInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.wuxiaworld.api.v2.NovelInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wuxiaworld.api.v2.NovelInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.NovelInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    slug: jspb.Message.getFieldWithDefault(msg, 3, ""),
    coverurl: (f = msg.getCoverurl()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wuxiaworld.api.v2.NovelInfo}
 */
proto.wuxiaworld.api.v2.NovelInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wuxiaworld.api.v2.NovelInfo;
  return proto.wuxiaworld.api.v2.NovelInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wuxiaworld.api.v2.NovelInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wuxiaworld.api.v2.NovelInfo}
 */
proto.wuxiaworld.api.v2.NovelInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlug(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCoverurl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wuxiaworld.api.v2.NovelInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wuxiaworld.api.v2.NovelInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wuxiaworld.api.v2.NovelInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.NovelInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSlug();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCoverurl();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.wuxiaworld.api.v2.NovelInfo.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wuxiaworld.api.v2.NovelInfo} returns this
 */
proto.wuxiaworld.api.v2.NovelInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.wuxiaworld.api.v2.NovelInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wuxiaworld.api.v2.NovelInfo} returns this
 */
proto.wuxiaworld.api.v2.NovelInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string slug = 3;
 * @return {string}
 */
proto.wuxiaworld.api.v2.NovelInfo.prototype.getSlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wuxiaworld.api.v2.NovelInfo} returns this
 */
proto.wuxiaworld.api.v2.NovelInfo.prototype.setSlug = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.StringValue coverUrl = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.wuxiaworld.api.v2.NovelInfo.prototype.getCoverurl = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.wuxiaworld.api.v2.NovelInfo} returns this
*/
proto.wuxiaworld.api.v2.NovelInfo.prototype.setCoverurl = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.NovelInfo} returns this
 */
proto.wuxiaworld.api.v2.NovelInfo.prototype.clearCoverurl = function() {
  return this.setCoverurl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.NovelInfo.prototype.hasCoverurl = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.wuxiaworld.api.v2.ByNovelProperty.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.wuxiaworld.api.v2.ByNovelProperty.BypropertyCase = {
  BYPROPERTY_NOT_SET: 0,
  ID: 1,
  SLUG: 2
};

/**
 * @return {proto.wuxiaworld.api.v2.ByNovelProperty.BypropertyCase}
 */
proto.wuxiaworld.api.v2.ByNovelProperty.prototype.getBypropertyCase = function() {
  return /** @type {proto.wuxiaworld.api.v2.ByNovelProperty.BypropertyCase} */(jspb.Message.computeOneofCase(this, proto.wuxiaworld.api.v2.ByNovelProperty.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wuxiaworld.api.v2.ByNovelProperty.prototype.toObject = function(opt_includeInstance) {
  return proto.wuxiaworld.api.v2.ByNovelProperty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wuxiaworld.api.v2.ByNovelProperty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.ByNovelProperty.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    slug: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wuxiaworld.api.v2.ByNovelProperty}
 */
proto.wuxiaworld.api.v2.ByNovelProperty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wuxiaworld.api.v2.ByNovelProperty;
  return proto.wuxiaworld.api.v2.ByNovelProperty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wuxiaworld.api.v2.ByNovelProperty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wuxiaworld.api.v2.ByNovelProperty}
 */
proto.wuxiaworld.api.v2.ByNovelProperty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlug(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wuxiaworld.api.v2.ByNovelProperty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wuxiaworld.api.v2.ByNovelProperty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wuxiaworld.api.v2.ByNovelProperty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.ByNovelProperty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.wuxiaworld.api.v2.ByNovelProperty.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wuxiaworld.api.v2.ByNovelProperty} returns this
 */
proto.wuxiaworld.api.v2.ByNovelProperty.prototype.setId = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.wuxiaworld.api.v2.ByNovelProperty.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.ByNovelProperty} returns this
 */
proto.wuxiaworld.api.v2.ByNovelProperty.prototype.clearId = function() {
  return jspb.Message.setOneofField(this, 1, proto.wuxiaworld.api.v2.ByNovelProperty.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.ByNovelProperty.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string slug = 2;
 * @return {string}
 */
proto.wuxiaworld.api.v2.ByNovelProperty.prototype.getSlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wuxiaworld.api.v2.ByNovelProperty} returns this
 */
proto.wuxiaworld.api.v2.ByNovelProperty.prototype.setSlug = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.wuxiaworld.api.v2.ByNovelProperty.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.ByNovelProperty} returns this
 */
proto.wuxiaworld.api.v2.ByNovelProperty.prototype.clearSlug = function() {
  return jspb.Message.setOneofField(this, 2, proto.wuxiaworld.api.v2.ByNovelProperty.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.ByNovelProperty.prototype.hasSlug = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wuxiaworld.api.v2.NovelItem.repeatedFields_ = [17,18,19,25];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.toObject = function(opt_includeInstance) {
  return proto.wuxiaworld.api.v2.NovelItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wuxiaworld.api.v2.NovelItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.NovelItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    slug: jspb.Message.getFieldWithDefault(msg, 3, ""),
    active: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    abbreviation: jspb.Message.getFieldWithDefault(msg, 5, ""),
    language: (f = msg.getLanguage()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    visible: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    description: (f = msg.getDescription()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    synopsis: (f = msg.getSynopsis()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    coverurl: (f = msg.getCoverurl()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    translatorname: (f = msg.getTranslatorname()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    translator: (f = msg.getTranslator()) && Protos_users_pb.UserItem.toObject(includeInstance, f),
    authorname: (f = msg.getAuthorname()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    karmaactive: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    isfree: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    freemaxchapternumber: (f = msg.getFreemaxchapternumber()) && Protos_types_pb.DecimalValue.toObject(includeInstance, f),
    chaptergroupsList: jspb.Message.toObjectList(msg.getChaptergroupsList(),
    Protos_chapters_pb.ChapterGroupItem.toObject, includeInstance),
    tagsList: (f = jspb.Message.getRepeatedField(msg, 18)) == null ? undefined : f,
    genresList: (f = jspb.Message.getRepeatedField(msg, 19)) == null ? undefined : f,
    reviewscore: (f = msg.getReviewscore()) && google_protobuf_wrappers_pb.DoubleValue.toObject(includeInstance, f),
    reviewcount: (f = msg.getReviewcount()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    hasanysponsorplans: (f = msg.getHasanysponsorplans()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    issneakpeek: jspb.Message.getBooleanFieldWithDefault(msg, 23, false),
    publicchaptercount: (f = msg.getPublicchaptercount()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    sponsorplansList: jspb.Message.toObjectList(msg.getSponsorplansList(),
    Protos_sponsors_pb.SponsorPlanItem.toObject, includeInstance),
    createdat: (f = msg.getCreatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wuxiaworld.api.v2.NovelItem}
 */
proto.wuxiaworld.api.v2.NovelItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wuxiaworld.api.v2.NovelItem;
  return proto.wuxiaworld.api.v2.NovelItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wuxiaworld.api.v2.NovelItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wuxiaworld.api.v2.NovelItem}
 */
proto.wuxiaworld.api.v2.NovelItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlug(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAbbreviation(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setLanguage(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVisible(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSynopsis(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCoverurl(value);
      break;
    case 11:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTranslatorname(value);
      break;
    case 12:
      var value = new Protos_users_pb.UserItem;
      reader.readMessage(value,Protos_users_pb.UserItem.deserializeBinaryFromReader);
      msg.setTranslator(value);
      break;
    case 13:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAuthorname(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setKarmaactive(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsfree(value);
      break;
    case 16:
      var value = new Protos_types_pb.DecimalValue;
      reader.readMessage(value,Protos_types_pb.DecimalValue.deserializeBinaryFromReader);
      msg.setFreemaxchapternumber(value);
      break;
    case 17:
      var value = new Protos_chapters_pb.ChapterGroupItem;
      reader.readMessage(value,Protos_chapters_pb.ChapterGroupItem.deserializeBinaryFromReader);
      msg.addChaptergroups(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.addTags(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.addGenres(value);
      break;
    case 20:
      var value = new google_protobuf_wrappers_pb.DoubleValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.DoubleValue.deserializeBinaryFromReader);
      msg.setReviewscore(value);
      break;
    case 21:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setReviewcount(value);
      break;
    case 22:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setHasanysponsorplans(value);
      break;
    case 23:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIssneakpeek(value);
      break;
    case 24:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setPublicchaptercount(value);
      break;
    case 25:
      var value = new Protos_sponsors_pb.SponsorPlanItem;
      reader.readMessage(value,Protos_sponsors_pb.SponsorPlanItem.deserializeBinaryFromReader);
      msg.addSponsorplans(value);
      break;
    case 26:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wuxiaworld.api.v2.NovelItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wuxiaworld.api.v2.NovelItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.NovelItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSlug();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getActive();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getAbbreviation();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLanguage();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getVisible();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getSynopsis();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCoverurl();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTranslatorname();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTranslator();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      Protos_users_pb.UserItem.serializeBinaryToWriter
    );
  }
  f = message.getAuthorname();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getKarmaactive();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getIsfree();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getFreemaxchapternumber();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      Protos_types_pb.DecimalValue.serializeBinaryToWriter
    );
  }
  f = message.getChaptergroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      Protos_chapters_pb.ChapterGroupItem.serializeBinaryToWriter
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      18,
      f
    );
  }
  f = message.getGenresList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      19,
      f
    );
  }
  f = message.getReviewscore();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      google_protobuf_wrappers_pb.DoubleValue.serializeBinaryToWriter
    );
  }
  f = message.getReviewcount();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getHasanysponsorplans();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getIssneakpeek();
  if (f) {
    writer.writeBool(
      23,
      f
    );
  }
  f = message.getPublicchaptercount();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getSponsorplansList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      25,
      f,
      Protos_sponsors_pb.SponsorPlanItem.serializeBinaryToWriter
    );
  }
  f = message.getCreatedat();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wuxiaworld.api.v2.NovelItem} returns this
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wuxiaworld.api.v2.NovelItem} returns this
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string slug = 3;
 * @return {string}
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.getSlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wuxiaworld.api.v2.NovelItem} returns this
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.setSlug = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool active = 4;
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wuxiaworld.api.v2.NovelItem} returns this
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string abbreviation = 5;
 * @return {string}
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.getAbbreviation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wuxiaworld.api.v2.NovelItem} returns this
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.setAbbreviation = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional google.protobuf.StringValue language = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.getLanguage = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.wuxiaworld.api.v2.NovelItem} returns this
*/
proto.wuxiaworld.api.v2.NovelItem.prototype.setLanguage = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.NovelItem} returns this
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.clearLanguage = function() {
  return this.setLanguage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.hasLanguage = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bool visible = 7;
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.getVisible = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wuxiaworld.api.v2.NovelItem} returns this
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.setVisible = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional google.protobuf.StringValue description = 8;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.getDescription = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 8));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.wuxiaworld.api.v2.NovelItem} returns this
*/
proto.wuxiaworld.api.v2.NovelItem.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.NovelItem} returns this
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.StringValue synopsis = 9;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.getSynopsis = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 9));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.wuxiaworld.api.v2.NovelItem} returns this
*/
proto.wuxiaworld.api.v2.NovelItem.prototype.setSynopsis = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.NovelItem} returns this
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.clearSynopsis = function() {
  return this.setSynopsis(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.hasSynopsis = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.StringValue coverUrl = 10;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.getCoverurl = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 10));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.wuxiaworld.api.v2.NovelItem} returns this
*/
proto.wuxiaworld.api.v2.NovelItem.prototype.setCoverurl = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.NovelItem} returns this
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.clearCoverurl = function() {
  return this.setCoverurl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.hasCoverurl = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.StringValue translatorName = 11;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.getTranslatorname = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 11));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.wuxiaworld.api.v2.NovelItem} returns this
*/
proto.wuxiaworld.api.v2.NovelItem.prototype.setTranslatorname = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.NovelItem} returns this
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.clearTranslatorname = function() {
  return this.setTranslatorname(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.hasTranslatorname = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional UserItem translator = 12;
 * @return {?proto.wuxiaworld.api.v2.UserItem}
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.getTranslator = function() {
  return /** @type{?proto.wuxiaworld.api.v2.UserItem} */ (
    jspb.Message.getWrapperField(this, Protos_users_pb.UserItem, 12));
};


/**
 * @param {?proto.wuxiaworld.api.v2.UserItem|undefined} value
 * @return {!proto.wuxiaworld.api.v2.NovelItem} returns this
*/
proto.wuxiaworld.api.v2.NovelItem.prototype.setTranslator = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.NovelItem} returns this
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.clearTranslator = function() {
  return this.setTranslator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.hasTranslator = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.StringValue authorName = 13;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.getAuthorname = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 13));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.wuxiaworld.api.v2.NovelItem} returns this
*/
proto.wuxiaworld.api.v2.NovelItem.prototype.setAuthorname = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.NovelItem} returns this
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.clearAuthorname = function() {
  return this.setAuthorname(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.hasAuthorname = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional bool karmaActive = 14;
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.getKarmaactive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wuxiaworld.api.v2.NovelItem} returns this
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.setKarmaactive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional bool isFree = 15;
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.getIsfree = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wuxiaworld.api.v2.NovelItem} returns this
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.setIsfree = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional customTypes.DecimalValue freeMaxChapterNumber = 16;
 * @return {?proto.customTypes.DecimalValue}
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.getFreemaxchapternumber = function() {
  return /** @type{?proto.customTypes.DecimalValue} */ (
    jspb.Message.getWrapperField(this, Protos_types_pb.DecimalValue, 16));
};


/**
 * @param {?proto.customTypes.DecimalValue|undefined} value
 * @return {!proto.wuxiaworld.api.v2.NovelItem} returns this
*/
proto.wuxiaworld.api.v2.NovelItem.prototype.setFreemaxchapternumber = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.NovelItem} returns this
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.clearFreemaxchapternumber = function() {
  return this.setFreemaxchapternumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.hasFreemaxchapternumber = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * repeated ChapterGroupItem chapterGroups = 17;
 * @return {!Array<!proto.wuxiaworld.api.v2.ChapterGroupItem>}
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.getChaptergroupsList = function() {
  return /** @type{!Array<!proto.wuxiaworld.api.v2.ChapterGroupItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, Protos_chapters_pb.ChapterGroupItem, 17));
};


/**
 * @param {!Array<!proto.wuxiaworld.api.v2.ChapterGroupItem>} value
 * @return {!proto.wuxiaworld.api.v2.NovelItem} returns this
*/
proto.wuxiaworld.api.v2.NovelItem.prototype.setChaptergroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.wuxiaworld.api.v2.ChapterGroupItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wuxiaworld.api.v2.ChapterGroupItem}
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.addChaptergroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.wuxiaworld.api.v2.ChapterGroupItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wuxiaworld.api.v2.NovelItem} returns this
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.clearChaptergroupsList = function() {
  return this.setChaptergroupsList([]);
};


/**
 * repeated string tags = 18;
 * @return {!Array<string>}
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.getTagsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 18));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wuxiaworld.api.v2.NovelItem} returns this
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.setTagsList = function(value) {
  return jspb.Message.setField(this, 18, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wuxiaworld.api.v2.NovelItem} returns this
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.addTags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 18, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wuxiaworld.api.v2.NovelItem} returns this
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * repeated string genres = 19;
 * @return {!Array<string>}
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.getGenresList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 19));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wuxiaworld.api.v2.NovelItem} returns this
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.setGenresList = function(value) {
  return jspb.Message.setField(this, 19, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wuxiaworld.api.v2.NovelItem} returns this
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.addGenres = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 19, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wuxiaworld.api.v2.NovelItem} returns this
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.clearGenresList = function() {
  return this.setGenresList([]);
};


/**
 * optional google.protobuf.DoubleValue reviewScore = 20;
 * @return {?proto.google.protobuf.DoubleValue}
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.getReviewscore = function() {
  return /** @type{?proto.google.protobuf.DoubleValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.DoubleValue, 20));
};


/**
 * @param {?proto.google.protobuf.DoubleValue|undefined} value
 * @return {!proto.wuxiaworld.api.v2.NovelItem} returns this
*/
proto.wuxiaworld.api.v2.NovelItem.prototype.setReviewscore = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.NovelItem} returns this
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.clearReviewscore = function() {
  return this.setReviewscore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.hasReviewscore = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional google.protobuf.Int32Value reviewCount = 21;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.getReviewcount = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 21));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.wuxiaworld.api.v2.NovelItem} returns this
*/
proto.wuxiaworld.api.v2.NovelItem.prototype.setReviewcount = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.NovelItem} returns this
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.clearReviewcount = function() {
  return this.setReviewcount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.hasReviewcount = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional google.protobuf.BoolValue hasAnySponsorPlans = 22;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.getHasanysponsorplans = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 22));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.wuxiaworld.api.v2.NovelItem} returns this
*/
proto.wuxiaworld.api.v2.NovelItem.prototype.setHasanysponsorplans = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.NovelItem} returns this
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.clearHasanysponsorplans = function() {
  return this.setHasanysponsorplans(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.hasHasanysponsorplans = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional bool isSneakPeek = 23;
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.getIssneakpeek = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 23, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wuxiaworld.api.v2.NovelItem} returns this
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.setIssneakpeek = function(value) {
  return jspb.Message.setProto3BooleanField(this, 23, value);
};


/**
 * optional google.protobuf.Int32Value publicChapterCount = 24;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.getPublicchaptercount = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 24));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.wuxiaworld.api.v2.NovelItem} returns this
*/
proto.wuxiaworld.api.v2.NovelItem.prototype.setPublicchaptercount = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.NovelItem} returns this
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.clearPublicchaptercount = function() {
  return this.setPublicchaptercount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.hasPublicchaptercount = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * repeated SponsorPlanItem sponsorPlans = 25;
 * @return {!Array<!proto.wuxiaworld.api.v2.SponsorPlanItem>}
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.getSponsorplansList = function() {
  return /** @type{!Array<!proto.wuxiaworld.api.v2.SponsorPlanItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, Protos_sponsors_pb.SponsorPlanItem, 25));
};


/**
 * @param {!Array<!proto.wuxiaworld.api.v2.SponsorPlanItem>} value
 * @return {!proto.wuxiaworld.api.v2.NovelItem} returns this
*/
proto.wuxiaworld.api.v2.NovelItem.prototype.setSponsorplansList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 25, value);
};


/**
 * @param {!proto.wuxiaworld.api.v2.SponsorPlanItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wuxiaworld.api.v2.SponsorPlanItem}
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.addSponsorplans = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 25, opt_value, proto.wuxiaworld.api.v2.SponsorPlanItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wuxiaworld.api.v2.NovelItem} returns this
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.clearSponsorplansList = function() {
  return this.setSponsorplansList([]);
};


/**
 * optional google.protobuf.Timestamp createdAt = 26;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.getCreatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 26));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wuxiaworld.api.v2.NovelItem} returns this
*/
proto.wuxiaworld.api.v2.NovelItem.prototype.setCreatedat = function(value) {
  return jspb.Message.setWrapperField(this, 26, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.NovelItem} returns this
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.clearCreatedat = function() {
  return this.setCreatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.NovelItem.prototype.hasCreatedat = function() {
  return jspb.Message.getField(this, 26) != null;
};


goog.object.extend(exports, proto.wuxiaworld.api.v2);
