import equal from 'fast-deep-equal';
import * as grpc from 'grpc-web';
import { useEffect, useMemo, useReducer } from 'react';
import { useHistory } from 'react-router';
import { AuthContext, AuthenticationProvider, getLocalUser, retrieveUser, silentLogin, User } from '../libs/auth';
import { HttpError } from '../libs/fetch';
import { GrpcError } from '../libs/grpc';

/**
 * Custom.
 */
export type AuthComponentProps = {
    children?: React.ReactNode;
};

/**
 * Custom.
 */
export type AuthContextState = {
    init: boolean;
    user?: User | null;
};

/**
 * Custom.
 */
export type AuthContextAction = {
    type: 'SET-USER' | 'SET-INIT';
    payload?: unknown;
} & (
    | {
          type: 'SET-USER';
          payload: User | null;
      }
    | {
          type: 'SET-INIT';
          payload: boolean;
      }
);

/**
 * Custom.
 */
function authContextReducer(state: AuthContextState, action: AuthContextAction): AuthContextState {
    switch (action.type) {
        case 'SET-USER':
            if (typeof window !== 'undefined') {
                if (action.payload) {
                    localStorage.setItem('user', JSON.stringify(action.payload));
                } else {
                    localStorage.removeItem('user');
                }
            }

            return { ...state, init: true, user: action.payload };
        case 'SET-INIT':
            return { ...state, init: action.payload };
        default:
            return state;
    }
}

/**
 * Custom.
 */
export default function AuthComponent({ children }: AuthComponentProps) {
    const { location } = useHistory();

    const [authContextState, dispatchContext] = useReducer(authContextReducer, {
        init: true,
        user: getLocalUser(),
    });

    const authContext = useMemo<AuthContext>(
        () => ({
            ...authContextState,
            setUser: (user: User) => {
                dispatchContext({
                    type: 'SET-USER',
                    payload: user,
                });
            },
        }),
        [authContextState]
    );

    useEffect(() => {
        async function retrieveUserAsync(user: User) {
            try {
                const userInfo = await retrieveUser(user.accessToken);

                if (userInfo) {
                    if (!equal(authContextState.user, userInfo)) {
                        dispatchContext({
                            type: 'SET-USER',
                            payload: {
                                ...userInfo,
                                accessToken: user.accessToken,
                            },
                        });
                    }
                }
            } catch (e) {
                console.error(e);

                if (e instanceof HttpError && e.response.status === 401) {
                    dispatchContext({
                        type: 'SET-USER',
                        payload: null,
                    });
                } else if (e instanceof GrpcError && e.status === grpc.StatusCode.UNAUTHENTICATED) {
                    dispatchContext({
                        type: 'SET-USER',
                        payload: null,
                    });
                }
            }
        }

        const localUser = getLocalUser();

        if (localUser && !location.pathname.startsWith('/auth/')) {
            retrieveUserAsync(localUser);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <AuthenticationProvider value={authContext}>{children}</AuthenticationProvider>;
}
