import { useTheme } from '@emotion/react';
import loadable from '@loadable/component';
import {
    AppBar,
    Button,
    Collapse,
    Container,
    IconButton,
    Slide,
    Switch,
    Toolbar,
    Typography,
    useScrollTrigger,
} from '@material-ui/core';
import { Brightness3, PersonOutline, Search, WbSunny } from '@material-ui/icons';
import * as React from 'react';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../libs/auth';
import { batch } from '../../libs/utils';
import { Hidden } from '../hidden';
import CustomLink from '../link';
import { ContentCenter } from '../shared/generic.styles';
import { StyledSwitchContainer } from '../shared/switch.styles';
import SiteLogo from '../site.logo';
import SiteName from '../site.name';
import MegaMenu from './mega.menu';
import NovelsMenu from './novels.menu';
import HeaderSearch from './search';
import {
    StyledAdText,
    StyledAppBar,
    StyledBottomToolbar,
    StyledCenterNav,
    StyledCollapsibleContainer,
    StyledCollapsibleContent,
    StyledContainer,
    StyledIconButton,
    StyledLeftNav,
    StyledLinksBar,
    StyledLogoLink,
    StyledLogoText,
    StyledNavContainer,
    StyledNavLink,
    StyledRightNav,
    StyledSearchContainer,
    StyledSwitch,
    StyledTopToolbar,
} from './styles';

/** Custom. Menu for authenticated user. Load only on demand. */
const DynamicHeaderAuthenticated = loadable(() => import('./authenticated'));

//#region : Helper local function components to reduce main component code length

/**
 * Custom.
 *
 * If no collapsible header menus are open,
 * it hides the header on scroll.
 */
function HideOnScroll({ children, noOfCollapsedMenu }: { children: ReactElement; noOfCollapsedMenu: number }) {
    const trigger = useScrollTrigger();

    const shouldTrigger = noOfCollapsedMenu > 0 ? true : !trigger;

    return (
        <Slide appear={false} direction="down" in={shouldTrigger}>
            {children}
        </Slide>
    );
}

/** Custom. Auth menu items for unauthenticated user. */
function AuthButtons({ login }) {
    const AUTHORITY = process.env.REACT_APP_AUTH_AUTHORITY;

    return (
        <>
            <Button size="medium" variant="outlined" color="secondary" href={`${AUTHORITY}/account/register`}>
                Sign Up
            </Button>
            &nbsp;
            <Button onClick={() => login(window.location.pathname)} size="medium">
                Login
            </Button>
        </>
    );
}

/** Custom. Misc. links to be shown in the header. */
function NavLinks() {
    return (
        <>
            <MegaMenu height={500} width={800} link={{ href: '/novels', label: 'Novels' }}>
                <NovelsMenu />
            </MegaMenu>
            {/* <CustomLink css={StyledNavLink} href="/novels">
                Novels
            </CustomLink> */}
            <CustomLink css={StyledNavLink} href="/manage/bookmarks">
                Bookmarks
            </CustomLink>
            <CustomLink css={StyledNavLink} href="/audiobooks">
                Audiobooks
            </CustomLink>
            <CustomLink css={StyledNavLink} href="/ebooks">
                Ebooks
            </CustomLink>
            {/* <CustomLink css={StyledNavLink} href="/about">About</CustomLink> */}
        </>
    );
}
//#endregion : Helper local function components to reduce main component code length

//#region : Main component

/** Custom. Main component parameters type */
type HeaderProps = {
    onDispatchTheme: React.Dispatch<{ type: 'toggle-theme' }>;
};

/** Custom. Header. Hides on scroll when no header collapsible menus are open. */
function Header({ onDispatchTheme }: HeaderProps) {
    //#region : Variables, functions and api calls

    //General
    const theme = useTheme();
    const { user, login } = useAuth();

    //#region : Switch action handler

    /** Custom. Theme switch toggle handler. */
    const onSwitchTheme = useCallback(() => {
        onDispatchTheme({
            type: 'toggle-theme',
        });
    }, [onDispatchTheme]);

    //#endregion : Switch action handler

    //#region : Handle collapsible content in header

    //#region : Handlers to keep track of number of collapsible menus open.
    const [collapsibleState, setCollapsibleState] = useState(0);

    /** Custom. Call when collapsible content is 'Entering' collapsible mode */
    const handleCollapseOpen = () => {
        setCollapsibleState(prev => prev + 1);
    };

    /** Custom. Call when collapsible content is 'Closed' completely. */
    const handleCollapseClose = () => {
        setCollapsibleState(prev => (prev > 0 ? prev - 1 : 0));
    };
    //#endregion : Handlers to keep track of number of collapsible menus open.

    //#region : Search bar
    const [showSearchBar, setShowSearchBar] = useState(false);

    /** Custom. Toggle search bar. */
    const handleShowSearchBarChange = () => {
        setShowSearchBar(prev => !prev);
    };
    //#endregion : Search bar

    //#region : Auth bar
    const [showAuthBar, setShowAuthBar] = useState(false);

    /** Custom. Toggle auth bar */
    const handleShowAuthBarChange = () => {
        setShowAuthBar(prev => !prev);
    };

    //hide auth bar after user login
    useEffect(() => {
        setShowAuthBar(prev => (user ? false : prev));
    }, [user]);

    //#endregion : Auth bar

    //hide collapsible bars when user navigates to another page.
    const location = useLocation();
    useEffect(() => {
        batch(() => {
            setShowAuthBar(false);
            setShowSearchBar(false);
        });
    }, [location.pathname]);

    //#endregion : Handle collapsible content in header

    //#endregion : Variables, functions and api calls

    return (
        <React.Fragment>
            {(!user || !user.isvipactive) && (
                <Toolbar css={StyledTopToolbar} disableGutters>
                    <Container css={StyledContainer}>
                        <Typography variant="caption" css={StyledAdText}>
                            Want to go Ad-free? Click here!
                        </Typography>
                    </Container>
                </Toolbar>
            )}
            <HideOnScroll noOfCollapsedMenu={collapsibleState}>
                <AppBar css={StyledAppBar} color="inherit" position="sticky" elevation={0}>
                    <Toolbar css={StyledBottomToolbar} disableGutters>
                        <Container css={StyledContainer}>
                            <div css={StyledLeftNav}>
                                <CustomLink css={StyledLogoLink} href="/" aria-label="Wuxiaworld">
                                    <Typography css={StyledLogoText} component="h2" variant="h4" align="center" noWrap>
                                        <SiteLogo />
                                        <Hidden smDown>
                                            <SiteName />
                                        </Hidden>
                                    </Typography>
                                </CustomLink>
                            </div>
                            <div css={StyledCenterNav}>
                                <Hidden smDown>
                                    <NavLinks />
                                </Hidden>
                            </div>
                            <div css={StyledRightNav}>
                                <Hidden mdDown>
                                    <div css={StyledNavContainer}>
                                        <div css={StyledSearchContainer}>
                                            <Search />
                                        </div>
                                        <HeaderSearch />
                                    </div>
                                </Hidden>
                                <Hidden mdUp>
                                    <IconButton
                                        css={StyledIconButton}
                                        onClick={handleShowSearchBarChange}
                                        aria-label="search"
                                    >
                                        <Search />
                                    </IconButton>
                                </Hidden>
                                {user ? (
                                    <>
                                        <DynamicHeaderAuthenticated />
                                    </>
                                ) : (
                                    <>
                                        <Hidden mdDown>
                                            <AuthButtons login={login} />
                                        </Hidden>
                                        <Hidden mdUp>
                                            <IconButton
                                                css={StyledIconButton}
                                                onClick={handleShowAuthBarChange}
                                                aria-label="login"
                                            >
                                                <PersonOutline />
                                            </IconButton>
                                        </Hidden>
                                    </>
                                )}
                                <div css={StyledSwitchContainer} onClick={onSwitchTheme}>
                                    <Switch
                                        css={StyledSwitch}
                                        checkedIcon={<Brightness3 />}
                                        icon={<WbSunny />}
                                        checked={theme.palette.mode === 'dark'}
                                        inputProps={{ 'aria-label': 'theme switch' }}
                                    />
                                </div>
                            </div>

                            <Hidden smUp>
                                <div css={StyledLinksBar}>
                                    <NavLinks />
                                </div>
                            </Hidden>

                            <div css={StyledCollapsibleContainer}>
                                <Hidden mdUp>
                                    <Collapse
                                        in={showSearchBar}
                                        onEnter={handleCollapseOpen}
                                        onExited={handleCollapseClose}
                                    >
                                        <div css={StyledCollapsibleContent}>
                                            <HeaderSearch />
                                        </div>
                                    </Collapse>
                                </Hidden>
                            </div>
                            <div css={StyledCollapsibleContainer}>
                                <Hidden mdUp>
                                    <Collapse
                                        in={showAuthBar}
                                        onEnter={handleCollapseOpen}
                                        onExited={handleCollapseClose}
                                    >
                                        <div css={StyledCollapsibleContent}>
                                            <div css={ContentCenter}>
                                                <AuthButtons login={login} />
                                            </div>
                                        </div>
                                    </Collapse>
                                </Hidden>
                            </div>
                        </Container>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>
        </React.Fragment>
    );
}

/** Custom. Header. Hides on scroll when no header collapsible menus are open. */
export default React.memo(Header);
//#endregion : Main component
