import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';
import { useCallback, useEffect, useRef, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { DecimalValue } from '../_proto/Protos/types_pb';

/**
 * Custom.
 *
 * Use it when you want to update more than one state within callbacks to avoid
 * multiple re-renders.
 *
 * Explanation:
 *
 * By default, React batches updates made in a known method like the lifecycle methods
 * or event handlers, but doesn’t do the same when the updates are within callbacks
 * like in SetTimeout or Promises. This means that if you have multiple calls to update the state,
 * React re-renders the component each time the call is made.
 *
 * In this function, we are forcing React to make batched updates, hence causing only one re-render.
 */
export const batch = (callback: () => void): void => {
    return unstable_batchedUpdates(callback);
};

/**
 * Custom.
 *
 * Returns ref if it has mounted, otherwise return undefined or null.
 */
const resolveRef = ref => {
    if (typeof document === 'undefined') return undefined;
    if (ref == null) return document.body;
    if (typeof ref === 'function') ref = ref();

    if (ref && ref.current) ref = ref.current;
    if (ref && ref.nodeType) return ref;

    return null;
};

/**
 * Custom hook.
 *
 * Use it when you need to know when an element has been mounted in the DOM.
 */
export function useWaitForDOMRef(ref: Element, onResolved?: (el: Element) => void) {
    const [resolvedRef, setRef] = useState<Element>(() => resolveRef(ref));

    if (!resolvedRef) {
        const earlyRef = resolveRef(ref);
        if (earlyRef) setRef(earlyRef);
    }

    useEffect(() => {
        if (onResolved && resolvedRef) {
            onResolved(resolvedRef);
        }
    }, [onResolved, resolvedRef]);

    useEffect(() => {
        const nextRef = resolveRef(ref);
        if (nextRef !== resolvedRef) {
            setRef(nextRef);
        }
    }, [ref, resolvedRef]);

    return resolvedRef;
}

/**
 * Custom type.
 *
 * Used by 'useScrollTo' custom hook as parameter type.
 */
export type ScrollToOptions = {
    offset: number;
    onComplete?: () => void;
};

/**
 * Custom hook.
 *
 * Use it when you need to scroll using offset
 * and require to know when the scroll is complete.
 */
export const useScrollTo = ({ offset, onComplete }: ScrollToOptions) => {
    const [scrollActive, setScrollActive] = useState(false);

    const completeCallback = useRef(onComplete);
    completeCallback.current = onComplete;

    //The actual scroll trigger.
    const triggerScroll = useCallback(() => {
        setScrollActive(true);

        window.scrollTo({
            top: offset,
            behavior: 'smooth',
        });
    }, [offset]);

    //Listens to the scroll event
    //and calls the onComplete parameter function when it's done.
    useEffect(() => {
        const onScroll = function () {
            const fixedOffset = offset.toFixed();

            //We have reached the offset point
            if (window.pageYOffset.toFixed() === fixedOffset) {
                window.removeEventListener('scroll', onScroll);

                //Call the onComplete parameter function
                completeCallback.current?.();

                setScrollActive(false);
            }
        };

        if (scrollActive) {
            window.addEventListener('scroll', onScroll);
        }

        return () => {
            //Remove the event listener when component is unmounted
            if (typeof window !== 'undefined') {
                window.removeEventListener('scroll', onScroll);
            }
        };
    }, [offset, scrollActive]);

    return {
        triggerScroll,
    };
};

/**
 * Custom hook.
 *
 * Use it when you need to keep track of whether
 * the UI has been rendered or not.
 */
export const useIsMounted = () => {
    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true;
    }, []);

    return isMounted.current;
};

/**
 * Custom type.
 *
 * Parameter type used by 'useEventCallback'
 */
type EventCallback<TResult, TArgs extends any[]> = (...args: TArgs) => Promise<TResult>;

/**
 * Custom hook.
 *
 * Use it when you need to memoize a callback that has an inner function that has to be re-created too often.
 * Make sure that the callback isn't used during rendering.
 */
export const useEventCallback = <TResult, TArgs extends any[] = any[]>(cb: EventCallback<TResult, TArgs>) => {
    let ref = useRef(cb);

    useEffect(() => {
        ref.current = cb;
    });

    return useCallback((...args: TArgs) => ref.current(...args), [ref]);
};

/**
 * Custom.
 *
 * Nano factor for converting protobuf decimal to number
 * and number to decimal.
 */
const NanoFactor = 1_000_000_000;

/**
 * Custom.
 *
 * Converts protobuf type DecimalValue to number.
 */
export const decimalToNumber = (value: DecimalValue | DecimalValue.AsObject | undefined) => {
    if (!value) {
        return 0;
    }

    if (value instanceof DecimalValue) {
        return value.getUnits() + value.getNanos() / NanoFactor;
    }

    return value.units + value.nanos / NanoFactor;
};

/**
 * Custom.
 *
 * Converts number to protobuf type DecimalValue
 */
export const numberToDecimal = (value: number | null): DecimalValue | null => {
    if (value === null) {
        return null;
    }

    const units = Math.trunc(value);
    const nanos = Math.trunc(Number((value - units).toFixed(12)) * NanoFactor);

    const decimal = new DecimalValue();
    decimal.setUnits(units);
    decimal.setNanos(nanos);

    return decimal;
};

/**
 * Custom.
 *
 * Converts protobuf type Timestamp to Date
 */
export const timestampToDate = (value: Timestamp | Timestamp.AsObject | undefined) => {
    if (!value) {
        return null;
    }

    if (!(value instanceof Timestamp)) {
        const newValue = new Timestamp();
        newValue.setSeconds(value.seconds);
        newValue.setNanos(value.nanos);

        value = newValue;
    }

    return value.toDate();
};

/**
 * Custom.
 *
 * Converts protobuf type Timestamp to unix epoch time.
 */
export const timestampToUnix = (value: Timestamp | Timestamp.AsObject | undefined) => {
    if (!value) {
        return null;
    }

    if (value instanceof Timestamp) {
        return (value.getSeconds() * 1000 + value.getNanos() / 1000000) / 1000;
    }

    return (value.seconds * 1000 + value.nanos / 1000000) / 1000;
};
