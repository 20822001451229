import { Autocomplete, AutocompleteRenderInputParams, CircularProgress, TextField } from '@material-ui/core';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { StringValue } from 'google-protobuf/google/protobuf/wrappers_pb';
import * as React from 'react';
import { useCallback, useState } from 'react';
import { useAsync } from 'react-async-hook';
import { useHistory } from 'react-router-dom';
import useConstant from 'use-constant';
import { createGrpcRequest } from '../../libs/grpc';
import { useHttp } from '../../libs/http';
import { NovelsClient } from '../../_proto/Protos/novels_grpc_web_pb';
import { NovelItem, SearchNovelsRequest } from '../../_proto/Protos/novels_pb';
import { StyledAutoComplete, StyledSearchCircularProgress } from './styles';

//#region : Main component

/** Custom. Shows textbox for searching and handles autocomplete feature. */
export default function HeaderSearch() {
    //#region : Variables, functions and api calls

    //General
    const [searchQuery, setSearchQuery] = useState('');

    //#region : Server request to fetch search results

    const { grpcRequest } = useHttp();

    const searchNovelsAsync = useCallback(
        async (searchQuery: string) => {
            if (!searchQuery || searchQuery.length === 0) {
                return [];
            }

            const request = createGrpcRequest(new SearchNovelsRequest(), {
                title: {
                    message: () => new StringValue(),
                    value: searchQuery,
                },
                count: 5,
            });

            const response = await grpcRequest(NovelsClient, c => c.searchNovels, request);
            const novels = response.toObject();

            return novels.itemsList || [];
        },
        [grpcRequest]
    );

    const debouncedSearch = useConstant(() => AwesomeDebouncePromise(searchNovelsAsync, 250));
    const searchResults = useAsync(debouncedSearch, [searchQuery]);

    //#endregion : Server request to fetch search results

    //#region : Autocomplete callbacks
    const history = useHistory();

    /** Custom. */
    const goToNovel = useCallback(
        async (e, novel: NovelItem.AsObject) => {
            if (!novel?.slug) {
                return;
            }

            history.push(`/novel/${novel.slug}`);

            setTimeout(() => setSearchQuery(''));
        },
        [history]
    );

    /** Custom. */
    const handleGetOptionLabel = useCallback(option => {
        return (option as any)?.name || '';
    }, []);

    /** Custom. */
    const onChangeSearchQuery = useCallback((e, value: string) => {
        if (value) {
            setSearchQuery(value);
        } else {
            setSearchQuery('');
        }
    }, []);

    /** Custom. */
    const handleFilterOpen = useCallback(option => {
        return option;
    }, []);

    /** Custom. */
    const renderInput = useCallback(
        (params: AutocompleteRenderInputParams) => {
            return (
                <TextField
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        placeholder: 'Search…',
                        endAdornment: (
                            <React.Fragment>
                                {searchQuery.length > 0 && searchResults.loading ? (
                                    <CircularProgress css={StyledSearchCircularProgress} color="inherit" size={20} />
                                ) : (
                                    <div css={StyledSearchCircularProgress}></div>
                                )}
                                {searchQuery.length > 0 && params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                        inputProps: {
                            ...params.inputProps,
                            className: '',
                            'aria-label': 'search',
                        },
                    }}
                />
            );
        },
        [searchQuery.length, searchResults.loading]
    );
    //#endregion : Autocomplete callbacks

    //#endregion : Variables, functions and api calls

    return (
        <Autocomplete
            css={StyledAutoComplete}
            freeSolo
            options={searchResults.result || []}
            getOptionLabel={handleGetOptionLabel}
            onInputChange={onChangeSearchQuery}
            filterOptions={handleFilterOpen}
            onChange={goToNovel}
            inputValue={searchQuery}
            autoHighlight
            renderInput={renderInput}
        />
    );
}
//#endregion : Main component
