import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

/**
 * Custom type.
 */
type ColorSchemeName = 'no-preference' | 'dark' | 'light';

/**
 * Custom type.
 */
type ColorScheme = {
    query: MediaQueryList | null;
    scheme: ColorSchemeName;
} | null;

/**
 * Custom.
 *
 * Array of color scheme names.
 */
const COLOR_SCHEMES: ColorSchemeName[] = ['no-preference', 'dark', 'light'];

/**
 * Custom.
 *
 * Site's default color scheme.
 */
const DEFAULT_TARGET_COLOR_SCHEME: ColorSchemeName = 'light';

/**
 * Custom function.
 *
 * Returns default color scheme if the provided scheme is invalid
 * or is set to no-preference.
 */
function resolveTargetColorScheme(scheme: ColorSchemeName) {
    if (!COLOR_SCHEMES.includes(scheme) || scheme === 'no-preference') {
        return DEFAULT_TARGET_COLOR_SCHEME;
    }

    return scheme;
}

/**
 * Custom function.
 *
 * Get user's system/browser setting of dark mode preference, if available.
 */
function getCurrentColorScheme(): ColorScheme {
    if (!isBrowser()) {
        return { query: null, scheme: DEFAULT_TARGET_COLOR_SCHEME };
    }

    const QUERIES = {};

    return (function () {
        //Check user prefers any of the color schemes supported by the site
        //Check the css media feature `prefers-color-scheme` using media query.
        for (const scheme of COLOR_SCHEMES) {
            // eslint-disable-next-line no-prototype-builtins
            const query = QUERIES.hasOwnProperty(scheme)
                ? QUERIES[scheme]
                : (QUERIES[scheme] = matchMedia(`(prefers-color-scheme: ${scheme})`));

            if (query.matches) return { query, scheme };
        }

        return null;
    })();
}

/**
 * Custom hook.
 *
 * Returns 'useLayoutEffect' for browser and 'useEffect' for non-browser.
 */
export const useEnhancedEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect;

/**
 * Custom function.
 *
 * Generates random number between the given range.
 */
export function randomNumberBetween(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

/**
 * Custom function.
 *
 * Generates hashcode for the given string.
 */
export function getHashCode(str: string) {
    if (!str) {
        return 0;
    }

    var hash = 0,
        i,
        chr;
    for (i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
    }

    return hash;
}

/**
 * Custom function.
 *
 * Checks if the client is a browser or not.
 */
export function isBrowser() {
    return typeof window !== 'undefined';
}

/**
 * Custom hook.
 *
 * Checks if the client is a browser or not.
 */
export function useIsBrowser() {
    const [browser, setBrowser] = useState(false);

    const result = isBrowser();

    useEnhancedEffect(() => {
        setBrowser(result);
    }, [result]);

    return browser;
}

/**
 * Custom hook.
 *
 * Returns true if the color scheme provide in the parameter matches
 * user's system/browser color scheme preference i.e. light/dark/no-preference.
 * Otherwise returns false.
 *
 * Also handles any change in the user's system / browser color scheme preference.
 */
export function useColorScheme(targetColorScheme: ColorSchemeName) {
    /** Custom. Keeps track of whether the component is mounted or not */
    const isMounted = useRef(false);

    /** Custom. Stores user's system/browser color scheme preference with media query object.  */
    const colorScheme = useRef<ColorScheme>();

    /**
     * Custom.
     *
     * Will have the colorScheme provided in the parameter, if it is valid.
     * Otherwise it will fallback to site's default color scheme.
     */
    const targetScheme = useMemo(() => resolveTargetColorScheme(targetColorScheme), [targetColorScheme]);

    /** Stores user's system/browser color scheme preference. */
    const [scheme, setColorScheme] = useState(() => {
        const { scheme } = (colorScheme.current = getCurrentColorScheme()!);

        return scheme;
    });

    //Handles any change in the user's system / browser color scheme preference.
    useEffect(() => {
        const { query } = colorScheme.current || {};

        /** Custom. User's system / browser color scheme preference change listener and handler. */
        function schemeChangeHandler(evt: MediaQueryListEvent) {
            if (!evt.matches) {
                query!.removeEventListener('change', schemeChangeHandler);

                const { query: newQuery, scheme } = (colorScheme.current = getCurrentColorScheme()!);

                isMounted.current && setColorScheme(scheme);

                newQuery?.addEventListener('change', schemeChangeHandler);
            }
        }

        query?.addEventListener?.('change', schemeChangeHandler);

        isMounted.current = true;

        //Remove the event listener when component dismounts
        return () => {
            const { query } = colorScheme.current || {};

            query?.removeEventListener?.('change', schemeChangeHandler);

            isMounted.current = false;
        };
    }, []);

    return scheme === targetScheme;
}

/**
 * Custom.
 *
 * Default config values for currency display formatting.
 */
const DEFAULT_CURRENCY_FORMAT = new Intl.NumberFormat(['en-US'], {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'symbol',
});

/**
 * Custom function.
 *
 * Formats given amount in USD for display with currency symbol.
 */
export function formatAmountForDisplay(amount: number): string {
    return DEFAULT_CURRENCY_FORMAT.format(amount);
}
