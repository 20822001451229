import { css, Theme } from '@emotion/react';
import { alpha } from '@material-ui/core/styles';
import { StyledAppDefaultMargins } from '../shared/app-main-container.styles';
import { ContentAlignCenter, ContentCenter } from '../shared/generic.styles';

export const StyledAppBar = (theme: Theme) => css({});

export const StyledAdText = (theme: Theme) =>
    css({
        alignSelf: 'center',
        color: theme.palette.text.disabled,
    });

export const StyledContainer = (theme: Theme) =>
    css(StyledAppDefaultMargins(theme), {
        display: 'flex',
        flexWrap: 'wrap',
        height: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            justifyContent: 'flex-end',
        },
    });

export const StyledToolbar = (theme: Theme) =>
    css({
        backgroundColor: theme.palette.background.default,
        flexWrap: 'wrap',
        fontSize: '14px',
    });

export const StyledTopToolbar = (theme: Theme) =>
    css(StyledToolbar(theme), {
        minHeight: '43px !important',
        borderBottom: '1px solid #E2E2E2',
        zIndex: 1100,
    });

export const StyledBottomToolbar = (theme: Theme) =>
    css(StyledToolbar(theme), {
        height: 'auto',
        boxShadow: `0px 2px 10px 0px rgba(0, 0, 0, 0.1)`,
        clipPath: 'polygon(0% 0%, 100% 0%, 100% 120%, 0% 120%)',
        '&.MuiToolbar-root': {
            alignItems: 'flex-end',
        },
    });

export const StyledNav = (theme: Theme) =>
    css({
        paddingBottom: theme.typography.pxToRem(5),
        paddingTop: theme.typography.pxToRem(5),
    });

export const StyledLeftNav = (theme: Theme) => css(StyledNav(theme), ContentAlignCenter(theme), {});

export const StyledCenterNav = (theme: Theme) =>
    css(ContentAlignCenter(theme), {
        flex: 1,
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing(3),
        },
        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(1),
        },
    });

export const StyledRightNav = (theme: Theme) =>
    css(StyledNav(theme), ContentAlignCenter(theme), {
        flexWrap: 'wrap',
        marginLeft: 'auto',
    });

export const StyledLogoLink = (theme: Theme) =>
    css({
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(1),
        color: theme.palette.common.white,
        '&:hover': {
            textDecoration: 'none',
        },
    });

export const StyledNavLink = (theme: Theme) =>
    css(ContentAlignCenter(theme), {
        position: 'relative',
        height: '100%',
        color: theme.palette.text.primary,
        fontSize: theme.typography.pxToRem(16),
        fontWeight: theme.typography.fontWeightMedium,
        marginRight: theme.spacing(2),
        '&::after': {
            content: '""',
            borderTop: '4px solid #0CA0FF',
            transition: 'opacity 0.3s',
            opacity: 0,
            zIndex: -1,
            bottom: 0,
            left: 0,
            position: 'absolute',
            width: '100%',
            height: '4px',
        },
        '&.active, :hover': {
            '&::after': {
                opacity: 1,
            },
        },
        ':hover': {
            textDecoration: 'none',
        },
    });

export const StyledLogoText = (theme: Theme) => css(ContentCenter(theme), {});

export const StyledNavContainer = (theme: Theme) =>
    css({
        width: 170,
        height: 40,
        position: 'relative',
        marginRight: theme.spacing(3),
        borderRadius: 19.5,
        backgroundColor: alpha(theme.palette.common.black, 1),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.black, 0.25),
        },
        marginLeft: 0,
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    });

export const StyledSearchContainer = (theme: Theme) =>
    css(ContentCenter(theme), {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
    });

export const StyledAutoComplete = (theme: Theme) =>
    css({
        '& .MuiInputBase-root': {
            color: 'inherit',
            borderRadius: 19.5,
        },
        '& .MuiInputBase-input': {
            padding: 0,
            // padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            marginLeft: `calc(1em + ${theme.spacing(4)})`,
            transition: theme.transitions.create('width'),
            width: '80%',
            height: '20px',
            [theme.breakpoints.up('sm')]: {
                width: '12ch',
                '&:focus': {
                    width: '20ch',
                },
            },
        },
        '& .MuiOutlinedInput-notchedOutline': {
            // borderColor: 'rgba(255, 255, 255, 0.23) !important'
        },
    });

export const StyledNotificationsMenu = (theme: Theme) =>
    css({
        maxHeight: '500px',
        overflowY: 'auto',
        '.MuiMenu-list': {
            paddingTop: 0,
        },
        '& .MuiMenuItem-root': {
            padding: theme.spacing(2),
            background: theme.palette.background.default,
            outline: 'none',
            minWidth: '350px',
        },
    });

export const StyledHeaderMenu = (theme: Theme) =>
    css({
        '& .MuiList-root': {
            paddingRight: '0px !important',
            '& .MuiListItemText-root *': {
                [theme.breakpoints.up('sm')]: {
                    lineHeight: '1rem',
                },
            },
            '& .MuiDivider-root': {
                borderColor: theme.name == 'dark' ? '#474747' : undefined,
            },
        },
    });

export const StyledCircularProgress = (theme: Theme) =>
    css({
        margin: theme.spacing(1),
    });

export const StyledSwitch = (theme: Theme) =>
    css({
        '&.MuiSwitch-root': {
            width: 74,
            height: 40,
            padding: 0,
            margin: theme.spacing(1),
            marginRight: '0',
            willChange: 'transform',
        },
        '& .MuiSwitch-switchBase': {
            height: 'calc(100% - 1px)',
            position: 'absolute',
            transform: 'translateZ(0) !important',
            padding: theme.typography.pxToRem(9),
            color: theme.palette.mode === 'light' ? '#000' : undefined,
            '& .MuiIconButton-label': {
                borderRadius: '100%',
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.primary.main,
                boxShadow: `0 0 0 1pt ${theme.palette.secondary.main}`,

                '& .MuiSvgIcon-root': {
                    padding: theme.spacing(0.5),
                },
            },
        },
        '& .MuiSwitch-track': {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 19.5,
            opacity: 1,
            backgroundColor: theme.palette.common.black,
        },
        '& .Mui-checked': {
            color: theme.palette.common.white,
            transform: 'translateZ(0) translateX(100%) translateX(-8px) !important',
            '& + .MuiSwitch-track': {
                backgroundColor: `${theme.palette.action.disabled} !important`,
            },
        },
    });

export const StyledCollapsibleContainer = (theme: Theme) =>
    css({
        display: 'block',
        width: '100%',
        margin: 'auto',
    });

export const StyledLinksBar = (theme: Theme) =>
    css({
        display: 'flex',
        width: '100%',
        overflow: 'auto',
    });

export const StyledIconButton = (theme: Theme) =>
    css({
        padding: theme.spacing(1),
        margin: theme.spacing(0, 0.5),
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        border: '2px solid #0CA0FF',
        '& .MuiAvatar-root': {
            backgroundColor: 'transparent',
            border: 'none',
            color: 'inherit',
        },
    });

export const StyledIconButtonNoPadding = (theme: Theme) =>
    css(StyledIconButton(theme), {
        padding: theme.spacing(0),
    });

export const StyledBadge = (theme: Theme) =>
    css({
        //borderRadius: '50%',
        //padding: theme.spacing(0.5),
        //backgroundColor: theme.palette.common.black,
        //color: theme.palette.common.white,
        //border: '2px solid #0CA0FF',
    });

export const StyledVipBadge = (theme: Theme) =>
    css(ContentAlignCenter(theme), {
        padding: theme.spacing(1),
        backgroundColor: '#000',
        borderRadius: theme.typography.pxToRem(16),
        width: 'fit-content',
        '& *': {
            margin: theme.spacing(0, 0.3),
            fontSize: '1rem',
            lineHeight: '0.75rem',
            color: '#fff',
        },
    });

export const StyledCollapsibleContent = (theme: Theme) =>
    css({
        margin: theme.spacing(2, 0),
    });

export const StyledSearchCircularProgress = (theme: Theme) =>
    css({
        position: 'absolute',
        top: 'calc(50% - 10px)',
        right: theme.typography.pxToRem(36),
        width: '30px',
    });

export const StyledArrow = (theme: Theme) =>
    css({
        position: 'relative',
        marginLeft: '42px',
        marginBottom: '1em',
        paddingTop: '1em',
        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            display: 'block',
            width: 0,
            height: 0,
            borderStyle: 'solid',
            borderColor: 'transparent',
            borderTop: 0,
        },
        '&:before': {
            bottom: '-17px',
            left: '21px',
            borderBottomColor: '#0CA0FF',
            borderWidth: '16px',
        },
        '&:after': {
            bottom: '-18px',
            left: '22px',
            borderBottomColor: theme.palette.background.paper,
            borderWidth: '15px',
        },
    });

export const StyledNovelsMenuBox = (theme: Theme) =>
    css({
        backgroundColor: theme.palette.mode === 'light' ? '#F3F3F3' : '#313131',
        borderTopRightRadius: '15px',
        borderBottomRightRadius: '15px',
    });

export const StyledMenuItem = css({
    display: 'flex',
    justifyContent: 'center',
});

export const StyledMenuText = css({
    marginTop: '24px',
    marginBottom: '24px',
});

export const StyledListItem = (theme: Theme) =>
    css({
        '&:hover': {
            backgroundColor: theme.palette.background.paper,
        },
    });
