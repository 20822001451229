// source: Protos/chapters.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var Protos_types_pb = require('../Protos/types_pb.js');
goog.object.extend(proto, Protos_types_pb);
goog.exportSymbol('proto.wuxiaworld.api.v2.ChapterGroupItem', null, global);
goog.exportSymbol('proto.wuxiaworld.api.v2.ChapterInfo', null, global);
goog.exportSymbol('proto.wuxiaworld.api.v2.ChapterItem', null, global);
goog.exportSymbol('proto.wuxiaworld.api.v2.ChapterKarma', null, global);
goog.exportSymbol('proto.wuxiaworld.api.v2.ChapterNovelInfo', null, global);
goog.exportSymbol('proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo', null, global);
goog.exportSymbol('proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.UserInfo', null, global);
goog.exportSymbol('proto.wuxiaworld.api.v2.GetChapterListRequest', null, global);
goog.exportSymbol('proto.wuxiaworld.api.v2.GetChapterListResponse', null, global);
goog.exportSymbol('proto.wuxiaworld.api.v2.GetChapterRequest', null, global);
goog.exportSymbol('proto.wuxiaworld.api.v2.GetChapterRequest.ByNovelAndChapterSlug', null, global);
goog.exportSymbol('proto.wuxiaworld.api.v2.GetChapterRequest.BypropertyCase', null, global);
goog.exportSymbol('proto.wuxiaworld.api.v2.GetChapterResponse', null, global);
goog.exportSymbol('proto.wuxiaworld.api.v2.GetChaptersBatch', null, global);
goog.exportSymbol('proto.wuxiaworld.api.v2.GetChaptersRequest', null, global);
goog.exportSymbol('proto.wuxiaworld.api.v2.RelatedChapterInfo', null, global);
goog.exportSymbol('proto.wuxiaworld.api.v2.RelatedChapterUserInfo', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wuxiaworld.api.v2.GetChapterListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wuxiaworld.api.v2.GetChapterListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wuxiaworld.api.v2.GetChapterListRequest.displayName = 'proto.wuxiaworld.api.v2.GetChapterListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wuxiaworld.api.v2.GetChapterRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.wuxiaworld.api.v2.GetChapterRequest.oneofGroups_);
};
goog.inherits(proto.wuxiaworld.api.v2.GetChapterRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wuxiaworld.api.v2.GetChapterRequest.displayName = 'proto.wuxiaworld.api.v2.GetChapterRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wuxiaworld.api.v2.GetChapterRequest.ByNovelAndChapterSlug = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wuxiaworld.api.v2.GetChapterRequest.ByNovelAndChapterSlug, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wuxiaworld.api.v2.GetChapterRequest.ByNovelAndChapterSlug.displayName = 'proto.wuxiaworld.api.v2.GetChapterRequest.ByNovelAndChapterSlug';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wuxiaworld.api.v2.GetChaptersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wuxiaworld.api.v2.GetChaptersRequest.repeatedFields_, null);
};
goog.inherits(proto.wuxiaworld.api.v2.GetChaptersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wuxiaworld.api.v2.GetChaptersRequest.displayName = 'proto.wuxiaworld.api.v2.GetChaptersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wuxiaworld.api.v2.GetChaptersBatch = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wuxiaworld.api.v2.GetChaptersBatch.repeatedFields_, null);
};
goog.inherits(proto.wuxiaworld.api.v2.GetChaptersBatch, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wuxiaworld.api.v2.GetChaptersBatch.displayName = 'proto.wuxiaworld.api.v2.GetChaptersBatch';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wuxiaworld.api.v2.GetChapterListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wuxiaworld.api.v2.GetChapterListResponse.repeatedFields_, null);
};
goog.inherits(proto.wuxiaworld.api.v2.GetChapterListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wuxiaworld.api.v2.GetChapterListResponse.displayName = 'proto.wuxiaworld.api.v2.GetChapterListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wuxiaworld.api.v2.GetChapterResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wuxiaworld.api.v2.GetChapterResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wuxiaworld.api.v2.GetChapterResponse.displayName = 'proto.wuxiaworld.api.v2.GetChapterResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wuxiaworld.api.v2.ChapterGroupItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wuxiaworld.api.v2.ChapterGroupItem.repeatedFields_, null);
};
goog.inherits(proto.wuxiaworld.api.v2.ChapterGroupItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wuxiaworld.api.v2.ChapterGroupItem.displayName = 'proto.wuxiaworld.api.v2.ChapterGroupItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wuxiaworld.api.v2.ChapterInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wuxiaworld.api.v2.ChapterInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wuxiaworld.api.v2.ChapterInfo.displayName = 'proto.wuxiaworld.api.v2.ChapterInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wuxiaworld.api.v2.RelatedChapterInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wuxiaworld.api.v2.RelatedChapterInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wuxiaworld.api.v2.RelatedChapterInfo.displayName = 'proto.wuxiaworld.api.v2.RelatedChapterInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wuxiaworld.api.v2.ChapterNovelInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wuxiaworld.api.v2.ChapterNovelInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wuxiaworld.api.v2.ChapterNovelInfo.displayName = 'proto.wuxiaworld.api.v2.ChapterNovelInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.displayName = 'proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.UserInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.UserInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.UserInfo.displayName = 'proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.UserInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wuxiaworld.api.v2.ChapterKarma = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wuxiaworld.api.v2.ChapterKarma, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wuxiaworld.api.v2.ChapterKarma.displayName = 'proto.wuxiaworld.api.v2.ChapterKarma';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wuxiaworld.api.v2.RelatedChapterUserInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wuxiaworld.api.v2.RelatedChapterUserInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wuxiaworld.api.v2.RelatedChapterUserInfo.displayName = 'proto.wuxiaworld.api.v2.RelatedChapterUserInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wuxiaworld.api.v2.ChapterItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wuxiaworld.api.v2.ChapterItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wuxiaworld.api.v2.ChapterItem.displayName = 'proto.wuxiaworld.api.v2.ChapterItem';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wuxiaworld.api.v2.GetChapterListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wuxiaworld.api.v2.GetChapterListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wuxiaworld.api.v2.GetChapterListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.GetChapterListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    novelid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wuxiaworld.api.v2.GetChapterListRequest}
 */
proto.wuxiaworld.api.v2.GetChapterListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wuxiaworld.api.v2.GetChapterListRequest;
  return proto.wuxiaworld.api.v2.GetChapterListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wuxiaworld.api.v2.GetChapterListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wuxiaworld.api.v2.GetChapterListRequest}
 */
proto.wuxiaworld.api.v2.GetChapterListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNovelid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wuxiaworld.api.v2.GetChapterListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wuxiaworld.api.v2.GetChapterListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wuxiaworld.api.v2.GetChapterListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.GetChapterListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNovelid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 novelId = 1;
 * @return {number}
 */
proto.wuxiaworld.api.v2.GetChapterListRequest.prototype.getNovelid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wuxiaworld.api.v2.GetChapterListRequest} returns this
 */
proto.wuxiaworld.api.v2.GetChapterListRequest.prototype.setNovelid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.wuxiaworld.api.v2.GetChapterRequest.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.wuxiaworld.api.v2.GetChapterRequest.BypropertyCase = {
  BYPROPERTY_NOT_SET: 0,
  CHAPTERID: 1,
  SLUGS: 2
};

/**
 * @return {proto.wuxiaworld.api.v2.GetChapterRequest.BypropertyCase}
 */
proto.wuxiaworld.api.v2.GetChapterRequest.prototype.getBypropertyCase = function() {
  return /** @type {proto.wuxiaworld.api.v2.GetChapterRequest.BypropertyCase} */(jspb.Message.computeOneofCase(this, proto.wuxiaworld.api.v2.GetChapterRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wuxiaworld.api.v2.GetChapterRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wuxiaworld.api.v2.GetChapterRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wuxiaworld.api.v2.GetChapterRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.GetChapterRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    chapterid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    slugs: (f = msg.getSlugs()) && proto.wuxiaworld.api.v2.GetChapterRequest.ByNovelAndChapterSlug.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wuxiaworld.api.v2.GetChapterRequest}
 */
proto.wuxiaworld.api.v2.GetChapterRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wuxiaworld.api.v2.GetChapterRequest;
  return proto.wuxiaworld.api.v2.GetChapterRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wuxiaworld.api.v2.GetChapterRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wuxiaworld.api.v2.GetChapterRequest}
 */
proto.wuxiaworld.api.v2.GetChapterRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setChapterid(value);
      break;
    case 2:
      var value = new proto.wuxiaworld.api.v2.GetChapterRequest.ByNovelAndChapterSlug;
      reader.readMessage(value,proto.wuxiaworld.api.v2.GetChapterRequest.ByNovelAndChapterSlug.deserializeBinaryFromReader);
      msg.setSlugs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wuxiaworld.api.v2.GetChapterRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wuxiaworld.api.v2.GetChapterRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wuxiaworld.api.v2.GetChapterRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.GetChapterRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSlugs();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.wuxiaworld.api.v2.GetChapterRequest.ByNovelAndChapterSlug.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wuxiaworld.api.v2.GetChapterRequest.ByNovelAndChapterSlug.prototype.toObject = function(opt_includeInstance) {
  return proto.wuxiaworld.api.v2.GetChapterRequest.ByNovelAndChapterSlug.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wuxiaworld.api.v2.GetChapterRequest.ByNovelAndChapterSlug} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.GetChapterRequest.ByNovelAndChapterSlug.toObject = function(includeInstance, msg) {
  var f, obj = {
    novelslug: jspb.Message.getFieldWithDefault(msg, 1, ""),
    chapterslug: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wuxiaworld.api.v2.GetChapterRequest.ByNovelAndChapterSlug}
 */
proto.wuxiaworld.api.v2.GetChapterRequest.ByNovelAndChapterSlug.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wuxiaworld.api.v2.GetChapterRequest.ByNovelAndChapterSlug;
  return proto.wuxiaworld.api.v2.GetChapterRequest.ByNovelAndChapterSlug.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wuxiaworld.api.v2.GetChapterRequest.ByNovelAndChapterSlug} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wuxiaworld.api.v2.GetChapterRequest.ByNovelAndChapterSlug}
 */
proto.wuxiaworld.api.v2.GetChapterRequest.ByNovelAndChapterSlug.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNovelslug(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setChapterslug(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wuxiaworld.api.v2.GetChapterRequest.ByNovelAndChapterSlug.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wuxiaworld.api.v2.GetChapterRequest.ByNovelAndChapterSlug.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wuxiaworld.api.v2.GetChapterRequest.ByNovelAndChapterSlug} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.GetChapterRequest.ByNovelAndChapterSlug.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNovelslug();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getChapterslug();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string novelSlug = 1;
 * @return {string}
 */
proto.wuxiaworld.api.v2.GetChapterRequest.ByNovelAndChapterSlug.prototype.getNovelslug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wuxiaworld.api.v2.GetChapterRequest.ByNovelAndChapterSlug} returns this
 */
proto.wuxiaworld.api.v2.GetChapterRequest.ByNovelAndChapterSlug.prototype.setNovelslug = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string chapterSlug = 2;
 * @return {string}
 */
proto.wuxiaworld.api.v2.GetChapterRequest.ByNovelAndChapterSlug.prototype.getChapterslug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wuxiaworld.api.v2.GetChapterRequest.ByNovelAndChapterSlug} returns this
 */
proto.wuxiaworld.api.v2.GetChapterRequest.ByNovelAndChapterSlug.prototype.setChapterslug = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 chapterId = 1;
 * @return {number}
 */
proto.wuxiaworld.api.v2.GetChapterRequest.prototype.getChapterid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wuxiaworld.api.v2.GetChapterRequest} returns this
 */
proto.wuxiaworld.api.v2.GetChapterRequest.prototype.setChapterid = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.wuxiaworld.api.v2.GetChapterRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.GetChapterRequest} returns this
 */
proto.wuxiaworld.api.v2.GetChapterRequest.prototype.clearChapterid = function() {
  return jspb.Message.setOneofField(this, 1, proto.wuxiaworld.api.v2.GetChapterRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.GetChapterRequest.prototype.hasChapterid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ByNovelAndChapterSlug slugs = 2;
 * @return {?proto.wuxiaworld.api.v2.GetChapterRequest.ByNovelAndChapterSlug}
 */
proto.wuxiaworld.api.v2.GetChapterRequest.prototype.getSlugs = function() {
  return /** @type{?proto.wuxiaworld.api.v2.GetChapterRequest.ByNovelAndChapterSlug} */ (
    jspb.Message.getWrapperField(this, proto.wuxiaworld.api.v2.GetChapterRequest.ByNovelAndChapterSlug, 2));
};


/**
 * @param {?proto.wuxiaworld.api.v2.GetChapterRequest.ByNovelAndChapterSlug|undefined} value
 * @return {!proto.wuxiaworld.api.v2.GetChapterRequest} returns this
*/
proto.wuxiaworld.api.v2.GetChapterRequest.prototype.setSlugs = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.wuxiaworld.api.v2.GetChapterRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.GetChapterRequest} returns this
 */
proto.wuxiaworld.api.v2.GetChapterRequest.prototype.clearSlugs = function() {
  return this.setSlugs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.GetChapterRequest.prototype.hasSlugs = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wuxiaworld.api.v2.GetChaptersRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wuxiaworld.api.v2.GetChaptersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wuxiaworld.api.v2.GetChaptersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wuxiaworld.api.v2.GetChaptersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.GetChaptersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    chapteridsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    batchsize: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wuxiaworld.api.v2.GetChaptersRequest}
 */
proto.wuxiaworld.api.v2.GetChaptersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wuxiaworld.api.v2.GetChaptersRequest;
  return proto.wuxiaworld.api.v2.GetChaptersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wuxiaworld.api.v2.GetChaptersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wuxiaworld.api.v2.GetChaptersRequest}
 */
proto.wuxiaworld.api.v2.GetChaptersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addChapterids(values[i]);
      }
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBatchsize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wuxiaworld.api.v2.GetChaptersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wuxiaworld.api.v2.GetChaptersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wuxiaworld.api.v2.GetChaptersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.GetChaptersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChapteridsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
  f = message.getBatchsize();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated int32 chapterIds = 1;
 * @return {!Array<number>}
 */
proto.wuxiaworld.api.v2.GetChaptersRequest.prototype.getChapteridsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.wuxiaworld.api.v2.GetChaptersRequest} returns this
 */
proto.wuxiaworld.api.v2.GetChaptersRequest.prototype.setChapteridsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.wuxiaworld.api.v2.GetChaptersRequest} returns this
 */
proto.wuxiaworld.api.v2.GetChaptersRequest.prototype.addChapterids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wuxiaworld.api.v2.GetChaptersRequest} returns this
 */
proto.wuxiaworld.api.v2.GetChaptersRequest.prototype.clearChapteridsList = function() {
  return this.setChapteridsList([]);
};


/**
 * optional int32 batchSize = 2;
 * @return {number}
 */
proto.wuxiaworld.api.v2.GetChaptersRequest.prototype.getBatchsize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wuxiaworld.api.v2.GetChaptersRequest} returns this
 */
proto.wuxiaworld.api.v2.GetChaptersRequest.prototype.setBatchsize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wuxiaworld.api.v2.GetChaptersBatch.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wuxiaworld.api.v2.GetChaptersBatch.prototype.toObject = function(opt_includeInstance) {
  return proto.wuxiaworld.api.v2.GetChaptersBatch.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wuxiaworld.api.v2.GetChaptersBatch} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.GetChaptersBatch.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.wuxiaworld.api.v2.ChapterItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wuxiaworld.api.v2.GetChaptersBatch}
 */
proto.wuxiaworld.api.v2.GetChaptersBatch.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wuxiaworld.api.v2.GetChaptersBatch;
  return proto.wuxiaworld.api.v2.GetChaptersBatch.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wuxiaworld.api.v2.GetChaptersBatch} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wuxiaworld.api.v2.GetChaptersBatch}
 */
proto.wuxiaworld.api.v2.GetChaptersBatch.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.wuxiaworld.api.v2.ChapterItem;
      reader.readMessage(value,proto.wuxiaworld.api.v2.ChapterItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wuxiaworld.api.v2.GetChaptersBatch.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wuxiaworld.api.v2.GetChaptersBatch.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wuxiaworld.api.v2.GetChaptersBatch} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.GetChaptersBatch.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.wuxiaworld.api.v2.ChapterItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ChapterItem items = 1;
 * @return {!Array<!proto.wuxiaworld.api.v2.ChapterItem>}
 */
proto.wuxiaworld.api.v2.GetChaptersBatch.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.wuxiaworld.api.v2.ChapterItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wuxiaworld.api.v2.ChapterItem, 1));
};


/**
 * @param {!Array<!proto.wuxiaworld.api.v2.ChapterItem>} value
 * @return {!proto.wuxiaworld.api.v2.GetChaptersBatch} returns this
*/
proto.wuxiaworld.api.v2.GetChaptersBatch.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.wuxiaworld.api.v2.ChapterItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wuxiaworld.api.v2.ChapterItem}
 */
proto.wuxiaworld.api.v2.GetChaptersBatch.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.wuxiaworld.api.v2.ChapterItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wuxiaworld.api.v2.GetChaptersBatch} returns this
 */
proto.wuxiaworld.api.v2.GetChaptersBatch.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wuxiaworld.api.v2.GetChapterListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wuxiaworld.api.v2.GetChapterListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wuxiaworld.api.v2.GetChapterListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wuxiaworld.api.v2.GetChapterListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.GetChapterListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.wuxiaworld.api.v2.ChapterGroupItem.toObject, includeInstance),
    novelinfo: (f = msg.getNovelinfo()) && proto.wuxiaworld.api.v2.ChapterNovelInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wuxiaworld.api.v2.GetChapterListResponse}
 */
proto.wuxiaworld.api.v2.GetChapterListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wuxiaworld.api.v2.GetChapterListResponse;
  return proto.wuxiaworld.api.v2.GetChapterListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wuxiaworld.api.v2.GetChapterListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wuxiaworld.api.v2.GetChapterListResponse}
 */
proto.wuxiaworld.api.v2.GetChapterListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.wuxiaworld.api.v2.ChapterGroupItem;
      reader.readMessage(value,proto.wuxiaworld.api.v2.ChapterGroupItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new proto.wuxiaworld.api.v2.ChapterNovelInfo;
      reader.readMessage(value,proto.wuxiaworld.api.v2.ChapterNovelInfo.deserializeBinaryFromReader);
      msg.setNovelinfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wuxiaworld.api.v2.GetChapterListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wuxiaworld.api.v2.GetChapterListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wuxiaworld.api.v2.GetChapterListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.GetChapterListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.wuxiaworld.api.v2.ChapterGroupItem.serializeBinaryToWriter
    );
  }
  f = message.getNovelinfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.wuxiaworld.api.v2.ChapterNovelInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ChapterGroupItem items = 1;
 * @return {!Array<!proto.wuxiaworld.api.v2.ChapterGroupItem>}
 */
proto.wuxiaworld.api.v2.GetChapterListResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.wuxiaworld.api.v2.ChapterGroupItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wuxiaworld.api.v2.ChapterGroupItem, 1));
};


/**
 * @param {!Array<!proto.wuxiaworld.api.v2.ChapterGroupItem>} value
 * @return {!proto.wuxiaworld.api.v2.GetChapterListResponse} returns this
*/
proto.wuxiaworld.api.v2.GetChapterListResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.wuxiaworld.api.v2.ChapterGroupItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wuxiaworld.api.v2.ChapterGroupItem}
 */
proto.wuxiaworld.api.v2.GetChapterListResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.wuxiaworld.api.v2.ChapterGroupItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wuxiaworld.api.v2.GetChapterListResponse} returns this
 */
proto.wuxiaworld.api.v2.GetChapterListResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional ChapterNovelInfo novelInfo = 2;
 * @return {?proto.wuxiaworld.api.v2.ChapterNovelInfo}
 */
proto.wuxiaworld.api.v2.GetChapterListResponse.prototype.getNovelinfo = function() {
  return /** @type{?proto.wuxiaworld.api.v2.ChapterNovelInfo} */ (
    jspb.Message.getWrapperField(this, proto.wuxiaworld.api.v2.ChapterNovelInfo, 2));
};


/**
 * @param {?proto.wuxiaworld.api.v2.ChapterNovelInfo|undefined} value
 * @return {!proto.wuxiaworld.api.v2.GetChapterListResponse} returns this
*/
proto.wuxiaworld.api.v2.GetChapterListResponse.prototype.setNovelinfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.GetChapterListResponse} returns this
 */
proto.wuxiaworld.api.v2.GetChapterListResponse.prototype.clearNovelinfo = function() {
  return this.setNovelinfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.GetChapterListResponse.prototype.hasNovelinfo = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wuxiaworld.api.v2.GetChapterResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wuxiaworld.api.v2.GetChapterResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wuxiaworld.api.v2.GetChapterResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.GetChapterResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    item: (f = msg.getItem()) && proto.wuxiaworld.api.v2.ChapterItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wuxiaworld.api.v2.GetChapterResponse}
 */
proto.wuxiaworld.api.v2.GetChapterResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wuxiaworld.api.v2.GetChapterResponse;
  return proto.wuxiaworld.api.v2.GetChapterResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wuxiaworld.api.v2.GetChapterResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wuxiaworld.api.v2.GetChapterResponse}
 */
proto.wuxiaworld.api.v2.GetChapterResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.wuxiaworld.api.v2.ChapterItem;
      reader.readMessage(value,proto.wuxiaworld.api.v2.ChapterItem.deserializeBinaryFromReader);
      msg.setItem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wuxiaworld.api.v2.GetChapterResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wuxiaworld.api.v2.GetChapterResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wuxiaworld.api.v2.GetChapterResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.GetChapterResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItem();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.wuxiaworld.api.v2.ChapterItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional ChapterItem item = 1;
 * @return {?proto.wuxiaworld.api.v2.ChapterItem}
 */
proto.wuxiaworld.api.v2.GetChapterResponse.prototype.getItem = function() {
  return /** @type{?proto.wuxiaworld.api.v2.ChapterItem} */ (
    jspb.Message.getWrapperField(this, proto.wuxiaworld.api.v2.ChapterItem, 1));
};


/**
 * @param {?proto.wuxiaworld.api.v2.ChapterItem|undefined} value
 * @return {!proto.wuxiaworld.api.v2.GetChapterResponse} returns this
*/
proto.wuxiaworld.api.v2.GetChapterResponse.prototype.setItem = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.GetChapterResponse} returns this
 */
proto.wuxiaworld.api.v2.GetChapterResponse.prototype.clearItem = function() {
  return this.setItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.GetChapterResponse.prototype.hasItem = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wuxiaworld.api.v2.ChapterGroupItem.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wuxiaworld.api.v2.ChapterGroupItem.prototype.toObject = function(opt_includeInstance) {
  return proto.wuxiaworld.api.v2.ChapterGroupItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wuxiaworld.api.v2.ChapterGroupItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.ChapterGroupItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    order: jspb.Message.getFieldWithDefault(msg, 3, 0),
    fromchapternumber: (f = msg.getFromchapternumber()) && Protos_types_pb.DecimalValue.toObject(includeInstance, f),
    tochapternumber: (f = msg.getTochapternumber()) && Protos_types_pb.DecimalValue.toObject(includeInstance, f),
    chapterlistList: jspb.Message.toObjectList(msg.getChapterlistList(),
    proto.wuxiaworld.api.v2.ChapterItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wuxiaworld.api.v2.ChapterGroupItem}
 */
proto.wuxiaworld.api.v2.ChapterGroupItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wuxiaworld.api.v2.ChapterGroupItem;
  return proto.wuxiaworld.api.v2.ChapterGroupItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wuxiaworld.api.v2.ChapterGroupItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wuxiaworld.api.v2.ChapterGroupItem}
 */
proto.wuxiaworld.api.v2.ChapterGroupItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 4:
      var value = new Protos_types_pb.DecimalValue;
      reader.readMessage(value,Protos_types_pb.DecimalValue.deserializeBinaryFromReader);
      msg.setFromchapternumber(value);
      break;
    case 5:
      var value = new Protos_types_pb.DecimalValue;
      reader.readMessage(value,Protos_types_pb.DecimalValue.deserializeBinaryFromReader);
      msg.setTochapternumber(value);
      break;
    case 6:
      var value = new proto.wuxiaworld.api.v2.ChapterItem;
      reader.readMessage(value,proto.wuxiaworld.api.v2.ChapterItem.deserializeBinaryFromReader);
      msg.addChapterlist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wuxiaworld.api.v2.ChapterGroupItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wuxiaworld.api.v2.ChapterGroupItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wuxiaworld.api.v2.ChapterGroupItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.ChapterGroupItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getFromchapternumber();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Protos_types_pb.DecimalValue.serializeBinaryToWriter
    );
  }
  f = message.getTochapternumber();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Protos_types_pb.DecimalValue.serializeBinaryToWriter
    );
  }
  f = message.getChapterlistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.wuxiaworld.api.v2.ChapterItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.wuxiaworld.api.v2.ChapterGroupItem.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wuxiaworld.api.v2.ChapterGroupItem} returns this
 */
proto.wuxiaworld.api.v2.ChapterGroupItem.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.wuxiaworld.api.v2.ChapterGroupItem.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wuxiaworld.api.v2.ChapterGroupItem} returns this
 */
proto.wuxiaworld.api.v2.ChapterGroupItem.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 order = 3;
 * @return {number}
 */
proto.wuxiaworld.api.v2.ChapterGroupItem.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.wuxiaworld.api.v2.ChapterGroupItem} returns this
 */
proto.wuxiaworld.api.v2.ChapterGroupItem.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional customTypes.DecimalValue fromChapterNumber = 4;
 * @return {?proto.customTypes.DecimalValue}
 */
proto.wuxiaworld.api.v2.ChapterGroupItem.prototype.getFromchapternumber = function() {
  return /** @type{?proto.customTypes.DecimalValue} */ (
    jspb.Message.getWrapperField(this, Protos_types_pb.DecimalValue, 4));
};


/**
 * @param {?proto.customTypes.DecimalValue|undefined} value
 * @return {!proto.wuxiaworld.api.v2.ChapterGroupItem} returns this
*/
proto.wuxiaworld.api.v2.ChapterGroupItem.prototype.setFromchapternumber = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.ChapterGroupItem} returns this
 */
proto.wuxiaworld.api.v2.ChapterGroupItem.prototype.clearFromchapternumber = function() {
  return this.setFromchapternumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.ChapterGroupItem.prototype.hasFromchapternumber = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional customTypes.DecimalValue toChapterNumber = 5;
 * @return {?proto.customTypes.DecimalValue}
 */
proto.wuxiaworld.api.v2.ChapterGroupItem.prototype.getTochapternumber = function() {
  return /** @type{?proto.customTypes.DecimalValue} */ (
    jspb.Message.getWrapperField(this, Protos_types_pb.DecimalValue, 5));
};


/**
 * @param {?proto.customTypes.DecimalValue|undefined} value
 * @return {!proto.wuxiaworld.api.v2.ChapterGroupItem} returns this
*/
proto.wuxiaworld.api.v2.ChapterGroupItem.prototype.setTochapternumber = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.ChapterGroupItem} returns this
 */
proto.wuxiaworld.api.v2.ChapterGroupItem.prototype.clearTochapternumber = function() {
  return this.setTochapternumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.ChapterGroupItem.prototype.hasTochapternumber = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated ChapterItem chapterList = 6;
 * @return {!Array<!proto.wuxiaworld.api.v2.ChapterItem>}
 */
proto.wuxiaworld.api.v2.ChapterGroupItem.prototype.getChapterlistList = function() {
  return /** @type{!Array<!proto.wuxiaworld.api.v2.ChapterItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wuxiaworld.api.v2.ChapterItem, 6));
};


/**
 * @param {!Array<!proto.wuxiaworld.api.v2.ChapterItem>} value
 * @return {!proto.wuxiaworld.api.v2.ChapterGroupItem} returns this
*/
proto.wuxiaworld.api.v2.ChapterGroupItem.prototype.setChapterlistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.wuxiaworld.api.v2.ChapterItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wuxiaworld.api.v2.ChapterItem}
 */
proto.wuxiaworld.api.v2.ChapterGroupItem.prototype.addChapterlist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.wuxiaworld.api.v2.ChapterItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wuxiaworld.api.v2.ChapterGroupItem} returns this
 */
proto.wuxiaworld.api.v2.ChapterGroupItem.prototype.clearChapterlistList = function() {
  return this.setChapterlistList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wuxiaworld.api.v2.ChapterInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.wuxiaworld.api.v2.ChapterInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wuxiaworld.api.v2.ChapterInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.ChapterInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    entityid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    slug: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wuxiaworld.api.v2.ChapterInfo}
 */
proto.wuxiaworld.api.v2.ChapterInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wuxiaworld.api.v2.ChapterInfo;
  return proto.wuxiaworld.api.v2.ChapterInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wuxiaworld.api.v2.ChapterInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wuxiaworld.api.v2.ChapterInfo}
 */
proto.wuxiaworld.api.v2.ChapterInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEntityid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlug(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wuxiaworld.api.v2.ChapterInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wuxiaworld.api.v2.ChapterInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wuxiaworld.api.v2.ChapterInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.ChapterInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntityid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSlug();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 entityId = 1;
 * @return {number}
 */
proto.wuxiaworld.api.v2.ChapterInfo.prototype.getEntityid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wuxiaworld.api.v2.ChapterInfo} returns this
 */
proto.wuxiaworld.api.v2.ChapterInfo.prototype.setEntityid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.wuxiaworld.api.v2.ChapterInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wuxiaworld.api.v2.ChapterInfo} returns this
 */
proto.wuxiaworld.api.v2.ChapterInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string slug = 3;
 * @return {string}
 */
proto.wuxiaworld.api.v2.ChapterInfo.prototype.getSlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wuxiaworld.api.v2.ChapterInfo} returns this
 */
proto.wuxiaworld.api.v2.ChapterInfo.prototype.setSlug = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wuxiaworld.api.v2.RelatedChapterInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.wuxiaworld.api.v2.RelatedChapterInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wuxiaworld.api.v2.RelatedChapterInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.RelatedChapterInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    previouschapter: (f = msg.getPreviouschapter()) && proto.wuxiaworld.api.v2.ChapterInfo.toObject(includeInstance, f),
    nextchapter: (f = msg.getNextchapter()) && proto.wuxiaworld.api.v2.ChapterInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wuxiaworld.api.v2.RelatedChapterInfo}
 */
proto.wuxiaworld.api.v2.RelatedChapterInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wuxiaworld.api.v2.RelatedChapterInfo;
  return proto.wuxiaworld.api.v2.RelatedChapterInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wuxiaworld.api.v2.RelatedChapterInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wuxiaworld.api.v2.RelatedChapterInfo}
 */
proto.wuxiaworld.api.v2.RelatedChapterInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.wuxiaworld.api.v2.ChapterInfo;
      reader.readMessage(value,proto.wuxiaworld.api.v2.ChapterInfo.deserializeBinaryFromReader);
      msg.setPreviouschapter(value);
      break;
    case 2:
      var value = new proto.wuxiaworld.api.v2.ChapterInfo;
      reader.readMessage(value,proto.wuxiaworld.api.v2.ChapterInfo.deserializeBinaryFromReader);
      msg.setNextchapter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wuxiaworld.api.v2.RelatedChapterInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wuxiaworld.api.v2.RelatedChapterInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wuxiaworld.api.v2.RelatedChapterInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.RelatedChapterInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPreviouschapter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.wuxiaworld.api.v2.ChapterInfo.serializeBinaryToWriter
    );
  }
  f = message.getNextchapter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.wuxiaworld.api.v2.ChapterInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional ChapterInfo previousChapter = 1;
 * @return {?proto.wuxiaworld.api.v2.ChapterInfo}
 */
proto.wuxiaworld.api.v2.RelatedChapterInfo.prototype.getPreviouschapter = function() {
  return /** @type{?proto.wuxiaworld.api.v2.ChapterInfo} */ (
    jspb.Message.getWrapperField(this, proto.wuxiaworld.api.v2.ChapterInfo, 1));
};


/**
 * @param {?proto.wuxiaworld.api.v2.ChapterInfo|undefined} value
 * @return {!proto.wuxiaworld.api.v2.RelatedChapterInfo} returns this
*/
proto.wuxiaworld.api.v2.RelatedChapterInfo.prototype.setPreviouschapter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.RelatedChapterInfo} returns this
 */
proto.wuxiaworld.api.v2.RelatedChapterInfo.prototype.clearPreviouschapter = function() {
  return this.setPreviouschapter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.RelatedChapterInfo.prototype.hasPreviouschapter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ChapterInfo nextChapter = 2;
 * @return {?proto.wuxiaworld.api.v2.ChapterInfo}
 */
proto.wuxiaworld.api.v2.RelatedChapterInfo.prototype.getNextchapter = function() {
  return /** @type{?proto.wuxiaworld.api.v2.ChapterInfo} */ (
    jspb.Message.getWrapperField(this, proto.wuxiaworld.api.v2.ChapterInfo, 2));
};


/**
 * @param {?proto.wuxiaworld.api.v2.ChapterInfo|undefined} value
 * @return {!proto.wuxiaworld.api.v2.RelatedChapterInfo} returns this
*/
proto.wuxiaworld.api.v2.RelatedChapterInfo.prototype.setNextchapter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.RelatedChapterInfo} returns this
 */
proto.wuxiaworld.api.v2.RelatedChapterInfo.prototype.clearNextchapter = function() {
  return this.setNextchapter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.RelatedChapterInfo.prototype.hasNextchapter = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wuxiaworld.api.v2.ChapterNovelInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.wuxiaworld.api.v2.ChapterNovelInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wuxiaworld.api.v2.ChapterNovelInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.ChapterNovelInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    slug: jspb.Message.getFieldWithDefault(msg, 3, ""),
    karmaactive: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    issneakpeek: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    translatorinfo: (f = msg.getTranslatorinfo()) && proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wuxiaworld.api.v2.ChapterNovelInfo}
 */
proto.wuxiaworld.api.v2.ChapterNovelInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wuxiaworld.api.v2.ChapterNovelInfo;
  return proto.wuxiaworld.api.v2.ChapterNovelInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wuxiaworld.api.v2.ChapterNovelInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wuxiaworld.api.v2.ChapterNovelInfo}
 */
proto.wuxiaworld.api.v2.ChapterNovelInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlug(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setKarmaactive(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIssneakpeek(value);
      break;
    case 6:
      var value = new proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo;
      reader.readMessage(value,proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.deserializeBinaryFromReader);
      msg.setTranslatorinfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wuxiaworld.api.v2.ChapterNovelInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wuxiaworld.api.v2.ChapterNovelInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wuxiaworld.api.v2.ChapterNovelInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.ChapterNovelInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSlug();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getKarmaactive();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getIssneakpeek();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getTranslatorinfo();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.wuxiaworld.api.v2.ChapterNovelInfo.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wuxiaworld.api.v2.ChapterNovelInfo} returns this
 */
proto.wuxiaworld.api.v2.ChapterNovelInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.wuxiaworld.api.v2.ChapterNovelInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wuxiaworld.api.v2.ChapterNovelInfo} returns this
 */
proto.wuxiaworld.api.v2.ChapterNovelInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string slug = 3;
 * @return {string}
 */
proto.wuxiaworld.api.v2.ChapterNovelInfo.prototype.getSlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wuxiaworld.api.v2.ChapterNovelInfo} returns this
 */
proto.wuxiaworld.api.v2.ChapterNovelInfo.prototype.setSlug = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool karmaActive = 4;
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.ChapterNovelInfo.prototype.getKarmaactive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wuxiaworld.api.v2.ChapterNovelInfo} returns this
 */
proto.wuxiaworld.api.v2.ChapterNovelInfo.prototype.setKarmaactive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool isSneakPeek = 5;
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.ChapterNovelInfo.prototype.getIssneakpeek = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wuxiaworld.api.v2.ChapterNovelInfo} returns this
 */
proto.wuxiaworld.api.v2.ChapterNovelInfo.prototype.setIssneakpeek = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional ChapterNovelTranslatorInfo translatorInfo = 6;
 * @return {?proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo}
 */
proto.wuxiaworld.api.v2.ChapterNovelInfo.prototype.getTranslatorinfo = function() {
  return /** @type{?proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo} */ (
    jspb.Message.getWrapperField(this, proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo, 6));
};


/**
 * @param {?proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo|undefined} value
 * @return {!proto.wuxiaworld.api.v2.ChapterNovelInfo} returns this
*/
proto.wuxiaworld.api.v2.ChapterNovelInfo.prototype.setTranslatorinfo = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.ChapterNovelInfo} returns this
 */
proto.wuxiaworld.api.v2.ChapterNovelInfo.prototype.clearTranslatorinfo = function() {
  return this.setTranslatorinfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.ChapterNovelInfo.prototype.hasTranslatorinfo = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    userinfo: (f = msg.getUserinfo()) && proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.UserInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo}
 */
proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo;
  return proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo}
 */
proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.UserInfo;
      reader.readMessage(value,proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.UserInfo.deserializeBinaryFromReader);
      msg.setUserinfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserinfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.UserInfo.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.UserInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.UserInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.UserInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.UserInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    username: jspb.Message.getFieldWithDefault(msg, 2, ""),
    avatarurl: (f = msg.getAvatarurl()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    joindate: (f = msg.getJoindate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.UserInfo}
 */
proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.UserInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.UserInfo;
  return proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.UserInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.UserInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.UserInfo}
 */
proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.UserInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAvatarurl(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setJoindate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.UserInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.UserInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.UserInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.UserInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAvatarurl();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getJoindate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.UserInfo.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.UserInfo} returns this
 */
proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.UserInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string userName = 2;
 * @return {string}
 */
proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.UserInfo.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.UserInfo} returns this
 */
proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.UserInfo.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.StringValue avatarUrl = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.UserInfo.prototype.getAvatarurl = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.UserInfo} returns this
*/
proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.UserInfo.prototype.setAvatarurl = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.UserInfo} returns this
 */
proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.UserInfo.prototype.clearAvatarurl = function() {
  return this.setAvatarurl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.UserInfo.prototype.hasAvatarurl = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp joinDate = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.UserInfo.prototype.getJoindate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.UserInfo} returns this
*/
proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.UserInfo.prototype.setJoindate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.UserInfo} returns this
 */
proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.UserInfo.prototype.clearJoindate = function() {
  return this.setJoindate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.UserInfo.prototype.hasJoindate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional UserInfo userInfo = 1;
 * @return {?proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.UserInfo}
 */
proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.prototype.getUserinfo = function() {
  return /** @type{?proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.UserInfo} */ (
    jspb.Message.getWrapperField(this, proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.UserInfo, 1));
};


/**
 * @param {?proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.UserInfo|undefined} value
 * @return {!proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo} returns this
*/
proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.prototype.setUserinfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo} returns this
 */
proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.prototype.clearUserinfo = function() {
  return this.setUserinfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.ChapterNovelTranslatorInfo.prototype.hasUserinfo = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wuxiaworld.api.v2.ChapterKarma.prototype.toObject = function(opt_includeInstance) {
  return proto.wuxiaworld.api.v2.ChapterKarma.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wuxiaworld.api.v2.ChapterKarma} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.ChapterKarma.toObject = function(includeInstance, msg) {
  var f, obj = {
    karmaprice: (f = msg.getKarmaprice()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    iskarmarequired: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wuxiaworld.api.v2.ChapterKarma}
 */
proto.wuxiaworld.api.v2.ChapterKarma.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wuxiaworld.api.v2.ChapterKarma;
  return proto.wuxiaworld.api.v2.ChapterKarma.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wuxiaworld.api.v2.ChapterKarma} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wuxiaworld.api.v2.ChapterKarma}
 */
proto.wuxiaworld.api.v2.ChapterKarma.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setKarmaprice(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIskarmarequired(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wuxiaworld.api.v2.ChapterKarma.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wuxiaworld.api.v2.ChapterKarma.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wuxiaworld.api.v2.ChapterKarma} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.ChapterKarma.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKarmaprice();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getIskarmarequired();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional google.protobuf.Int32Value karmaPrice = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.wuxiaworld.api.v2.ChapterKarma.prototype.getKarmaprice = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.wuxiaworld.api.v2.ChapterKarma} returns this
*/
proto.wuxiaworld.api.v2.ChapterKarma.prototype.setKarmaprice = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.ChapterKarma} returns this
 */
proto.wuxiaworld.api.v2.ChapterKarma.prototype.clearKarmaprice = function() {
  return this.setKarmaprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.ChapterKarma.prototype.hasKarmaprice = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool isKarmaRequired = 2;
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.ChapterKarma.prototype.getIskarmarequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wuxiaworld.api.v2.ChapterKarma} returns this
 */
proto.wuxiaworld.api.v2.ChapterKarma.prototype.setIskarmarequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wuxiaworld.api.v2.RelatedChapterUserInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.wuxiaworld.api.v2.RelatedChapterUserInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wuxiaworld.api.v2.RelatedChapterUserInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.RelatedChapterUserInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    ischapterunlocked: (f = msg.getIschapterunlocked()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wuxiaworld.api.v2.RelatedChapterUserInfo}
 */
proto.wuxiaworld.api.v2.RelatedChapterUserInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wuxiaworld.api.v2.RelatedChapterUserInfo;
  return proto.wuxiaworld.api.v2.RelatedChapterUserInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wuxiaworld.api.v2.RelatedChapterUserInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wuxiaworld.api.v2.RelatedChapterUserInfo}
 */
proto.wuxiaworld.api.v2.RelatedChapterUserInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIschapterunlocked(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wuxiaworld.api.v2.RelatedChapterUserInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wuxiaworld.api.v2.RelatedChapterUserInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wuxiaworld.api.v2.RelatedChapterUserInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.RelatedChapterUserInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIschapterunlocked();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.BoolValue isChapterUnlocked = 1;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.wuxiaworld.api.v2.RelatedChapterUserInfo.prototype.getIschapterunlocked = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 1));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.wuxiaworld.api.v2.RelatedChapterUserInfo} returns this
*/
proto.wuxiaworld.api.v2.RelatedChapterUserInfo.prototype.setIschapterunlocked = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.RelatedChapterUserInfo} returns this
 */
proto.wuxiaworld.api.v2.RelatedChapterUserInfo.prototype.clearIschapterunlocked = function() {
  return this.setIschapterunlocked(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.RelatedChapterUserInfo.prototype.hasIschapterunlocked = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wuxiaworld.api.v2.ChapterItem.prototype.toObject = function(opt_includeInstance) {
  return proto.wuxiaworld.api.v2.ChapterItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wuxiaworld.api.v2.ChapterItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.ChapterItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    entityid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    slug: jspb.Message.getFieldWithDefault(msg, 3, ""),
    number: (f = msg.getNumber()) && Protos_types_pb.DecimalValue.toObject(includeInstance, f),
    content: (f = msg.getContent()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    novelid: jspb.Message.getFieldWithDefault(msg, 6, 0),
    visible: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    isteaser: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    advancechapter: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    advancechapternumber: (f = msg.getAdvancechapternumber()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    whentopublish: (f = msg.getWhentopublish()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    spoilertitle: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    allowcomments: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    relatedchapterinfo: (f = msg.getRelatedchapterinfo()) && proto.wuxiaworld.api.v2.RelatedChapterInfo.toObject(includeInstance, f),
    karmainfo: (f = msg.getKarmainfo()) && proto.wuxiaworld.api.v2.ChapterKarma.toObject(includeInstance, f),
    novelinfo: (f = msg.getNovelinfo()) && proto.wuxiaworld.api.v2.ChapterNovelInfo.toObject(includeInstance, f),
    relateduserinfo: (f = msg.getRelateduserinfo()) && proto.wuxiaworld.api.v2.RelatedChapterUserInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wuxiaworld.api.v2.ChapterItem}
 */
proto.wuxiaworld.api.v2.ChapterItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wuxiaworld.api.v2.ChapterItem;
  return proto.wuxiaworld.api.v2.ChapterItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wuxiaworld.api.v2.ChapterItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wuxiaworld.api.v2.ChapterItem}
 */
proto.wuxiaworld.api.v2.ChapterItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEntityid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlug(value);
      break;
    case 4:
      var value = new Protos_types_pb.DecimalValue;
      reader.readMessage(value,Protos_types_pb.DecimalValue.deserializeBinaryFromReader);
      msg.setNumber(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setContent(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNovelid(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVisible(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsteaser(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAdvancechapter(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setAdvancechapternumber(value);
      break;
    case 11:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setWhentopublish(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSpoilertitle(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowcomments(value);
      break;
    case 14:
      var value = new proto.wuxiaworld.api.v2.RelatedChapterInfo;
      reader.readMessage(value,proto.wuxiaworld.api.v2.RelatedChapterInfo.deserializeBinaryFromReader);
      msg.setRelatedchapterinfo(value);
      break;
    case 15:
      var value = new proto.wuxiaworld.api.v2.ChapterKarma;
      reader.readMessage(value,proto.wuxiaworld.api.v2.ChapterKarma.deserializeBinaryFromReader);
      msg.setKarmainfo(value);
      break;
    case 16:
      var value = new proto.wuxiaworld.api.v2.ChapterNovelInfo;
      reader.readMessage(value,proto.wuxiaworld.api.v2.ChapterNovelInfo.deserializeBinaryFromReader);
      msg.setNovelinfo(value);
      break;
    case 17:
      var value = new proto.wuxiaworld.api.v2.RelatedChapterUserInfo;
      reader.readMessage(value,proto.wuxiaworld.api.v2.RelatedChapterUserInfo.deserializeBinaryFromReader);
      msg.setRelateduserinfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wuxiaworld.api.v2.ChapterItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wuxiaworld.api.v2.ChapterItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wuxiaworld.api.v2.ChapterItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.ChapterItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntityid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSlug();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getNumber();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Protos_types_pb.DecimalValue.serializeBinaryToWriter
    );
  }
  f = message.getContent();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getNovelid();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getVisible();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getIsteaser();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getAdvancechapter();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getAdvancechapternumber();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getWhentopublish();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSpoilertitle();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getAllowcomments();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getRelatedchapterinfo();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.wuxiaworld.api.v2.RelatedChapterInfo.serializeBinaryToWriter
    );
  }
  f = message.getKarmainfo();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.wuxiaworld.api.v2.ChapterKarma.serializeBinaryToWriter
    );
  }
  f = message.getNovelinfo();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.wuxiaworld.api.v2.ChapterNovelInfo.serializeBinaryToWriter
    );
  }
  f = message.getRelateduserinfo();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.wuxiaworld.api.v2.RelatedChapterUserInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 entityId = 1;
 * @return {number}
 */
proto.wuxiaworld.api.v2.ChapterItem.prototype.getEntityid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wuxiaworld.api.v2.ChapterItem} returns this
 */
proto.wuxiaworld.api.v2.ChapterItem.prototype.setEntityid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.wuxiaworld.api.v2.ChapterItem.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wuxiaworld.api.v2.ChapterItem} returns this
 */
proto.wuxiaworld.api.v2.ChapterItem.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string slug = 3;
 * @return {string}
 */
proto.wuxiaworld.api.v2.ChapterItem.prototype.getSlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wuxiaworld.api.v2.ChapterItem} returns this
 */
proto.wuxiaworld.api.v2.ChapterItem.prototype.setSlug = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional customTypes.DecimalValue number = 4;
 * @return {?proto.customTypes.DecimalValue}
 */
proto.wuxiaworld.api.v2.ChapterItem.prototype.getNumber = function() {
  return /** @type{?proto.customTypes.DecimalValue} */ (
    jspb.Message.getWrapperField(this, Protos_types_pb.DecimalValue, 4));
};


/**
 * @param {?proto.customTypes.DecimalValue|undefined} value
 * @return {!proto.wuxiaworld.api.v2.ChapterItem} returns this
*/
proto.wuxiaworld.api.v2.ChapterItem.prototype.setNumber = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.ChapterItem} returns this
 */
proto.wuxiaworld.api.v2.ChapterItem.prototype.clearNumber = function() {
  return this.setNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.ChapterItem.prototype.hasNumber = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue content = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.wuxiaworld.api.v2.ChapterItem.prototype.getContent = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.wuxiaworld.api.v2.ChapterItem} returns this
*/
proto.wuxiaworld.api.v2.ChapterItem.prototype.setContent = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.ChapterItem} returns this
 */
proto.wuxiaworld.api.v2.ChapterItem.prototype.clearContent = function() {
  return this.setContent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.ChapterItem.prototype.hasContent = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int32 novelId = 6;
 * @return {number}
 */
proto.wuxiaworld.api.v2.ChapterItem.prototype.getNovelid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.wuxiaworld.api.v2.ChapterItem} returns this
 */
proto.wuxiaworld.api.v2.ChapterItem.prototype.setNovelid = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional bool visible = 7;
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.ChapterItem.prototype.getVisible = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wuxiaworld.api.v2.ChapterItem} returns this
 */
proto.wuxiaworld.api.v2.ChapterItem.prototype.setVisible = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool isTeaser = 8;
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.ChapterItem.prototype.getIsteaser = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wuxiaworld.api.v2.ChapterItem} returns this
 */
proto.wuxiaworld.api.v2.ChapterItem.prototype.setIsteaser = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool advanceChapter = 9;
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.ChapterItem.prototype.getAdvancechapter = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wuxiaworld.api.v2.ChapterItem} returns this
 */
proto.wuxiaworld.api.v2.ChapterItem.prototype.setAdvancechapter = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional google.protobuf.Int32Value advanceChapterNumber = 10;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.wuxiaworld.api.v2.ChapterItem.prototype.getAdvancechapternumber = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 10));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.wuxiaworld.api.v2.ChapterItem} returns this
*/
proto.wuxiaworld.api.v2.ChapterItem.prototype.setAdvancechapternumber = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.ChapterItem} returns this
 */
proto.wuxiaworld.api.v2.ChapterItem.prototype.clearAdvancechapternumber = function() {
  return this.setAdvancechapternumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.ChapterItem.prototype.hasAdvancechapternumber = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.Timestamp whenToPublish = 11;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wuxiaworld.api.v2.ChapterItem.prototype.getWhentopublish = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 11));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wuxiaworld.api.v2.ChapterItem} returns this
*/
proto.wuxiaworld.api.v2.ChapterItem.prototype.setWhentopublish = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.ChapterItem} returns this
 */
proto.wuxiaworld.api.v2.ChapterItem.prototype.clearWhentopublish = function() {
  return this.setWhentopublish(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.ChapterItem.prototype.hasWhentopublish = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional bool spoilerTitle = 12;
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.ChapterItem.prototype.getSpoilertitle = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wuxiaworld.api.v2.ChapterItem} returns this
 */
proto.wuxiaworld.api.v2.ChapterItem.prototype.setSpoilertitle = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool allowComments = 13;
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.ChapterItem.prototype.getAllowcomments = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wuxiaworld.api.v2.ChapterItem} returns this
 */
proto.wuxiaworld.api.v2.ChapterItem.prototype.setAllowcomments = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional RelatedChapterInfo relatedChapterInfo = 14;
 * @return {?proto.wuxiaworld.api.v2.RelatedChapterInfo}
 */
proto.wuxiaworld.api.v2.ChapterItem.prototype.getRelatedchapterinfo = function() {
  return /** @type{?proto.wuxiaworld.api.v2.RelatedChapterInfo} */ (
    jspb.Message.getWrapperField(this, proto.wuxiaworld.api.v2.RelatedChapterInfo, 14));
};


/**
 * @param {?proto.wuxiaworld.api.v2.RelatedChapterInfo|undefined} value
 * @return {!proto.wuxiaworld.api.v2.ChapterItem} returns this
*/
proto.wuxiaworld.api.v2.ChapterItem.prototype.setRelatedchapterinfo = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.ChapterItem} returns this
 */
proto.wuxiaworld.api.v2.ChapterItem.prototype.clearRelatedchapterinfo = function() {
  return this.setRelatedchapterinfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.ChapterItem.prototype.hasRelatedchapterinfo = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional ChapterKarma karmaInfo = 15;
 * @return {?proto.wuxiaworld.api.v2.ChapterKarma}
 */
proto.wuxiaworld.api.v2.ChapterItem.prototype.getKarmainfo = function() {
  return /** @type{?proto.wuxiaworld.api.v2.ChapterKarma} */ (
    jspb.Message.getWrapperField(this, proto.wuxiaworld.api.v2.ChapterKarma, 15));
};


/**
 * @param {?proto.wuxiaworld.api.v2.ChapterKarma|undefined} value
 * @return {!proto.wuxiaworld.api.v2.ChapterItem} returns this
*/
proto.wuxiaworld.api.v2.ChapterItem.prototype.setKarmainfo = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.ChapterItem} returns this
 */
proto.wuxiaworld.api.v2.ChapterItem.prototype.clearKarmainfo = function() {
  return this.setKarmainfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.ChapterItem.prototype.hasKarmainfo = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional ChapterNovelInfo novelInfo = 16;
 * @return {?proto.wuxiaworld.api.v2.ChapterNovelInfo}
 */
proto.wuxiaworld.api.v2.ChapterItem.prototype.getNovelinfo = function() {
  return /** @type{?proto.wuxiaworld.api.v2.ChapterNovelInfo} */ (
    jspb.Message.getWrapperField(this, proto.wuxiaworld.api.v2.ChapterNovelInfo, 16));
};


/**
 * @param {?proto.wuxiaworld.api.v2.ChapterNovelInfo|undefined} value
 * @return {!proto.wuxiaworld.api.v2.ChapterItem} returns this
*/
proto.wuxiaworld.api.v2.ChapterItem.prototype.setNovelinfo = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.ChapterItem} returns this
 */
proto.wuxiaworld.api.v2.ChapterItem.prototype.clearNovelinfo = function() {
  return this.setNovelinfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.ChapterItem.prototype.hasNovelinfo = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional RelatedChapterUserInfo relatedUserInfo = 17;
 * @return {?proto.wuxiaworld.api.v2.RelatedChapterUserInfo}
 */
proto.wuxiaworld.api.v2.ChapterItem.prototype.getRelateduserinfo = function() {
  return /** @type{?proto.wuxiaworld.api.v2.RelatedChapterUserInfo} */ (
    jspb.Message.getWrapperField(this, proto.wuxiaworld.api.v2.RelatedChapterUserInfo, 17));
};


/**
 * @param {?proto.wuxiaworld.api.v2.RelatedChapterUserInfo|undefined} value
 * @return {!proto.wuxiaworld.api.v2.ChapterItem} returns this
*/
proto.wuxiaworld.api.v2.ChapterItem.prototype.setRelateduserinfo = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.ChapterItem} returns this
 */
proto.wuxiaworld.api.v2.ChapterItem.prototype.clearRelateduserinfo = function() {
  return this.setRelateduserinfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.ChapterItem.prototype.hasRelateduserinfo = function() {
  return jspb.Message.getField(this, 17) != null;
};


goog.object.extend(exports, proto.wuxiaworld.api.v2);
