import { css, Theme } from '@emotion/react';
import * as React from 'react';
import { ReactComponent as Logo } from '../assets/Wu-Black.svg';

//#region : Styles
const StyledLogo = (theme: Theme) =>
    css({
        width: '32px',
        height: '32px',
        fill: theme.palette.text.primary,
    });
//#endregion : Styles

//#region : Main component
/**
 * Custom helper component.
 *
 * Use it when you want to display the site's logo, size small.
 */
export default function SiteLogo() {
    return <Logo title="Wuxiaworld" viewBox="50 0 800 800" css={StyledLogo} />;
}
//#endregion : Main component
