import { Theme, ThemeProvider } from '@emotion/react';
import { Container, CssBaseline } from '@material-ui/core';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { SignalWifiOff } from '@material-ui/icons';
import { GlobalStyles } from '@material-ui/styled-engine';
import * as React from 'react';
import { ReactNode, useEffect, useMemo, useReducer, useState } from 'react';
import { Helmet } from 'react-helmet';
import { onlineManager } from 'react-query';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../libs/auth';
import { isBrowser, useColorScheme, useEnhancedEffect } from '../utils/utils';
import { ErrorBoundary } from './error';
import Footer from './footer';
import Header from './header';
import ModalProvider from './modal';
import { StyledAppMainContainer, StyledAppMainContainerWrapper } from './shared/app-main-container.styles';
import { StyledNetworkErrorContainer } from './shared/generic.styles';
import { MaterialDarkTheme, MaterialLightTheme } from './theme';

//#region : Helpers

/**
 * Custom. Used by theme switch.
 */
type ThemeAction = {
    type: 'toggle-theme' | 'set-theme';
    payload?: unknown;
} & (
    | {
          type: 'toggle-theme';
      }
    | {
          type: 'set-theme';
          payload: string;
      }
);

/**
 * Custom. Used by theme switch.
 */
function themeReducer(state: Theme, action: ThemeAction) {
    switch (action.type) {
        case 'toggle-theme':
            return state.name === 'light' ? MaterialDarkTheme : MaterialLightTheme;
        case 'set-theme':
            return action.payload === 'light' ? MaterialLightTheme : MaterialDarkTheme;
    }

    return state;
}

/**
 * Custom.
 *
 * Returns 'light' theme if the client is not a browser.
 * In case it is browser, return stored theme, if there.
 */
const getStoredTheme = () => {
    if (!isBrowser()) {
        return MaterialLightTheme;
    }

    let theme: Theme | null = null;

    const themeName = localStorage.getItem('theme');

    switch (themeName) {
        case 'light':
            theme = MaterialLightTheme;
            break;
        case 'dark':
            theme = MaterialDarkTheme;
            break;
    }

    return theme;
};
//#endregion : Helpers

//#region : Main component

/** Custom. Main component parameters type */
type Props = {
    title?: string;
    children: ReactNode;
};

/**
 * Custom.
 *
 * This component handles the main layout of the site.
 */
export default function Layout({ title = 'Wuxiaworld', children }: Props) {
    //#region : Variables, functions and api calls

    //#region : Check user login required
    const { user, init, login } = useAuth();
    const location = useLocation();
    const loginRequired = useMemo(() => {
        if (location.pathname.startsWith('/auth')) {
            return false;
        }

        if (init && !user) {
            return true;
        }

        return !init || !user?.vip;
    }, [init, location.pathname, user]);
    //#endregion : Check user login required

    //#region : Setup theme
    const storedTheme = useMemo(() => getStoredTheme(), []);
    const [theme, dispatchTheme] = useReducer(themeReducer, storedTheme ?? MaterialLightTheme);
    const light = useColorScheme('light');
    useEnhancedEffect(() => {
        const theme = getStoredTheme() ?? (light ? MaterialLightTheme : MaterialDarkTheme);

        dispatchTheme({
            type: 'set-theme',
            payload: theme.name,
        });
    }, [light]);

    //Store the current theme in browser's localstorage
    useEffect(() => {
        if (typeof window !== 'undefined' && window.localStorage) {
            localStorage.setItem('theme', theme.name);
        }
    }, [theme.name]);
    //#endregion : Setup theme

    //#region : Internet is off
    const [displayNetworkError, setDisplayNetworkError] = useState(!onlineManager.isOnline());
    useEffect(
        () =>
            onlineManager.subscribe(() => {
                setDisplayNetworkError(!onlineManager.isOnline());
            }),
        []
    );
    //#endregion : Internet is off

    //#endregion : Variables, functions and api calls

    return (
        <React.Fragment>
            <Helmet>
                <meta name="theme-color" content={theme.palette.primary.main} />
            </Helmet>
            <MuiThemeProvider theme={theme}>
                <CssBaseline />
                <GlobalStyles
                    styles={{
                        body: {
                            backgroundColor: theme.palette.primary.main,
                        },
                    }}
                />
                <ThemeProvider theme={theme}>
                    <ModalProvider>
                        <Header onDispatchTheme={dispatchTheme} />
                        <div css={StyledAppMainContainerWrapper}>
                            <Container css={StyledAppMainContainer}>
                                <main>
                                    <ErrorBoundary>
                                        {/* {user && !user.vip ? (
                                        <div style={{ textAlign: 'center' }}>
                                            <Typography variant="h4">Access Denied.</Typography>
                                            <Typography variant="h6">
                                                VIP Subscription Required
                                            </Typography>
                                        </div>
                                    )
                                        : loginRequired
                                            ? (
                                                <div style={{ textAlign: 'center' }}>
                                                    <Typography variant="h4">Access Denied.</Typography>
                                                    <Typography variant="h6">
                                                        <Button onClick={() => login(window.location.pathname)} variant="outlined">Click here to login</Button>
                                                    </Typography>
                                                </div>
                                            )
                                            : children
                                    } */}
                                        {children}
                                    </ErrorBoundary>
                                </main>
                            </Container>
                        </div>
                        <Footer />
                        {displayNetworkError && (
                            <div css={StyledNetworkErrorContainer}>
                                <SignalWifiOff /> &nbsp; No internet connection.
                            </div>
                        )}
                    </ModalProvider>
                </ThemeProvider>
            </MuiThemeProvider>
        </React.Fragment>
    );
}
//#endregion : Main component
