import { css, Theme } from '@emotion/react';

export const StyledAppDefaultMargins = (theme: Theme) =>
    css({
        //marginTop: theme.typography.pxToRem(15),
        marginBottom: theme.typography.pxToRem(15),
        margin: '0px auto',
        [theme.breakpoints.up('lg')]: {
            maxWidth: `${theme.breakpoints.values.lg}px !important`,
        },
    });

export const StyledAppMainContainer = (theme: Theme) =>
    css(StyledAppDefaultMargins(theme), {
        minHeight: '100vh',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(2),
    });

export const StyledAppMainContainerWrapper = (theme: Theme) =>
    css({
        overflowX: 'hidden',
    });
