import type {} from '@emotion/react/types/css-prop';
import baseLoadable from '@loadable/component';
import { StyledEngineProvider } from '@material-ui/core';
import { StylesProvider } from '@material-ui/styles';
import * as React from 'react';
import { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { broadcastQueryClient } from 'react-query/broadcastQueryClient-experimental';
import { createLocalStoragePersistor } from 'react-query/createLocalStoragePersistor-experimental';
import { ReactQueryDevtools } from 'react-query/devtools';
import { persistQueryClient } from 'react-query/persistQueryClient-experimental';
import { BrowserRouter as Router, Redirect, Route, RouteProps, Switch, useLocation } from 'react-router-dom';
import AuthComponent from './components/auth.component';
import Layout from './components/layout';
import './styles/index.scss';
import './styles/vendor.scss';

/**
 * Custom.
 */
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 1000 * 60 * 5,
            refetchOnWindowFocus: false,
        },
    },
});

const localStoragePersistor = createLocalStoragePersistor({
    localStorageKey: 'WUXIAWORLD_OFFLINE_QUERY_CACHE',
});

persistQueryClient({
    queryClient,
    persistor: localStoragePersistor,
});

broadcastQueryClient({
    queryClient,
    broadcastChannel: 'wuxiaworld-spa',
});

/**
 * Custom.
 */
type AppRoute = {
    path: string;
    component: React.ComponentType<AppRouteProps>;
    config?: RouteProps;
    private?: boolean;
    routes?: AppRoute[];
};

/**
 * Custom.
 */
type AppRouteProps = {
    routes?: AppRoute[];
};

/**
 * Custom.
 *
 * Syntax used from "Loadable detection" section in the link below
 * https://loadable-components.com/docs/babel-plugin/
 */
function loadable(func) {
    return baseLoadable(func, { fallback: <div>Loading...</div> });
}

const Home = loadable(() => import('./pages'));
const Updates = loadable(() => import('./pages/updates'));
const News = loadable(() => import('./pages/news'));
const Audiobooks = loadable(() => import('./pages/audiobooks'));
const Ebooks = loadable(() => import('./pages/ebooks'));
const NewsItem = loadable(() => import('./pages/news/[slug]'));
const Announcements = loadable(() => import('./pages/announcements'));
const AnnouncementsNovel = loadable(() => import('./pages/announcements/[novelSlug]'));
const Announcement = loadable(() => import('./pages/announcements/[novelSlug]/[slug]'));
const Novels = loadable(() => import('./pages/novels'));
const Novel = loadable(() => import('./pages/novel/[novelSlug]'));
const Chapter = loadable(() => import('./pages/novel/[novelSlug]/[chapterSlug]'));
const ManageBookmarks = loadable(() => import('./pages/manage/bookmarks'));
const ManageSubscriptions = loadable(() => import('./pages/manage/subscriptions'));
const ManageNovelSubscriptionTiers = loadable(() => import('./pages/manage/subscriptions/sponsorships/[novelSlug]'));
const ManageProfile = loadable(() => import('./pages/manage/profile'));
const ManageAudiobooks = loadable(() => import('./pages/manage/products/audiobooks'));
const ManageEbooks = loadable(() => import('./pages/manage/products/ebooks'));
const AuthLogout = loadable(() => import('./pages/auth/logout'));
const AuthWuxiaworldCallback = loadable(() => import('./pages/auth/callback/wuxiaworld'));
const ContactUs = loadable(() => import('./pages/contact-us'));
const GeneralFaq = loadable(() => import('./pages/general-faq'));
const About = loadable(() => import('./pages/about'));
const TermsOfService = loadable(() => import('./pages/terms-of-service'));
const PrivacyPolicy = loadable(() => import('./pages/privacy-policy'));
const Error404 = loadable(() => import('./pages/404'));

/**
 * Custom.
 */
export default function App() {
    return (
        <StyledEngineProvider injectFirst>
            <StylesProvider>
                <QueryClientProvider client={queryClient}>
                    {<ReactQueryDevtools initialIsOpen={false} />}
                    <Router>
                        <AuthComponent>
                            <ScrollToTop />
                            <Layout>
                                <Switch>
                                    <Route path="/" exact>
                                        <Home />
                                    </Route>
                                    <Route path="/updates" exact>
                                        <Updates />
                                    </Route>
                                    <Route path="/news" exact>
                                        <News />
                                    </Route>
                                    <Route path="/audiobooks" exact>
                                        <Audiobooks />
                                    </Route>
                                    <Route path="/ebooks" exact>
                                        <Ebooks />
                                    </Route>
                                    <Route path="/news/:slug" exact>
                                        <NewsItem />
                                    </Route>
                                    <Route path="/announcements" exact>
                                        <Announcements />
                                    </Route>
                                    <Route path="/announcements/:novelSlug" exact>
                                        <AnnouncementsNovel />
                                    </Route>
                                    <Route path="/announcements/:novelSlug/:slug" exact>
                                        <Announcement />
                                    </Route>
                                    <Route path="/novels" exact>
                                        <Novels />
                                    </Route>
                                    <Route path="/novel/:novelSlug" exact>
                                        <Novel />
                                    </Route>
                                    <Route path="/novel/:novelSlug/:chapterSlug" exact>
                                        <Chapter />
                                    </Route>
                                    <Route path="/manage/bookmarks" exact>
                                        <ManageBookmarks />
                                    </Route>
                                    <Route path="/manage/subscriptions/sponsorships/:novelSlug" exact>
                                        <ManageNovelSubscriptionTiers />
                                    </Route>
                                    <Route path="/manage/subscriptions">
                                        <ManageSubscriptions />
                                    </Route>
                                    <Route path="/manage/profile">
                                        <ManageProfile />
                                    </Route>
                                    <Route path="/manage/products/audiobooks" exact>
                                        <ManageAudiobooks />
                                    </Route>
                                    <Route path="/manage/products/ebooks" exact>
                                        <ManageEbooks />
                                    </Route>
                                    <Route path="/auth/logout" exact>
                                        <AuthLogout />
                                    </Route>
                                    <Route path="/auth/callback/wuxiaworld" exact>
                                        <AuthWuxiaworldCallback />
                                    </Route>
                                    <Route path="/contact-us" exact>
                                        <ContactUs />
                                    </Route>
                                    <Route path="/general-faq" exact>
                                        <GeneralFaq />
                                    </Route>
                                    <Route path="/about" exact>
                                        <About />
                                    </Route>
                                    <Route path="/terms-of-service" exact>
                                        <TermsOfService />
                                    </Route>
                                    <Route path="/terms-of-service-and-privacy-policy" exact>
                                        <PrivacyPolicy />
                                    </Route>
                                    <Route path="/404" exact>
                                        <Error404 />
                                    </Route>
                                    <Redirect from="*" to="/404" />
                                </Switch>
                            </Layout>
                        </AuthComponent>
                    </Router>
                </QueryClientProvider>
            </StylesProvider>
        </StyledEngineProvider>
    );
}

/**
 * Custom.
 */
function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}
