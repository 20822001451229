import { css, Theme } from '@emotion/react';
import { Alert, Button } from '@material-ui/core';
import { SignalWifiOff } from '@material-ui/icons';
import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ErrorBoundary as ReactErrorBoundary, FallbackProps } from 'react-error-boundary';
import { Helmet } from 'react-helmet';
import { onlineManager } from 'react-query';
import { useLocation } from 'react-router';
import { ContentCenter } from './shared/generic.styles';

//#region : Styles
export const StyledErrorMessageContainer = (theme: Theme) =>
    css({
        margin: 'auto',
        textAlign: 'center',
    });
//#endregion : Styles

//#region : Helper local function components to reduce main component code length
/**
 * Default ErrorBoundaryFallback function component.
 */
function ErrorBoundaryFallback({ error, resetErrorBoundary }: FallbackProps) {
    //#region : Variables, functions and api calls

    //#region : Clear error when user navigates to another page
    const location = useLocation();
    const pathname = useRef(location.pathname);

    useEffect(() => {
        if (pathname.current !== location.pathname) {
            pathname.current = location.pathname;

            if (error) {
                resetErrorBoundary();
            }
        }
    }, [error, location.pathname, resetErrorBoundary]);
    //#endregion : Clear error when user navigates to another page

    //#region : Any other uncaught javascript error
    const handleReload = useCallback(() => {
        if (error.name === 'ChunkLoadError') {
            window.location.reload();
        } else {
            resetErrorBoundary();
        }
    }, [error, resetErrorBoundary]);
    //#endregion : Any other uncaught javascript error

    //#region : No internet
    //Show network error when internet is off.
    const [displayNetworkError, setDisplayNetworkError] = useState(!onlineManager.isOnline());

    useEffect(
        //Subscribe to network change and reset errorboundary when internet is back online
        () =>
            onlineManager.subscribe(() => {
                if (error && onlineManager.isOnline()) {
                    resetErrorBoundary();
                }
                setDisplayNetworkError(!onlineManager.isOnline());
            }),
        [error, resetErrorBoundary]
    );
    //#endregion : No internet

    //#endregion : Variables, functions and api calls

    //Damn... no internet connection :(
    //Show a polite message, don't be rude!
    if (displayNetworkError) {
        return (
            <>
                <Helmet>
                    <title>Error! No internet</title>
                </Helmet>
                <div css={StyledErrorMessageContainer}>
                    <p css={ContentCenter}>
                        <SignalWifiOff /> &nbsp; No internet connection.
                    </p>
                    <p>Sorry... we cannot load this page without internet.</p>
                    <p>We will be back as soon as the internet connection is back.</p>
                    <p>
                        <strong>See you soon :)</strong>
                    </p>
                </div>
            </>
        );
    }

    //We will be here if there is any other uncaught javascript error.
    //Show the alert component with 'Reload' button.
    return (
        <Alert variant="outlined" severity="error" action={<Button onClick={handleReload}>Reload</Button>}>
            An error has occurred
        </Alert>
    );
}
//#endregion : Helper local function components to reduce main component code length

//#region : Main component
//Main component parameters type
export type ErrorProps = {
    fallback?: React.ComponentType<FallbackProps>;
    children?: React.ReactNode;
};

/**
 * Custom helper component.
 *
 * Use it when you want to draw an error boundary around one or many componenent(s).
 * @link https://reactjs.org/docs/error-boundaries.html
 *
 * It handles any uncaught/unhandled javascript errors.
 * If there is no error, children of the component are returned.
 *
 * If there is error and fallback component is provided, return the fallback component.
 *
 * Otherwise, returned component is based on one of the following two scenarios:
 *
 * 1) If internet is disconnected, returns a message with an observer which will automatically
 * reload the component(s) when internent connection is back.
 *
 * 2) Returns an Alert component in case of any other unhandled javascript error with reload button.
 */
export function ErrorBoundary({ fallback, children }: ErrorProps) {
    return <ReactErrorBoundary FallbackComponent={fallback || ErrorBoundaryFallback}>{children}</ReactErrorBoundary>;
}
//#endregion : Main component
