import { TinyColor } from '@ctrl/tinycolor';
import { Theme as EmotionTheme } from '@emotion/react';
import { createTheme, Theme, unstable_createMuiStrictModeTheme } from '@material-ui/core/styles';
import createPalette, { Palette } from '@material-ui/core/styles/createPalette';

const defaultTheme = createTheme();

const lightPalette = createPalette({
    mode: 'light',
    primary: {
        main: '#FFFFFF',
    },
    secondary: {
        main: '#232323',
    },
    background: {
        paper: '#FFFFFF',
        default: '#FFFFFF',
    },
    text: {
        primary: '#000000',
        secondary: '#434343',
        disabled: '#888888',
    },
    common: {
        black: '#FFFFFF',
        white: '#000000',
    },
    divider: '#E8E8E8',
});

const darkPalette = createPalette({
    mode: 'dark',
    primary: {
        main: '#202020',
    },
    secondary: {
        main: '#FFFFFF',
    },
    background: {
        default: '#313131',
        paper: '#131415',
    },
    text: {
        primary: '#FFFFFF',
        secondary: '#B6B6B6',
        disabled: '#888888',
    },
    common: {
        black: '#202020',
        white: '#FFFFFF',
    },
    divider: '#313131',
});

function getMuiTheme({
    name,
    defaultTheme,
    palette,
}: {
    name: string;
    defaultTheme: Theme;
    palette: Palette;
}): EmotionTheme {
    const defaultBoxShadow = `0px 2px 5px 0px ${new TinyColor(palette.common.white).setAlpha(0.25).toRgbString()}`;

    return {
        name: name,
        ...createTheme({
            breakpoints: defaultTheme.breakpoints,
            palette: palette,
            typography: {
                fontFamily: ['Open Sans', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
                fontWeightRegular: 400,
                fontWeightMedium: 600,
                fontWeightBold: 800,
                h1: {
                    fontSize: defaultTheme.typography.pxToRem(30),
                    lineHeight: defaultTheme.typography.pxToRem(36),
                    color: palette.text.primary,
                    fontWeight: 600,
                    marginBottom: defaultTheme.spacing(3),
                },
                h2: {
                    fontSize: defaultTheme.typography.pxToRem(24),
                    lineHeight: defaultTheme.typography.pxToRem(29),
                    color: palette.text.primary,
                    fontWeight: 600,
                },
                h3: {
                    fontSize: defaultTheme.typography.pxToRem(20),
                    lineHeight: defaultTheme.typography.pxToRem(24),
                    color: palette.text.primary,
                    fontWeight: 600,
                },
                h4: {
                    fontSize: defaultTheme.typography.pxToRem(18),
                    lineHeight: defaultTheme.typography.pxToRem(22),
                    color: palette.text.primary,
                    fontWeight: 600,
                },
                h5: {
                    fontSize: defaultTheme.typography.pxToRem(16),
                    lineHeight: defaultTheme.typography.pxToRem(19),
                    color: palette.text.primary,
                },
                h6: {
                    fontSize: defaultTheme.typography.pxToRem(14),
                    lineHeight: defaultTheme.typography.pxToRem(18),
                    color: palette.text.primary,
                },
                subtitle1: {
                    fontSize: defaultTheme.typography.pxToRem(18),
                    lineHeight: defaultTheme.typography.pxToRem(30),
                    color: palette.text.secondary,
                },
                subtitle2: {
                    fontSize: defaultTheme.typography.pxToRem(18),
                    lineHeight: defaultTheme.typography.pxToRem(30),
                    color: palette.text.secondary,
                },
                body1: {
                    fontSize: defaultTheme.typography.pxToRem(16),
                    lineHeight: defaultTheme.typography.pxToRem(30),
                    color: palette.text.secondary,
                },
                body2: {
                    fontSize: defaultTheme.typography.pxToRem(20),
                    lineHeight: defaultTheme.typography.pxToRem(30),
                    color: palette.text.secondary,
                },
                caption: {
                    fontSize: defaultTheme.typography.pxToRem(14),
                    lineHeight: defaultTheme.typography.pxToRem(18),
                    color: palette.text.disabled,
                },
                overline: {},
                button: {
                    textTransform: 'none',
                },
            },
            components: {
                MuiBreadcrumbs: {
                    defaultProps: {
                        separator: '›',
                    },
                    styleOverrides: {
                        root: {
                            marginBottom: defaultTheme.typography.pxToRem(35),
                            fontWeight: 600,
                        },
                    },
                },
                MuiPagination: {
                    defaultProps: {
                        variant: 'outlined',
                        shape: 'rounded',
                        size: 'medium',
                    },
                },
                MuiPaginationItem: {
                    styleOverrides: {
                        root: {
                            fontSize: defaultTheme.typography.pxToRem(16),
                            '&.Mui-selected': {
                                border: '1px solid #0CA0FF',
                                backgroundColor: 'transparent',
                            },
                        },
                        sizeSmall: {
                            fontSize: defaultTheme.typography.pxToRem(12),
                        },
                        sizeLarge: {
                            fontSize: defaultTheme.typography.pxToRem(18),
                        },
                    },
                },
                MuiAvatar: {
                    styleOverrides: {
                        root: {
                            border: '1px solid',
                        },
                    },
                },
                MuiTable: {
                    styleOverrides: {
                        root: {
                            '& .MuiTableCell-root': {
                                fontSize: defaultTheme.typography.pxToRem(14),
                                '&:last-child': {
                                    paddingRight: '0px',
                                },
                                '&:first-of-type': {
                                    paddingLeft: '0px',
                                },
                            },
                            '& .MuiTableCell-head': {
                                fontWeight: 'bold',
                            },
                            '& .MuiTableCell-body': {
                                //fontWeight: "bold"
                            },
                        },
                    },
                },
                MuiDialog: {
                    styleOverrides: {
                        root: {},
                        paper: {
                            backgroundColor: palette.common.black,
                            backgroundImage: 'none',
                            borderRadius: defaultTheme.typography.pxToRem(6),
                        },
                    },
                },
                MuiDialogActions: {
                    styleOverrides: {
                        root: {
                            gap: defaultTheme.spacing(1),
                            padding: defaultTheme.spacing(2, 3),
                            justifyContent: 'center',
                        },
                    },
                },
                MuiBottomNavigation: {
                    styleOverrides: {
                        root: {
                            //backgroundColor: palette.common.white,
                            height: 'auto',
                        },
                    },
                },
                MuiBottomNavigationAction: {
                    styleOverrides: {
                        root: {
                            //color: palette.common.black,
                            fontSize: defaultTheme.typography.pxToRem(12),
                            lineHeight: defaultTheme.typography.pxToRem(14),
                            '&.Mui-disabled': {
                                color: palette.text.disabled,
                            },
                        },
                    },
                },
                MuiCard: {
                    styleOverrides: {
                        root: {
                            //backgroundColor: palette.mode === 'light' ? '#fff' : '#131415',
                            backgroundImage: 'none',
                        },
                    },
                },
                MuiAccordion: {
                    styleOverrides: {
                        root: {
                            backgroundImage: 'none',
                            margin: defaultTheme.spacing(2, 0),
                            borderRadius: '6px',
                            '&:before': {
                                display: 'none',
                            },
                        },
                    },
                },
                MuiAccordionSummary: {
                    styleOverrides: {
                        root: {
                            '& > *': {
                                display: 'flex',
                                alignItems: 'center',
                            },
                            '&.Mui-expanded': {
                                borderBottom: '1px solid',
                                borderColor: palette.divider,
                            },
                        },
                    },
                },
                MuiChip: {
                    styleOverrides: {
                        colorPrimary: {
                            background: 'linear-gradient(131.45deg, #20A7FE 0%, #003AFF 100%)',
                        },
                    },
                },
                MuiSwitch: {
                    defaultProps: {},
                    styleOverrides: {
                        root: {
                            padding: defaultTheme.typography.pxToRem(0),
                            '& .MuiSwitch-switchBase': {
                                height: '100%',
                                position: 'absolute',
                                padding: '1%',
                                paddingLeft: '6%',
                            },
                            '& .MuiSwitch-track': {
                                borderRadius: defaultTheme.typography.pxToRem(19.5),
                                opacity: '1 !important',
                                backgroundColor: '#B6B6B6',
                            },
                            '& .MuiSwitch-thumb': {
                                backgroundColor: '#FFFFFF',
                            },
                            '& .Mui-checked': {
                                transform: `translateX(100%)  !important`,
                                '& + .MuiSwitch-track': {
                                    backgroundColor: '#0CA0FF !important',
                                },
                            },
                        },
                        sizeSmall: {
                            width: defaultTheme.typography.pxToRem(40),
                            height: defaultTheme.typography.pxToRem(22),
                        },
                        sizeMedium: {
                            width: defaultTheme.typography.pxToRem(50),
                            height: defaultTheme.typography.pxToRem(26),
                        },
                    },
                },
                MuiInputLabel: {
                    defaultProps: {
                        shrink: true,
                    },
                    styleOverrides: {
                        root: {
                            transform: 'none',
                            position: 'relative',
                            marginBottom: defaultTheme.typography.pxToRem(10),
                            fontWeight: 600,
                            color: palette.text.primary,
                            '&.Mui-focused': {
                                color: 'inherit',
                            },
                        },
                    },
                },
                MuiFormControl: {
                    defaultProps: {
                        fullWidth: true,
                    },
                },
                MuiInputBase: {
                    styleOverrides: {
                        root: {
                            padding: `${defaultTheme.typography.pxToRem(10)} ${defaultTheme.typography.pxToRem(10)}`,
                            '&.MuiInputBase-sizeSmall': {
                                padding: defaultTheme.typography.pxToRem(5),
                            },
                        },
                    },
                },
                MuiOutlinedInput: {
                    styleOverrides: {
                        root: {
                            borderRadius: defaultTheme.typography.pxToRem(6),
                            position: 'relative',
                            border: `${defaultTheme.typography.pxToRem(1)} solid #888888`,
                            transition: defaultTheme.transitions.create(['border-color', 'box-shadow']),
                            '&:hover': {
                                borderColor: '#0CA0FF',
                                boxShadow: defaultBoxShadow,
                            },
                            '&.Mui-focused': {
                                borderColor: '#0CA0FF',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: defaultTheme.typography.pxToRem(0),
                            },
                        },
                        input: {
                            padding: defaultTheme.typography.pxToRem(0),
                        },
                    },
                },
                MuiBadge: {
                    defaultProps: {
                        max: 99999,
                    },
                },
                MuiButton: {
                    defaultProps: {
                        variant: 'contained',
                        color: 'primary',
                    },
                    styleOverrides: {
                        root: {
                            border: `${defaultTheme.typography.pxToRem(1)} solid #0CA0FF`,
                            borderRadius: defaultTheme.typography.pxToRem(25),
                            transition: defaultTheme.transitions.create(['box-shadow']),
                            '&.Mui-disabled': {
                                color: palette.mode === 'dark' ? '#616161' : '#888888',
                                background: palette.mode === 'dark' ? '#313131' : '#D7D7D7',
                            },
                            '&:hover': {
                                borderColor: '#0CA0FF',
                                boxShadow: defaultBoxShadow,
                            },
                        },
                        label: {
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                        },
                        sizeSmall: {
                            padding: `${defaultTheme.typography.pxToRem(1)} ${defaultTheme.typography.pxToRem(15)}`,
                            lineHeight: defaultTheme.typography.pxToRem(16),
                        },
                        sizeMedium: {
                            fontSize: defaultTheme.typography.pxToRem(16),
                            padding: `${defaultTheme.typography.pxToRem(5)} ${defaultTheme.typography.pxToRem(25)}`,
                        },
                        sizeLarge: {
                            fontSize: defaultTheme.typography.pxToRem(18),
                            lineHeight: defaultTheme.typography.pxToRem(22),
                            padding: `${defaultTheme.typography.pxToRem(15)} ${defaultTheme.typography.pxToRem(50)}`,
                        },
                        contained: {
                            margin: defaultTheme.typography.pxToRem(0),
                        },
                        containedPrimary: {
                            background: 'linear-gradient(131.45deg, #20A7FE 0%, #003AFF 100%)',
                            color: '#FFFFFF',
                            whiteSpace: 'nowrap',
                        },
                        outlined: {
                            margin: defaultTheme.typography.pxToRem(0),
                        },
                        outlinedPrimary: {
                            backgroundColor: palette.mode === 'dark' ? '#000' : palette.common.black,
                            color: palette.common.white,
                        },
                        textPrimary: {
                            border: 'none',
                            color: '#0CA0FF',
                            fontWeight: 600,
                            paddingLeft: '0px !important',
                            paddingRight: '0px !important',
                            '&:hover': {
                                boxShadow: 'none',
                                background: 'none',
                                textDecoration: 'underline',
                            },
                        },
                        textSecondary: {
                            border: 'none',
                            paddingLeft: '0px !important',
                            paddingRight: '0px !important',
                            '&:hover': {
                                boxShadow: 'none',
                                background: 'none',
                                textDecoration: 'underline',
                            },
                        },
                    },
                },
                MuiStepIcon: {
                    styleOverrides: {
                        root: {
                            '&.MuiStepIcon-completed': {
                                color: palette.common.white,
                            },
                            '&.Mui-active': {
                                color: '#0CA0FF',
                            },
                        },
                    },
                },
                /*MuiFab: {                
                    styleOverrides: {
                        root: {
                            backgroundColor: '#ffffff',
                            border: `${defaultTheme.typography.pxToRem(1)} solid #0CA0FF`,
                        }
                    }
                },*/
                MuiFab: {
                    styleOverrides: {
                        root: {
                            color: '#ffffff',
                            backgroundColor: '#000000',
                            ':hover': {
                                backgroundColor: '#323232',
                            },
                            border: `${defaultTheme.typography.pxToRem(1)} solid #0CA0FF`,
                            '@media (hover: none)': {
                                ':hover': {
                                    backgroundColor: '#323232 !important',
                                },
                            },
                        },
                    },
                },
                MuiLinearProgress: {
                    styleOverrides: {
                        barColorPrimary: {
                            backgroundColor: '#cccccc',
                        },
                    },
                },
                MuiMenuItem: {
                    styleOverrides: {
                        root: {
                            '&.Mui-selected': {
                                backgroundColor: palette.mode === 'light' ? '#F3F3F3' : '#313131',
                                '&:hover': {
                                    backgroundColor:
                                        palette.mode === 'light' ? 'rgba(0, 0, 0, 0.04)' : 'rgba(255, 255, 255, 0.16)',
                                },
                            },
                        },
                    },
                },
            },
        }),
    };
}

export const MaterialLightTheme: EmotionTheme = getMuiTheme({
    name: 'light',
    defaultTheme: defaultTheme,
    palette: lightPalette,
});

export const MaterialDarkTheme: EmotionTheme = getMuiTheme({
    name: 'dark',
    defaultTheme: defaultTheme,
    palette: darkPalette,
});
