import { Mutex } from 'async-mutex';
import { Empty } from 'google-protobuf/google/protobuf/empty_pb';
import type { SignoutResponse, User as OidcUser, UserManager, UserManagerSettings } from 'oidc-client';
import { createContext, useCallback, useContext } from 'react';
import { isBrowser } from '../utils/utils';
import { UsersClient } from '../_proto/Protos/users_grpc_web_pb';
import { UserItem } from '../_proto/Protos/users_pb';
import { grpcRequestAsObject } from './grpc';

const AUTHORITY = process.env.REACT_APP_AUTH_AUTHORITY;
const CLIENT_ID = process.env.REACT_APP_AUTH_CLIENT_ID;
const REDIRECT_URI = `${process.env.REACT_APP_SITE}/auth/callback/wuxiaworld`;
const LOGOUT_REDIRECT_URI = `${process.env.REACT_APP_SITE}/auth/logout`;
const SCOPES = 'openid profile api email offline_access';

/**
 * Custom.
 *
 * OpenID Connect user manager
 */
let userManager: UserManager | null = null;

/**
 * Custom.
 *
 * Used to store OpenID Connect config
 */
let config: UserManagerSettings | null = null;

/**
 * Custom.
 *
 * Used to store auth mutex
 */
const authMutex = new Mutex();

/**
 * Custom type.
 *
 * OpenID Connect login result
 */
type LoginCallbackResult = {
    user?: OidcUser;
};

/**
 * Custom type.
 *
 * Protobuf user object
 */
export type User = UserItem.AsObject & {
    accessToken: string;
};

/**
 * Custom type.
 *
 * Used to create AuthenticationContext
 * and also part of the return type of 'useAuth'.
 *
 * Has logged in user's getter/setter.
 */
export type AuthContext = {
    init: boolean;
    user?: User | null;
    setUser: ((user: User | null | undefined) => void) | null;
};

/**
 * Custom.
 *
 * Just creating an auth context
 */
const AuthenticationContext = createContext<AuthContext | null>(null);

/**
 * Custom.
 *
 * Use it as one of the top level DOM to access AuthContext values.
 */
const AuthenticationProvider = AuthenticationContext.Provider;

/**
 * Custom type.
 *
 * Used as part of the return type of 'useAuth'
 */
type AuthMethods = {
    login: (state?: string) => Promise<void>;
    logout: () => Promise<boolean>;
    loginCallback: (() => Promise<LoginCallbackResult | null>) | null;
    logoutCallback: () => Promise<SignoutResponse>;
    refreshUser: () => Promise<User | null>;
    // getIdUser: () => Promise<OidcUser | null>;
};

/**
 * Custom function.
 *
 * OpenID Connect config.
 */
const getOidcConfig = (): UserManagerSettings => {
    if (config) {
        return config;
    }

    config = {
        authority: AUTHORITY,
        client_id: CLIENT_ID,
        redirect_uri: REDIRECT_URI,
        silent_redirect_uri: REDIRECT_URI,
        post_logout_redirect_uri: LOGOUT_REDIRECT_URI,
        response_type: 'code',
        response_mode: 'query',
        scope: SCOPES,

        popup_redirect_uri: REDIRECT_URI,
        popup_post_logout_redirect_uri: LOGOUT_REDIRECT_URI,

        filterProtocolClaims: true,
        automaticSilentRenew: true,
        revokeAccessTokenOnSignout: true,
        loadUserInfo: false,
        prompt: 'login',
    };

    return config!;
};

/**
 * Custom function.
 *
 * Returns OpenID Connect user Manager
 */
export const getUserManager = async () => {
    const Oidc = (await import('oidc-client')).default;

    if (userManager) {
        return userManager;
    }

    if (!config) {
        config = getOidcConfig();
    }

    userManager = new Oidc.UserManager({
        ...config,
        userStore: new Oidc.WebStorageStateStore({ store: localStorage }),
    });

    return userManager;
};

/**
 * Custom function.
 *
 * Login through OpenID Connect
 */
const login = async (state?: any) => {
    if (!isBrowser()) {
        return;
    }

    const userManager = await getUserManager();

    const width = 996;
    const height = 700;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;

    const idUser = await userManager.signinPopup({
        popupWindowFeatures: `toolbar=no, location=no, width=${width}, height=${height}, top=${top}, left=${left}`,
    });

    return idUser;
};

/**
 * Custom function.
 *
 * OpenID Connect silent login. Attempts to silently signin an already authenticated
 * user when the authentication period expires.
 *
 * The OpenID Connect protocol supports allows applications to indicate that
 * the authorization server must not display any user interaction
 * (such as authentication, consent, or MFA). It will either return the requested
 * response back to the application, or return an error if the user is not already
 * authenticated or if some type of consent or prompt is required before proceeding.
 */
export const silentLogin = async () => {
    const userManager = await getUserManager();

    const authMutex = getAuthMutex();
    const release = await authMutex.acquire();

    try {
        const user = await userManager.getUser();

        if (!user) {
            return await userManager.signinSilent();
        }

        return user;
    } finally {
        release();
    }
};

/**
 * Custom function.
 *
 * Returns mutex used for auth.
 */
export const getAuthMutex = () => {
    return authMutex;
};

/**
 * Custom function.
 *
 * Retrieves user info stored in browser's local storage (if it exists)
 * by the site during auth
 */
export const getLocalUser = () => {
    if (!isBrowser()) {
        return null;
    }

    const userJson = localStorage.getItem('user');

    if (userJson) {
        const user: User = JSON.parse(userJson);

        return user;
    }

    return null;
};

/**
 * Custom function.
 *
 * Makes server request to get user details.
 */
export const retrieveUser = async (accessToken: string): Promise<UserItem.AsObject | null> => {
    const itemUser = await grpcRequestAsObject(UsersClient, c => c.getMyUser, new Empty(), {
        authorization: `Bearer ${accessToken}`,
    });

    if (itemUser.item) {
        return itemUser.item;
    }

    return null;
};

/**
 * Custom function.
 *
 * Uses OpenID Connect signinCallback internally. Returns the callback result.
 * Usually the details of the signed in user.
 */
const loginCallback = async (): Promise<LoginCallbackResult | null> => {
    if (!isBrowser()) {
        return null;
    }

    const userManager = await getUserManager();
    const user = await userManager.signinCallback();

    if (!user) {
        return null;
    }

    return {
        user,
    };
};

/**
 * Custom function.
 *
 * Sign's user out using OpenID Connect signoutRedirect and also removes it's details from
 * browser's local storage.
 */
const logout = async () => {
    if (!isBrowser() || !getLocalUser()) {
        return false;
    }

    localStorage.removeItem('user');

    const userManager = await getUserManager();

    await userManager.signoutRedirect({
        state: {
            returnPath: window.location.pathname,
        },
    });

    return true;
};

/**
 * Custom function.
 *
 * Returns OpenID Connect's signoutRedirectCallback return value.
 */
const logoutCallback = async () => {
    const userManager = await getUserManager();

    return await userManager.signoutRedirectCallback();
};

/**
 * Custom hook.
 *
 * Use it to get logged in user's information
 * and access to auth methods and callbacks.
 */
const useAuth = (): AuthContext & AuthMethods => {
    const context = useContext(AuthenticationContext);

    const getIdUser = useCallback(async () => {
        const localUser = getLocalUser();

        if (!localUser) {
            return null;
        }

        const userManager = await getUserManager();
        let idUser = await userManager.getUser();

        if (!idUser) {
            try {
                idUser = await silentLogin();
            } catch (e) {
                return null;
            }
        }

        return idUser;
    }, []);

    const doLogin = useCallback(
        async state => {
            const idUser = await login(state);

            if (idUser) {
                const userInfo = await retrieveUser?.(idUser.access_token!);

                if (!userInfo) {
                    return;
                }

                const user: User = {
                    ...userInfo,
                    accessToken: idUser.access_token,
                };

                if (userInfo) {
                    context?.setUser?.(user);
                }
            }
        },
        [context]
    );

    const refreshUser = useCallback(async () => {
        const idUser = await getIdUser();

        if (!idUser?.access_token) {
            return null;
        }

        const userInfo = await retrieveUser(idUser.access_token);

        if (!userInfo) {
            return null;
        }

        const user: User = {
            ...userInfo,
            accessToken: idUser.access_token,
        };

        context?.setUser?.(user);

        return user;
    }, [context, getIdUser]);

    return {
        ...context!,
        login: doLogin,
        logout: logout || (() => {}),
        loginCallback,
        logoutCallback,
        refreshUser,
        // getIdUser,
    };
};

export { AuthenticationContext, AuthenticationProvider, useAuth };
