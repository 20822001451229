import { css, Theme } from '@emotion/react';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from '@material-ui/core';
import * as React from 'react';
import { useContext, useRef, useState } from 'react';
import { batch } from '../libs/utils';
import ButtonSpinner from './button.spinner';

//#region : Styles
export const StyledLoaderContainer = (theme: Theme) =>
    css({
        top: '0',
        bottom: '0',
        left: '0',
        right: '0',
        backgroundColor: '#8080807a',
        zIndex: 1,
        position: 'fixed',
        display: 'flex',
        '& *': {
            margin: 'auto',
        },
    });
//#endregion : Styles

//#region : Global constants and types

/**
 * Custom.
 *
 * Internally used to show the relevant view
 */
enum ModalType {
    Undefined = 0,
    Confirmation = 1,
    Error = 2,
}

/**
 * Custom.
 *
 * Internally used to mark the completion / rejection of the modal task.
 * Resolve is called when modal completes successfully.
 * Reject is called when user cancels the action. It throws an error.
 */
type StatefulPromise = {
    resolve: () => void;
    reject: (reason?: any) => void;
};

/**
 * Custom.
 *
 * ModalContextType will be used by useContext to create context.
 * The methods declared inside it will be available to the calling component.
 * This is just a type declaration. Methods body is declared in the main component.
 */
export type ModalContextType = {
    /**
     * Custom.
     *
     * Use in a try/catch block.
     *
     * Use it when you want a confirmation for a action from the user,
     * in a modal dialog. You can choose to keep the dialog open after
     * the confirmation withConfirmProgress. It will show spinner.
     *
     * To close the dialog when wihConfirmProgress is true,
     * use closeDialogProgress context method.
     *
     * When wihConfirmProgress is false, it will throw error
     * when user cancels. When user agrees, it will complete without error.
     */
    requestConfirmation: (
        title: string,
        text: string,
        confirmText?: string,
        cancelText?: string,
        withConfirmProgress?: boolean
    ) => Promise<void>;

    /**
     * Custom.
     *
     * Use it to close the dialog when you have set wihConfirmProgress to true
     * in requestConfirmation function.
     */
    closeDialogProgress: () => void;

    /**
     * Custom.
     *
     * Use in a try/catch block.
     *
     * Use it when you want to show a modal error.
     * It will throw error when user chooses to try again later and cancels the action.
     *
     * If reloadPage is set to true, it will reload the page with window.location
     * when user clicks on reload button.
     *
     * When reloadPage is false, it will complete without error and won't reload the page.
     * You have to take care of what happens when the user clicks on 'reload'.
     */
    showError: (reloadPage: boolean, err: any) => Promise<void>;

    /**
     * Custom.
     *
     * Use it when you want to show full page modal loading indicator.
     * Pass true to show, false to hide.
     */
    showFullPageLoader: (loading: boolean) => void;
};

const ModalContext = React.createContext<ModalContextType>(null!);

/**
 * Custom.
 *
 * Use it when you need a modal dialog to take user's confirmation,
 * to show a modal error dialog,
 * or to show a modal full page loader.
 */
export const useModal = () => useContext(ModalContext);

//#endregion : Global constants and types

//#region : Main component

/** Custom. Main component parameters type */
export type ModalProps = {
    children?: React.ReactNode;
};

export default function ModalProvider({ children, ...rest }: ModalProps) {
    //#region : Variables, functions and api calls

    //Start: Dialog properties
    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [text, setText] = useState('');
    const [confirmText, setConfirmText] = useState('Ok');
    const [cancelText, setCancelText] = useState('Cancel');
    const [withConfirmProgress, setWithConfirmProgress] = useState(false);
    const [showConfirmProgress, setShowConfirmProgress] = useState(false);
    const [modalType, setModalType] = useState(ModalType.Undefined);
    const [reloadPage, setReloadPage] = useState(false);
    const statefulPromise = useRef<StatefulPromise | null>(null);
    //End: Dialog properties

    //Start: Full page loader properties
    const [loading, setLoading] = useState(false);
    //End: Full page loader properties

    //Declare ModalContextType methods body
    const [context, setContext] = useState<ModalContextType>({
        //Start: Dialog methods
        requestConfirmation: (title, text, confirmText, cancelText, withConfirmProgress = false) => {
            batch(() => {
                setTitle(title);
                setText(text);
                setConfirmText(confirmText ?? 'Ok');
                setCancelText(cancelText ?? 'Cancel');
                setOpen(true);
                setWithConfirmProgress(withConfirmProgress);
                setModalType(ModalType.Confirmation);
            });

            return new Promise((resolve, reject) => {
                statefulPromise.current = { resolve, reject };
            });
        },
        closeDialogProgress: () => {
            batch(() => {
                setOpen(false);
                setShowConfirmProgress(false);
            });
        },
        showError: (reloadPage, err) => {
            batch(() => {
                setReloadPage(reloadPage);
                setTitle('Error');
                setText(
                    'Trouble connecting to the server.\nPlease try to reload the page.\nIf the problem persists, please try again after some time.'
                );
                setConfirmText('Reload');
                setCancelText("I'll try later");
                setOpen(true);
                setWithConfirmProgress(false);
                setModalType(ModalType.Error);
            });

            return new Promise((resolve, reject) => {
                statefulPromise.current = { resolve, reject };
            });
        },
        //End: Dialog methods

        //Start: Full page loader methods
        showFullPageLoader: loading => {
            setLoading(loading);
        },
        //End: Full page loader methods
    });

    //Start: Internal Dialog methods
    const handleConfirm = () => {
        const shouldRemainOpen = withConfirmProgress ? true : false;

        batch(() => {
            setOpen(shouldRemainOpen);
            setShowConfirmProgress(shouldRemainOpen);
        });

        if (modalType == ModalType.Error && reloadPage) {
            window.location.reload();
        }

        statefulPromise.current?.resolve();
    };

    const handleCancel = () => {
        setOpen(false);

        statefulPromise.current?.reject();
    };
    //End: Internal Dialog methods

    //#endregion : Variables, functions and api calls

    return (
        <ModalContext.Provider value={context}>
            {children}
            <Dialog maxWidth="xs" aria-labelledby="confirmation-dialog-title" open={open} {...rest}>
                <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
                <DialogContent dividers>
                    {text.split('\n').map((line, idx) => (
                        <Typography key={idx}>{line}</Typography>
                    ))}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} variant="outlined" color="secondary" disabled={showConfirmProgress}>
                        {cancelText ?? 'Cancel'}
                    </Button>
                    <ButtonSpinner onClick={handleConfirm} spinner={showConfirmProgress} disabled={showConfirmProgress}>
                        {confirmText ?? 'Ok'}
                    </ButtonSpinner>
                </DialogActions>
            </Dialog>
            {loading && (
                <div css={StyledLoaderContainer}>
                    <CircularProgress color="secondary" size={56} />
                </div>
            )}
        </ModalContext.Provider>
    );
}
//#endregion : Main component
