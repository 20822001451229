// source: Protos/sponsors.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var Protos_types_pb = require('../Protos/types_pb.js');
goog.object.extend(proto, Protos_types_pb);
goog.exportSymbol('proto.wuxiaworld.api.v2.GetSponsorPlansRequest', null, global);
goog.exportSymbol('proto.wuxiaworld.api.v2.GetSponsorPlansResponse', null, global);
goog.exportSymbol('proto.wuxiaworld.api.v2.SponsorNovelRequest', null, global);
goog.exportSymbol('proto.wuxiaworld.api.v2.SponsorNovelResponse', null, global);
goog.exportSymbol('proto.wuxiaworld.api.v2.SponsorPlanItem', null, global);
goog.exportSymbol('proto.wuxiaworld.api.v2.SponsorPlanNovel', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wuxiaworld.api.v2.GetSponsorPlansRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wuxiaworld.api.v2.GetSponsorPlansRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wuxiaworld.api.v2.GetSponsorPlansRequest.displayName = 'proto.wuxiaworld.api.v2.GetSponsorPlansRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wuxiaworld.api.v2.GetSponsorPlansResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wuxiaworld.api.v2.GetSponsorPlansResponse.repeatedFields_, null);
};
goog.inherits(proto.wuxiaworld.api.v2.GetSponsorPlansResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wuxiaworld.api.v2.GetSponsorPlansResponse.displayName = 'proto.wuxiaworld.api.v2.GetSponsorPlansResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wuxiaworld.api.v2.SponsorNovelRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wuxiaworld.api.v2.SponsorNovelRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wuxiaworld.api.v2.SponsorNovelRequest.displayName = 'proto.wuxiaworld.api.v2.SponsorNovelRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wuxiaworld.api.v2.SponsorNovelResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wuxiaworld.api.v2.SponsorNovelResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wuxiaworld.api.v2.SponsorNovelResponse.displayName = 'proto.wuxiaworld.api.v2.SponsorNovelResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wuxiaworld.api.v2.SponsorPlanNovel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wuxiaworld.api.v2.SponsorPlanNovel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wuxiaworld.api.v2.SponsorPlanNovel.displayName = 'proto.wuxiaworld.api.v2.SponsorPlanNovel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wuxiaworld.api.v2.SponsorPlanItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wuxiaworld.api.v2.SponsorPlanItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wuxiaworld.api.v2.SponsorPlanItem.displayName = 'proto.wuxiaworld.api.v2.SponsorPlanItem';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wuxiaworld.api.v2.GetSponsorPlansRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wuxiaworld.api.v2.GetSponsorPlansRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wuxiaworld.api.v2.GetSponsorPlansRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.GetSponsorPlansRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    novelid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wuxiaworld.api.v2.GetSponsorPlansRequest}
 */
proto.wuxiaworld.api.v2.GetSponsorPlansRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wuxiaworld.api.v2.GetSponsorPlansRequest;
  return proto.wuxiaworld.api.v2.GetSponsorPlansRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wuxiaworld.api.v2.GetSponsorPlansRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wuxiaworld.api.v2.GetSponsorPlansRequest}
 */
proto.wuxiaworld.api.v2.GetSponsorPlansRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNovelid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wuxiaworld.api.v2.GetSponsorPlansRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wuxiaworld.api.v2.GetSponsorPlansRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wuxiaworld.api.v2.GetSponsorPlansRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.GetSponsorPlansRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNovelid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 novelId = 1;
 * @return {number}
 */
proto.wuxiaworld.api.v2.GetSponsorPlansRequest.prototype.getNovelid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wuxiaworld.api.v2.GetSponsorPlansRequest} returns this
 */
proto.wuxiaworld.api.v2.GetSponsorPlansRequest.prototype.setNovelid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wuxiaworld.api.v2.GetSponsorPlansResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wuxiaworld.api.v2.GetSponsorPlansResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wuxiaworld.api.v2.GetSponsorPlansResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wuxiaworld.api.v2.GetSponsorPlansResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.GetSponsorPlansResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.wuxiaworld.api.v2.SponsorPlanItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wuxiaworld.api.v2.GetSponsorPlansResponse}
 */
proto.wuxiaworld.api.v2.GetSponsorPlansResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wuxiaworld.api.v2.GetSponsorPlansResponse;
  return proto.wuxiaworld.api.v2.GetSponsorPlansResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wuxiaworld.api.v2.GetSponsorPlansResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wuxiaworld.api.v2.GetSponsorPlansResponse}
 */
proto.wuxiaworld.api.v2.GetSponsorPlansResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.wuxiaworld.api.v2.SponsorPlanItem;
      reader.readMessage(value,proto.wuxiaworld.api.v2.SponsorPlanItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wuxiaworld.api.v2.GetSponsorPlansResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wuxiaworld.api.v2.GetSponsorPlansResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wuxiaworld.api.v2.GetSponsorPlansResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.GetSponsorPlansResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.wuxiaworld.api.v2.SponsorPlanItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SponsorPlanItem items = 1;
 * @return {!Array<!proto.wuxiaworld.api.v2.SponsorPlanItem>}
 */
proto.wuxiaworld.api.v2.GetSponsorPlansResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.wuxiaworld.api.v2.SponsorPlanItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wuxiaworld.api.v2.SponsorPlanItem, 1));
};


/**
 * @param {!Array<!proto.wuxiaworld.api.v2.SponsorPlanItem>} value
 * @return {!proto.wuxiaworld.api.v2.GetSponsorPlansResponse} returns this
*/
proto.wuxiaworld.api.v2.GetSponsorPlansResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.wuxiaworld.api.v2.SponsorPlanItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wuxiaworld.api.v2.SponsorPlanItem}
 */
proto.wuxiaworld.api.v2.GetSponsorPlansResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.wuxiaworld.api.v2.SponsorPlanItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wuxiaworld.api.v2.GetSponsorPlansResponse} returns this
 */
proto.wuxiaworld.api.v2.GetSponsorPlansResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wuxiaworld.api.v2.SponsorNovelRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wuxiaworld.api.v2.SponsorNovelRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wuxiaworld.api.v2.SponsorNovelRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.SponsorNovelRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    planid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wuxiaworld.api.v2.SponsorNovelRequest}
 */
proto.wuxiaworld.api.v2.SponsorNovelRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wuxiaworld.api.v2.SponsorNovelRequest;
  return proto.wuxiaworld.api.v2.SponsorNovelRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wuxiaworld.api.v2.SponsorNovelRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wuxiaworld.api.v2.SponsorNovelRequest}
 */
proto.wuxiaworld.api.v2.SponsorNovelRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlanid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wuxiaworld.api.v2.SponsorNovelRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wuxiaworld.api.v2.SponsorNovelRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wuxiaworld.api.v2.SponsorNovelRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.SponsorNovelRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlanid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 planId = 1;
 * @return {number}
 */
proto.wuxiaworld.api.v2.SponsorNovelRequest.prototype.getPlanid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wuxiaworld.api.v2.SponsorNovelRequest} returns this
 */
proto.wuxiaworld.api.v2.SponsorNovelRequest.prototype.setPlanid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wuxiaworld.api.v2.SponsorNovelResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wuxiaworld.api.v2.SponsorNovelResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wuxiaworld.api.v2.SponsorNovelResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.SponsorNovelResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wuxiaworld.api.v2.SponsorNovelResponse}
 */
proto.wuxiaworld.api.v2.SponsorNovelResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wuxiaworld.api.v2.SponsorNovelResponse;
  return proto.wuxiaworld.api.v2.SponsorNovelResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wuxiaworld.api.v2.SponsorNovelResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wuxiaworld.api.v2.SponsorNovelResponse}
 */
proto.wuxiaworld.api.v2.SponsorNovelResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wuxiaworld.api.v2.SponsorNovelResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wuxiaworld.api.v2.SponsorNovelResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wuxiaworld.api.v2.SponsorNovelResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.SponsorNovelResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wuxiaworld.api.v2.SponsorPlanNovel.prototype.toObject = function(opt_includeInstance) {
  return proto.wuxiaworld.api.v2.SponsorPlanNovel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wuxiaworld.api.v2.SponsorPlanNovel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.SponsorPlanNovel.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    slug: jspb.Message.getFieldWithDefault(msg, 3, ""),
    coverurl: (f = msg.getCoverurl()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wuxiaworld.api.v2.SponsorPlanNovel}
 */
proto.wuxiaworld.api.v2.SponsorPlanNovel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wuxiaworld.api.v2.SponsorPlanNovel;
  return proto.wuxiaworld.api.v2.SponsorPlanNovel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wuxiaworld.api.v2.SponsorPlanNovel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wuxiaworld.api.v2.SponsorPlanNovel}
 */
proto.wuxiaworld.api.v2.SponsorPlanNovel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlug(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCoverurl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wuxiaworld.api.v2.SponsorPlanNovel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wuxiaworld.api.v2.SponsorPlanNovel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wuxiaworld.api.v2.SponsorPlanNovel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.SponsorPlanNovel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSlug();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCoverurl();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.wuxiaworld.api.v2.SponsorPlanNovel.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wuxiaworld.api.v2.SponsorPlanNovel} returns this
 */
proto.wuxiaworld.api.v2.SponsorPlanNovel.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.wuxiaworld.api.v2.SponsorPlanNovel.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wuxiaworld.api.v2.SponsorPlanNovel} returns this
 */
proto.wuxiaworld.api.v2.SponsorPlanNovel.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string slug = 3;
 * @return {string}
 */
proto.wuxiaworld.api.v2.SponsorPlanNovel.prototype.getSlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wuxiaworld.api.v2.SponsorPlanNovel} returns this
 */
proto.wuxiaworld.api.v2.SponsorPlanNovel.prototype.setSlug = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.StringValue coverUrl = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.wuxiaworld.api.v2.SponsorPlanNovel.prototype.getCoverurl = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.wuxiaworld.api.v2.SponsorPlanNovel} returns this
*/
proto.wuxiaworld.api.v2.SponsorPlanNovel.prototype.setCoverurl = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.SponsorPlanNovel} returns this
 */
proto.wuxiaworld.api.v2.SponsorPlanNovel.prototype.clearCoverurl = function() {
  return this.setCoverurl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.SponsorPlanNovel.prototype.hasCoverurl = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wuxiaworld.api.v2.SponsorPlanItem.prototype.toObject = function(opt_includeInstance) {
  return proto.wuxiaworld.api.v2.SponsorPlanItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wuxiaworld.api.v2.SponsorPlanItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.SponsorPlanItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: (f = msg.getDescription()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    enabled: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    visible: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    advancechaptercount: jspb.Message.getFieldWithDefault(msg, 6, 0),
    planid: jspb.Message.getFieldWithDefault(msg, 7, ""),
    price: (f = msg.getPrice()) && Protos_types_pb.DecimalValue.toObject(includeInstance, f),
    karmaprice: (f = msg.getKarmaprice()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    paused: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    novel: (f = msg.getNovel()) && proto.wuxiaworld.api.v2.SponsorPlanNovel.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wuxiaworld.api.v2.SponsorPlanItem}
 */
proto.wuxiaworld.api.v2.SponsorPlanItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wuxiaworld.api.v2.SponsorPlanItem;
  return proto.wuxiaworld.api.v2.SponsorPlanItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wuxiaworld.api.v2.SponsorPlanItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wuxiaworld.api.v2.SponsorPlanItem}
 */
proto.wuxiaworld.api.v2.SponsorPlanItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVisible(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvancechaptercount(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlanid(value);
      break;
    case 8:
      var value = new Protos_types_pb.DecimalValue;
      reader.readMessage(value,Protos_types_pb.DecimalValue.deserializeBinaryFromReader);
      msg.setPrice(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setKarmaprice(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPaused(value);
      break;
    case 11:
      var value = new proto.wuxiaworld.api.v2.SponsorPlanNovel;
      reader.readMessage(value,proto.wuxiaworld.api.v2.SponsorPlanNovel.deserializeBinaryFromReader);
      msg.setNovel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wuxiaworld.api.v2.SponsorPlanItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wuxiaworld.api.v2.SponsorPlanItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wuxiaworld.api.v2.SponsorPlanItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.SponsorPlanItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getEnabled();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getVisible();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getAdvancechaptercount();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getPlanid();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPrice();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      Protos_types_pb.DecimalValue.serializeBinaryToWriter
    );
  }
  f = message.getKarmaprice();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getPaused();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getNovel();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.wuxiaworld.api.v2.SponsorPlanNovel.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.wuxiaworld.api.v2.SponsorPlanItem.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wuxiaworld.api.v2.SponsorPlanItem} returns this
 */
proto.wuxiaworld.api.v2.SponsorPlanItem.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.wuxiaworld.api.v2.SponsorPlanItem.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wuxiaworld.api.v2.SponsorPlanItem} returns this
 */
proto.wuxiaworld.api.v2.SponsorPlanItem.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.StringValue description = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.wuxiaworld.api.v2.SponsorPlanItem.prototype.getDescription = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.wuxiaworld.api.v2.SponsorPlanItem} returns this
*/
proto.wuxiaworld.api.v2.SponsorPlanItem.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.SponsorPlanItem} returns this
 */
proto.wuxiaworld.api.v2.SponsorPlanItem.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.SponsorPlanItem.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool enabled = 4;
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.SponsorPlanItem.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wuxiaworld.api.v2.SponsorPlanItem} returns this
 */
proto.wuxiaworld.api.v2.SponsorPlanItem.prototype.setEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool visible = 5;
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.SponsorPlanItem.prototype.getVisible = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wuxiaworld.api.v2.SponsorPlanItem} returns this
 */
proto.wuxiaworld.api.v2.SponsorPlanItem.prototype.setVisible = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional int32 advanceChapterCount = 6;
 * @return {number}
 */
proto.wuxiaworld.api.v2.SponsorPlanItem.prototype.getAdvancechaptercount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.wuxiaworld.api.v2.SponsorPlanItem} returns this
 */
proto.wuxiaworld.api.v2.SponsorPlanItem.prototype.setAdvancechaptercount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string planId = 7;
 * @return {string}
 */
proto.wuxiaworld.api.v2.SponsorPlanItem.prototype.getPlanid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wuxiaworld.api.v2.SponsorPlanItem} returns this
 */
proto.wuxiaworld.api.v2.SponsorPlanItem.prototype.setPlanid = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional customTypes.DecimalValue price = 8;
 * @return {?proto.customTypes.DecimalValue}
 */
proto.wuxiaworld.api.v2.SponsorPlanItem.prototype.getPrice = function() {
  return /** @type{?proto.customTypes.DecimalValue} */ (
    jspb.Message.getWrapperField(this, Protos_types_pb.DecimalValue, 8));
};


/**
 * @param {?proto.customTypes.DecimalValue|undefined} value
 * @return {!proto.wuxiaworld.api.v2.SponsorPlanItem} returns this
*/
proto.wuxiaworld.api.v2.SponsorPlanItem.prototype.setPrice = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.SponsorPlanItem} returns this
 */
proto.wuxiaworld.api.v2.SponsorPlanItem.prototype.clearPrice = function() {
  return this.setPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.SponsorPlanItem.prototype.hasPrice = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Int32Value karmaPrice = 9;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.wuxiaworld.api.v2.SponsorPlanItem.prototype.getKarmaprice = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 9));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.wuxiaworld.api.v2.SponsorPlanItem} returns this
*/
proto.wuxiaworld.api.v2.SponsorPlanItem.prototype.setKarmaprice = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.SponsorPlanItem} returns this
 */
proto.wuxiaworld.api.v2.SponsorPlanItem.prototype.clearKarmaprice = function() {
  return this.setKarmaprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.SponsorPlanItem.prototype.hasKarmaprice = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional bool paused = 10;
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.SponsorPlanItem.prototype.getPaused = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wuxiaworld.api.v2.SponsorPlanItem} returns this
 */
proto.wuxiaworld.api.v2.SponsorPlanItem.prototype.setPaused = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional SponsorPlanNovel novel = 11;
 * @return {?proto.wuxiaworld.api.v2.SponsorPlanNovel}
 */
proto.wuxiaworld.api.v2.SponsorPlanItem.prototype.getNovel = function() {
  return /** @type{?proto.wuxiaworld.api.v2.SponsorPlanNovel} */ (
    jspb.Message.getWrapperField(this, proto.wuxiaworld.api.v2.SponsorPlanNovel, 11));
};


/**
 * @param {?proto.wuxiaworld.api.v2.SponsorPlanNovel|undefined} value
 * @return {!proto.wuxiaworld.api.v2.SponsorPlanItem} returns this
*/
proto.wuxiaworld.api.v2.SponsorPlanItem.prototype.setNovel = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.SponsorPlanItem} returns this
 */
proto.wuxiaworld.api.v2.SponsorPlanItem.prototype.clearNovel = function() {
  return this.setNovel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.SponsorPlanItem.prototype.hasNovel = function() {
  return jspb.Message.getField(this, 11) != null;
};


goog.object.extend(exports, proto.wuxiaworld.api.v2);
