import { css, Theme } from '@emotion/react';
import { ContentAlignCenter } from '../shared/generic.styles';

export const StyledFooter = (theme: Theme) =>
    css({
        backgroundColor: theme.palette.background.default,
        boxShadow: `0px 2px 10px 2px rgba(0, 0, 0, 0.1)`,
        // marginTop: theme.spacing(8),
        padding: theme.spacing(6, 0),
        '& *': {
            color: `${theme.palette.text.primary} !important`,
        },
    });

export const StyledFooterContent = (theme: Theme) =>
    css({
        justifyContent: 'center',
        alignItems: 'center',
        '& > :not(:last-child)': {
            marginTop: '20px',
        },
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            '& > *': {
                verticalAlign: 'top',
            },
            '& > :not(:last-child)': {
                marginRight: theme.spacing(2),
            },
        },
        [theme.breakpoints.down('md')]: {
            //Use '&&' to increase specifity.
            //Refer: https://stackoverflow.com/questions/62660480/is-there-a-way-to-increase-specificity-by-adding-the-element-with-emotion
            '&& *': {
                textAlign: 'center',
                justifyContent: 'center',
            },
        },
    });

export const StyledSiteInfoContainer = (theme: Theme) =>
    css({
        '& *': {
            justifyContent: 'center',
        },
        [theme.breakpoints.up('md')]: {
            paddingRight: '16px',
            borderRight: '1px solid #ddd',
        },
    });

export const StyledSocialMediaContainer = (theme: Theme) =>
    css({
        'a .MuiSvgIcon-root': {
            height: '2em',
            width: '2em',
        },
    });

export const StyledLogoText = (theme: Theme) =>
    css(ContentAlignCenter(theme), {
        marginBottom: theme.spacing(2),
    });

export const StyledAppLink = (theme: Theme) =>
    css({
        marginRight: theme.spacing(1),
        display: 'block',
    });
