// import AbortController from 'abort-controller';

/**
 * Custom function.
 *
 * Use it to make json request. Returns a promise.
 */
export default function fetchImpl<JSON = any>(input: RequestInfo, init?: RequestInit): Promise<JSON> {
    // eslint-disable-next-line no-async-promise-executor
    const promise = new Promise<JSON>(async (resolve, reject) => {
        try {
            const res = await fetch(input, init);

            if (res.status === 404) {
                resolve(null!);

                return;
            }

            if (!res.ok && res.status !== 400) {
                throw new HttpError(`Received status code: ${res.status}`, res);
            }

            const result = await res.json();

            resolve(result);
        } catch (e) {
            reject(e);
        }
    });

    // (promise as any).cancel = () => controller.abort();

    return promise;
}

/**
 * Custom class.
 *
 * It extends the error object.
 */
export class HttpError extends Error {
    constructor(message: string, public response: Response) {
        super(message);
    }
}
