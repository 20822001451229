// source: Protos/users.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var Protos_vips_pb = require('../Protos/vips_pb.js');
goog.object.extend(proto, Protos_vips_pb);
goog.exportSymbol('proto.wuxiaworld.api.v2.DeleteAccountRequest', null, global);
goog.exportSymbol('proto.wuxiaworld.api.v2.DeleteAccountResponse', null, global);
goog.exportSymbol('proto.wuxiaworld.api.v2.GetMyUserResponse', null, global);
goog.exportSymbol('proto.wuxiaworld.api.v2.SetAvatarChunkedRequest', null, global);
goog.exportSymbol('proto.wuxiaworld.api.v2.SetAvatarRequest', null, global);
goog.exportSymbol('proto.wuxiaworld.api.v2.UserItem', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wuxiaworld.api.v2.GetMyUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wuxiaworld.api.v2.GetMyUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wuxiaworld.api.v2.GetMyUserResponse.displayName = 'proto.wuxiaworld.api.v2.GetMyUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wuxiaworld.api.v2.SetAvatarRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wuxiaworld.api.v2.SetAvatarRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wuxiaworld.api.v2.SetAvatarRequest.displayName = 'proto.wuxiaworld.api.v2.SetAvatarRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wuxiaworld.api.v2.SetAvatarChunkedRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wuxiaworld.api.v2.SetAvatarChunkedRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wuxiaworld.api.v2.SetAvatarChunkedRequest.displayName = 'proto.wuxiaworld.api.v2.SetAvatarChunkedRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wuxiaworld.api.v2.DeleteAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wuxiaworld.api.v2.DeleteAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wuxiaworld.api.v2.DeleteAccountRequest.displayName = 'proto.wuxiaworld.api.v2.DeleteAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wuxiaworld.api.v2.DeleteAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wuxiaworld.api.v2.DeleteAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wuxiaworld.api.v2.DeleteAccountResponse.displayName = 'proto.wuxiaworld.api.v2.DeleteAccountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wuxiaworld.api.v2.UserItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wuxiaworld.api.v2.UserItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wuxiaworld.api.v2.UserItem.displayName = 'proto.wuxiaworld.api.v2.UserItem';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wuxiaworld.api.v2.GetMyUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wuxiaworld.api.v2.GetMyUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wuxiaworld.api.v2.GetMyUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.GetMyUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    item: (f = msg.getItem()) && proto.wuxiaworld.api.v2.UserItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wuxiaworld.api.v2.GetMyUserResponse}
 */
proto.wuxiaworld.api.v2.GetMyUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wuxiaworld.api.v2.GetMyUserResponse;
  return proto.wuxiaworld.api.v2.GetMyUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wuxiaworld.api.v2.GetMyUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wuxiaworld.api.v2.GetMyUserResponse}
 */
proto.wuxiaworld.api.v2.GetMyUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.wuxiaworld.api.v2.UserItem;
      reader.readMessage(value,proto.wuxiaworld.api.v2.UserItem.deserializeBinaryFromReader);
      msg.setItem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wuxiaworld.api.v2.GetMyUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wuxiaworld.api.v2.GetMyUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wuxiaworld.api.v2.GetMyUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.GetMyUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItem();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.wuxiaworld.api.v2.UserItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserItem item = 1;
 * @return {?proto.wuxiaworld.api.v2.UserItem}
 */
proto.wuxiaworld.api.v2.GetMyUserResponse.prototype.getItem = function() {
  return /** @type{?proto.wuxiaworld.api.v2.UserItem} */ (
    jspb.Message.getWrapperField(this, proto.wuxiaworld.api.v2.UserItem, 1));
};


/**
 * @param {?proto.wuxiaworld.api.v2.UserItem|undefined} value
 * @return {!proto.wuxiaworld.api.v2.GetMyUserResponse} returns this
*/
proto.wuxiaworld.api.v2.GetMyUserResponse.prototype.setItem = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.GetMyUserResponse} returns this
 */
proto.wuxiaworld.api.v2.GetMyUserResponse.prototype.clearItem = function() {
  return this.setItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.GetMyUserResponse.prototype.hasItem = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wuxiaworld.api.v2.SetAvatarRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wuxiaworld.api.v2.SetAvatarRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wuxiaworld.api.v2.SetAvatarRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.SetAvatarRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    image: msg.getImage_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wuxiaworld.api.v2.SetAvatarRequest}
 */
proto.wuxiaworld.api.v2.SetAvatarRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wuxiaworld.api.v2.SetAvatarRequest;
  return proto.wuxiaworld.api.v2.SetAvatarRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wuxiaworld.api.v2.SetAvatarRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wuxiaworld.api.v2.SetAvatarRequest}
 */
proto.wuxiaworld.api.v2.SetAvatarRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setImage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wuxiaworld.api.v2.SetAvatarRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wuxiaworld.api.v2.SetAvatarRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wuxiaworld.api.v2.SetAvatarRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.SetAvatarRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getImage_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
};


/**
 * optional bytes image = 1;
 * @return {!(string|Uint8Array)}
 */
proto.wuxiaworld.api.v2.SetAvatarRequest.prototype.getImage = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes image = 1;
 * This is a type-conversion wrapper around `getImage()`
 * @return {string}
 */
proto.wuxiaworld.api.v2.SetAvatarRequest.prototype.getImage_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getImage()));
};


/**
 * optional bytes image = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getImage()`
 * @return {!Uint8Array}
 */
proto.wuxiaworld.api.v2.SetAvatarRequest.prototype.getImage_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getImage()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.wuxiaworld.api.v2.SetAvatarRequest} returns this
 */
proto.wuxiaworld.api.v2.SetAvatarRequest.prototype.setImage = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wuxiaworld.api.v2.SetAvatarChunkedRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wuxiaworld.api.v2.SetAvatarChunkedRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wuxiaworld.api.v2.SetAvatarChunkedRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.SetAvatarChunkedRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    chunk: msg.getChunk_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wuxiaworld.api.v2.SetAvatarChunkedRequest}
 */
proto.wuxiaworld.api.v2.SetAvatarChunkedRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wuxiaworld.api.v2.SetAvatarChunkedRequest;
  return proto.wuxiaworld.api.v2.SetAvatarChunkedRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wuxiaworld.api.v2.SetAvatarChunkedRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wuxiaworld.api.v2.SetAvatarChunkedRequest}
 */
proto.wuxiaworld.api.v2.SetAvatarChunkedRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setChunk(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wuxiaworld.api.v2.SetAvatarChunkedRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wuxiaworld.api.v2.SetAvatarChunkedRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wuxiaworld.api.v2.SetAvatarChunkedRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.SetAvatarChunkedRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChunk_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional bytes chunk = 2;
 * @return {!(string|Uint8Array)}
 */
proto.wuxiaworld.api.v2.SetAvatarChunkedRequest.prototype.getChunk = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes chunk = 2;
 * This is a type-conversion wrapper around `getChunk()`
 * @return {string}
 */
proto.wuxiaworld.api.v2.SetAvatarChunkedRequest.prototype.getChunk_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getChunk()));
};


/**
 * optional bytes chunk = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getChunk()`
 * @return {!Uint8Array}
 */
proto.wuxiaworld.api.v2.SetAvatarChunkedRequest.prototype.getChunk_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getChunk()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.wuxiaworld.api.v2.SetAvatarChunkedRequest} returns this
 */
proto.wuxiaworld.api.v2.SetAvatarChunkedRequest.prototype.setChunk = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wuxiaworld.api.v2.DeleteAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wuxiaworld.api.v2.DeleteAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wuxiaworld.api.v2.DeleteAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.DeleteAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    confirm: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wuxiaworld.api.v2.DeleteAccountRequest}
 */
proto.wuxiaworld.api.v2.DeleteAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wuxiaworld.api.v2.DeleteAccountRequest;
  return proto.wuxiaworld.api.v2.DeleteAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wuxiaworld.api.v2.DeleteAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wuxiaworld.api.v2.DeleteAccountRequest}
 */
proto.wuxiaworld.api.v2.DeleteAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setConfirm(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wuxiaworld.api.v2.DeleteAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wuxiaworld.api.v2.DeleteAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wuxiaworld.api.v2.DeleteAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.DeleteAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConfirm();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool confirm = 1;
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.DeleteAccountRequest.prototype.getConfirm = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wuxiaworld.api.v2.DeleteAccountRequest} returns this
 */
proto.wuxiaworld.api.v2.DeleteAccountRequest.prototype.setConfirm = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wuxiaworld.api.v2.DeleteAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wuxiaworld.api.v2.DeleteAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wuxiaworld.api.v2.DeleteAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.DeleteAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wuxiaworld.api.v2.DeleteAccountResponse}
 */
proto.wuxiaworld.api.v2.DeleteAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wuxiaworld.api.v2.DeleteAccountResponse;
  return proto.wuxiaworld.api.v2.DeleteAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wuxiaworld.api.v2.DeleteAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wuxiaworld.api.v2.DeleteAccountResponse}
 */
proto.wuxiaworld.api.v2.DeleteAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wuxiaworld.api.v2.DeleteAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wuxiaworld.api.v2.DeleteAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wuxiaworld.api.v2.DeleteAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.DeleteAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool result = 1;
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.DeleteAccountResponse.prototype.getResult = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wuxiaworld.api.v2.DeleteAccountResponse} returns this
 */
proto.wuxiaworld.api.v2.DeleteAccountResponse.prototype.setResult = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wuxiaworld.api.v2.UserItem.prototype.toObject = function(opt_includeInstance) {
  return proto.wuxiaworld.api.v2.UserItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wuxiaworld.api.v2.UserItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.UserItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    username: jspb.Message.getFieldWithDefault(msg, 2, ""),
    avatarurl: (f = msg.getAvatarurl()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    email: (f = msg.getEmail()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    joindate: (f = msg.getJoindate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    isvipactive: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    vip: (f = msg.getVip()) && Protos_vips_pb.VipItem.toObject(includeInstance, f),
    flair: (f = msg.getFlair()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wuxiaworld.api.v2.UserItem}
 */
proto.wuxiaworld.api.v2.UserItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wuxiaworld.api.v2.UserItem;
  return proto.wuxiaworld.api.v2.UserItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wuxiaworld.api.v2.UserItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wuxiaworld.api.v2.UserItem}
 */
proto.wuxiaworld.api.v2.UserItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAvatarurl(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setEmail(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setJoindate(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsvipactive(value);
      break;
    case 7:
      var value = new Protos_vips_pb.VipItem;
      reader.readMessage(value,Protos_vips_pb.VipItem.deserializeBinaryFromReader);
      msg.setVip(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setFlair(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wuxiaworld.api.v2.UserItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wuxiaworld.api.v2.UserItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wuxiaworld.api.v2.UserItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.UserItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAvatarurl();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getEmail();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getJoindate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getIsvipactive();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getVip();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      Protos_vips_pb.VipItem.serializeBinaryToWriter
    );
  }
  f = message.getFlair();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wuxiaworld.api.v2.UserItem.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wuxiaworld.api.v2.UserItem} returns this
 */
proto.wuxiaworld.api.v2.UserItem.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string userName = 2;
 * @return {string}
 */
proto.wuxiaworld.api.v2.UserItem.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wuxiaworld.api.v2.UserItem} returns this
 */
proto.wuxiaworld.api.v2.UserItem.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.StringValue avatarUrl = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.wuxiaworld.api.v2.UserItem.prototype.getAvatarurl = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.wuxiaworld.api.v2.UserItem} returns this
*/
proto.wuxiaworld.api.v2.UserItem.prototype.setAvatarurl = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.UserItem} returns this
 */
proto.wuxiaworld.api.v2.UserItem.prototype.clearAvatarurl = function() {
  return this.setAvatarurl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.UserItem.prototype.hasAvatarurl = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue email = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.wuxiaworld.api.v2.UserItem.prototype.getEmail = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.wuxiaworld.api.v2.UserItem} returns this
*/
proto.wuxiaworld.api.v2.UserItem.prototype.setEmail = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.UserItem} returns this
 */
proto.wuxiaworld.api.v2.UserItem.prototype.clearEmail = function() {
  return this.setEmail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.UserItem.prototype.hasEmail = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp joinDate = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wuxiaworld.api.v2.UserItem.prototype.getJoindate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wuxiaworld.api.v2.UserItem} returns this
*/
proto.wuxiaworld.api.v2.UserItem.prototype.setJoindate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.UserItem} returns this
 */
proto.wuxiaworld.api.v2.UserItem.prototype.clearJoindate = function() {
  return this.setJoindate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.UserItem.prototype.hasJoindate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool isVipActive = 6;
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.UserItem.prototype.getIsvipactive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wuxiaworld.api.v2.UserItem} returns this
 */
proto.wuxiaworld.api.v2.UserItem.prototype.setIsvipactive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional VipItem vip = 7;
 * @return {?proto.wuxiaworld.api.v2.VipItem}
 */
proto.wuxiaworld.api.v2.UserItem.prototype.getVip = function() {
  return /** @type{?proto.wuxiaworld.api.v2.VipItem} */ (
    jspb.Message.getWrapperField(this, Protos_vips_pb.VipItem, 7));
};


/**
 * @param {?proto.wuxiaworld.api.v2.VipItem|undefined} value
 * @return {!proto.wuxiaworld.api.v2.UserItem} returns this
*/
proto.wuxiaworld.api.v2.UserItem.prototype.setVip = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.UserItem} returns this
 */
proto.wuxiaworld.api.v2.UserItem.prototype.clearVip = function() {
  return this.setVip(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.UserItem.prototype.hasVip = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.StringValue flair = 8;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.wuxiaworld.api.v2.UserItem.prototype.getFlair = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 8));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.wuxiaworld.api.v2.UserItem} returns this
*/
proto.wuxiaworld.api.v2.UserItem.prototype.setFlair = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.UserItem} returns this
 */
proto.wuxiaworld.api.v2.UserItem.prototype.clearFlair = function() {
  return this.setFlair(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.UserItem.prototype.hasFlair = function() {
  return jspb.Message.getField(this, 8) != null;
};


goog.object.extend(exports, proto.wuxiaworld.api.v2);
