// source: Protos/products.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var Protos_types_pb = require('../Protos/types_pb.js');
goog.object.extend(proto, Protos_types_pb);
var Protos_payments_pb = require('../Protos/payments_pb.js');
goog.object.extend(proto, Protos_payments_pb);
goog.exportSymbol('proto.wuxiaworld.api.v2.GetProductsRequest', null, global);
goog.exportSymbol('proto.wuxiaworld.api.v2.GetProductsResponse', null, global);
goog.exportSymbol('proto.wuxiaworld.api.v2.PlatformProductId', null, global);
goog.exportSymbol('proto.wuxiaworld.api.v2.ProductItem', null, global);
goog.exportSymbol('proto.wuxiaworld.api.v2.ProductItem.ProductReviewInfo', null, global);
goog.exportSymbol('proto.wuxiaworld.api.v2.ProductPlatform', null, global);
goog.exportSymbol('proto.wuxiaworld.api.v2.ProductType', null, global);
goog.exportSymbol('proto.wuxiaworld.api.v2.PurchaseProductRequest', null, global);
goog.exportSymbol('proto.wuxiaworld.api.v2.PurchaseProductRequest.PaymentmethodCase', null, global);
goog.exportSymbol('proto.wuxiaworld.api.v2.PurchaseProductResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wuxiaworld.api.v2.GetProductsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wuxiaworld.api.v2.GetProductsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wuxiaworld.api.v2.GetProductsRequest.displayName = 'proto.wuxiaworld.api.v2.GetProductsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wuxiaworld.api.v2.GetProductsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wuxiaworld.api.v2.GetProductsResponse.repeatedFields_, null);
};
goog.inherits(proto.wuxiaworld.api.v2.GetProductsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wuxiaworld.api.v2.GetProductsResponse.displayName = 'proto.wuxiaworld.api.v2.GetProductsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wuxiaworld.api.v2.PurchaseProductRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.wuxiaworld.api.v2.PurchaseProductRequest.oneofGroups_);
};
goog.inherits(proto.wuxiaworld.api.v2.PurchaseProductRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wuxiaworld.api.v2.PurchaseProductRequest.displayName = 'proto.wuxiaworld.api.v2.PurchaseProductRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wuxiaworld.api.v2.PurchaseProductResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wuxiaworld.api.v2.PurchaseProductResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wuxiaworld.api.v2.PurchaseProductResponse.displayName = 'proto.wuxiaworld.api.v2.PurchaseProductResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wuxiaworld.api.v2.ProductItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wuxiaworld.api.v2.ProductItem.repeatedFields_, null);
};
goog.inherits(proto.wuxiaworld.api.v2.ProductItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wuxiaworld.api.v2.ProductItem.displayName = 'proto.wuxiaworld.api.v2.ProductItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wuxiaworld.api.v2.ProductItem.ProductReviewInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wuxiaworld.api.v2.ProductItem.ProductReviewInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wuxiaworld.api.v2.ProductItem.ProductReviewInfo.displayName = 'proto.wuxiaworld.api.v2.ProductItem.ProductReviewInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wuxiaworld.api.v2.PlatformProductId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wuxiaworld.api.v2.PlatformProductId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wuxiaworld.api.v2.PlatformProductId.displayName = 'proto.wuxiaworld.api.v2.PlatformProductId';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wuxiaworld.api.v2.GetProductsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wuxiaworld.api.v2.GetProductsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wuxiaworld.api.v2.GetProductsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.GetProductsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wuxiaworld.api.v2.GetProductsRequest}
 */
proto.wuxiaworld.api.v2.GetProductsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wuxiaworld.api.v2.GetProductsRequest;
  return proto.wuxiaworld.api.v2.GetProductsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wuxiaworld.api.v2.GetProductsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wuxiaworld.api.v2.GetProductsRequest}
 */
proto.wuxiaworld.api.v2.GetProductsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.wuxiaworld.api.v2.ProductType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wuxiaworld.api.v2.GetProductsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wuxiaworld.api.v2.GetProductsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wuxiaworld.api.v2.GetProductsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.GetProductsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional ProductType type = 1;
 * @return {!proto.wuxiaworld.api.v2.ProductType}
 */
proto.wuxiaworld.api.v2.GetProductsRequest.prototype.getType = function() {
  return /** @type {!proto.wuxiaworld.api.v2.ProductType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.wuxiaworld.api.v2.ProductType} value
 * @return {!proto.wuxiaworld.api.v2.GetProductsRequest} returns this
 */
proto.wuxiaworld.api.v2.GetProductsRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wuxiaworld.api.v2.GetProductsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wuxiaworld.api.v2.GetProductsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wuxiaworld.api.v2.GetProductsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wuxiaworld.api.v2.GetProductsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.GetProductsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.wuxiaworld.api.v2.ProductItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wuxiaworld.api.v2.GetProductsResponse}
 */
proto.wuxiaworld.api.v2.GetProductsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wuxiaworld.api.v2.GetProductsResponse;
  return proto.wuxiaworld.api.v2.GetProductsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wuxiaworld.api.v2.GetProductsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wuxiaworld.api.v2.GetProductsResponse}
 */
proto.wuxiaworld.api.v2.GetProductsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.wuxiaworld.api.v2.ProductItem;
      reader.readMessage(value,proto.wuxiaworld.api.v2.ProductItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wuxiaworld.api.v2.GetProductsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wuxiaworld.api.v2.GetProductsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wuxiaworld.api.v2.GetProductsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.GetProductsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.wuxiaworld.api.v2.ProductItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ProductItem items = 1;
 * @return {!Array<!proto.wuxiaworld.api.v2.ProductItem>}
 */
proto.wuxiaworld.api.v2.GetProductsResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.wuxiaworld.api.v2.ProductItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wuxiaworld.api.v2.ProductItem, 1));
};


/**
 * @param {!Array<!proto.wuxiaworld.api.v2.ProductItem>} value
 * @return {!proto.wuxiaworld.api.v2.GetProductsResponse} returns this
*/
proto.wuxiaworld.api.v2.GetProductsResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.wuxiaworld.api.v2.ProductItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wuxiaworld.api.v2.ProductItem}
 */
proto.wuxiaworld.api.v2.GetProductsResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.wuxiaworld.api.v2.ProductItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wuxiaworld.api.v2.GetProductsResponse} returns this
 */
proto.wuxiaworld.api.v2.GetProductsResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.wuxiaworld.api.v2.PurchaseProductRequest.oneofGroups_ = [[3,4]];

/**
 * @enum {number}
 */
proto.wuxiaworld.api.v2.PurchaseProductRequest.PaymentmethodCase = {
  PAYMENTMETHOD_NOT_SET: 0,
  PAYMENTMETHODID: 3,
  PAYMENTTOKEN: 4
};

/**
 * @return {proto.wuxiaworld.api.v2.PurchaseProductRequest.PaymentmethodCase}
 */
proto.wuxiaworld.api.v2.PurchaseProductRequest.prototype.getPaymentmethodCase = function() {
  return /** @type {proto.wuxiaworld.api.v2.PurchaseProductRequest.PaymentmethodCase} */(jspb.Message.computeOneofCase(this, proto.wuxiaworld.api.v2.PurchaseProductRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wuxiaworld.api.v2.PurchaseProductRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wuxiaworld.api.v2.PurchaseProductRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wuxiaworld.api.v2.PurchaseProductRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.PurchaseProductRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    productid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    paymentgateway: jspb.Message.getFieldWithDefault(msg, 2, 0),
    paymentmethodid: (f = msg.getPaymentmethodid()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    paymenttoken: (f = msg.getPaymenttoken()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    confirmprice: (f = msg.getConfirmprice()) && Protos_types_pb.DecimalValue.toObject(includeInstance, f),
    couponid: (f = msg.getCouponid()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    platform: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wuxiaworld.api.v2.PurchaseProductRequest}
 */
proto.wuxiaworld.api.v2.PurchaseProductRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wuxiaworld.api.v2.PurchaseProductRequest;
  return proto.wuxiaworld.api.v2.PurchaseProductRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wuxiaworld.api.v2.PurchaseProductRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wuxiaworld.api.v2.PurchaseProductRequest}
 */
proto.wuxiaworld.api.v2.PurchaseProductRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProductid(value);
      break;
    case 2:
      var value = /** @type {!proto.wuxiaworld.api.v2.PaymentMethodGateway} */ (reader.readEnum());
      msg.setPaymentgateway(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPaymentmethodid(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPaymenttoken(value);
      break;
    case 5:
      var value = new Protos_types_pb.DecimalValue;
      reader.readMessage(value,Protos_types_pb.DecimalValue.deserializeBinaryFromReader);
      msg.setConfirmprice(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCouponid(value);
      break;
    case 7:
      var value = /** @type {!proto.wuxiaworld.api.v2.ProductPlatform} */ (reader.readEnum());
      msg.setPlatform(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wuxiaworld.api.v2.PurchaseProductRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wuxiaworld.api.v2.PurchaseProductRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wuxiaworld.api.v2.PurchaseProductRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.PurchaseProductRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPaymentgateway();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getPaymentmethodid();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPaymenttoken();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getConfirmprice();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Protos_types_pb.DecimalValue.serializeBinaryToWriter
    );
  }
  f = message.getCouponid();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPlatform();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
};


/**
 * optional int32 productId = 1;
 * @return {number}
 */
proto.wuxiaworld.api.v2.PurchaseProductRequest.prototype.getProductid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wuxiaworld.api.v2.PurchaseProductRequest} returns this
 */
proto.wuxiaworld.api.v2.PurchaseProductRequest.prototype.setProductid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional PaymentMethodGateway paymentGateway = 2;
 * @return {!proto.wuxiaworld.api.v2.PaymentMethodGateway}
 */
proto.wuxiaworld.api.v2.PurchaseProductRequest.prototype.getPaymentgateway = function() {
  return /** @type {!proto.wuxiaworld.api.v2.PaymentMethodGateway} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.wuxiaworld.api.v2.PaymentMethodGateway} value
 * @return {!proto.wuxiaworld.api.v2.PurchaseProductRequest} returns this
 */
proto.wuxiaworld.api.v2.PurchaseProductRequest.prototype.setPaymentgateway = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional google.protobuf.StringValue paymentMethodId = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.wuxiaworld.api.v2.PurchaseProductRequest.prototype.getPaymentmethodid = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.wuxiaworld.api.v2.PurchaseProductRequest} returns this
*/
proto.wuxiaworld.api.v2.PurchaseProductRequest.prototype.setPaymentmethodid = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.wuxiaworld.api.v2.PurchaseProductRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.PurchaseProductRequest} returns this
 */
proto.wuxiaworld.api.v2.PurchaseProductRequest.prototype.clearPaymentmethodid = function() {
  return this.setPaymentmethodid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.PurchaseProductRequest.prototype.hasPaymentmethodid = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue paymentToken = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.wuxiaworld.api.v2.PurchaseProductRequest.prototype.getPaymenttoken = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.wuxiaworld.api.v2.PurchaseProductRequest} returns this
*/
proto.wuxiaworld.api.v2.PurchaseProductRequest.prototype.setPaymenttoken = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.wuxiaworld.api.v2.PurchaseProductRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.PurchaseProductRequest} returns this
 */
proto.wuxiaworld.api.v2.PurchaseProductRequest.prototype.clearPaymenttoken = function() {
  return this.setPaymenttoken(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.PurchaseProductRequest.prototype.hasPaymenttoken = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional customTypes.DecimalValue confirmPrice = 5;
 * @return {?proto.customTypes.DecimalValue}
 */
proto.wuxiaworld.api.v2.PurchaseProductRequest.prototype.getConfirmprice = function() {
  return /** @type{?proto.customTypes.DecimalValue} */ (
    jspb.Message.getWrapperField(this, Protos_types_pb.DecimalValue, 5));
};


/**
 * @param {?proto.customTypes.DecimalValue|undefined} value
 * @return {!proto.wuxiaworld.api.v2.PurchaseProductRequest} returns this
*/
proto.wuxiaworld.api.v2.PurchaseProductRequest.prototype.setConfirmprice = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.PurchaseProductRequest} returns this
 */
proto.wuxiaworld.api.v2.PurchaseProductRequest.prototype.clearConfirmprice = function() {
  return this.setConfirmprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.PurchaseProductRequest.prototype.hasConfirmprice = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue couponId = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.wuxiaworld.api.v2.PurchaseProductRequest.prototype.getCouponid = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.wuxiaworld.api.v2.PurchaseProductRequest} returns this
*/
proto.wuxiaworld.api.v2.PurchaseProductRequest.prototype.setCouponid = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.PurchaseProductRequest} returns this
 */
proto.wuxiaworld.api.v2.PurchaseProductRequest.prototype.clearCouponid = function() {
  return this.setCouponid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.PurchaseProductRequest.prototype.hasCouponid = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional ProductPlatform platform = 7;
 * @return {!proto.wuxiaworld.api.v2.ProductPlatform}
 */
proto.wuxiaworld.api.v2.PurchaseProductRequest.prototype.getPlatform = function() {
  return /** @type {!proto.wuxiaworld.api.v2.ProductPlatform} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.wuxiaworld.api.v2.ProductPlatform} value
 * @return {!proto.wuxiaworld.api.v2.PurchaseProductRequest} returns this
 */
proto.wuxiaworld.api.v2.PurchaseProductRequest.prototype.setPlatform = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wuxiaworld.api.v2.PurchaseProductResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wuxiaworld.api.v2.PurchaseProductResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wuxiaworld.api.v2.PurchaseProductResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.PurchaseProductResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    token: (f = msg.getToken()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wuxiaworld.api.v2.PurchaseProductResponse}
 */
proto.wuxiaworld.api.v2.PurchaseProductResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wuxiaworld.api.v2.PurchaseProductResponse;
  return proto.wuxiaworld.api.v2.PurchaseProductResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wuxiaworld.api.v2.PurchaseProductResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wuxiaworld.api.v2.PurchaseProductResponse}
 */
proto.wuxiaworld.api.v2.PurchaseProductResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setResult(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wuxiaworld.api.v2.PurchaseProductResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wuxiaworld.api.v2.PurchaseProductResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wuxiaworld.api.v2.PurchaseProductResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.PurchaseProductResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getToken();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool result = 1;
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.PurchaseProductResponse.prototype.getResult = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wuxiaworld.api.v2.PurchaseProductResponse} returns this
 */
proto.wuxiaworld.api.v2.PurchaseProductResponse.prototype.setResult = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue token = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.wuxiaworld.api.v2.PurchaseProductResponse.prototype.getToken = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.wuxiaworld.api.v2.PurchaseProductResponse} returns this
*/
proto.wuxiaworld.api.v2.PurchaseProductResponse.prototype.setToken = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.PurchaseProductResponse} returns this
 */
proto.wuxiaworld.api.v2.PurchaseProductResponse.prototype.clearToken = function() {
  return this.setToken(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.PurchaseProductResponse.prototype.hasToken = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wuxiaworld.api.v2.ProductItem.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wuxiaworld.api.v2.ProductItem.prototype.toObject = function(opt_includeInstance) {
  return proto.wuxiaworld.api.v2.ProductItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wuxiaworld.api.v2.ProductItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.ProductItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    price: (f = msg.getPrice()) && Protos_types_pb.DecimalValue.toObject(includeInstance, f),
    quantity: jspb.Message.getFieldWithDefault(msg, 4, 0),
    description: (f = msg.getDescription()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    enabled: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    type: jspb.Message.getFieldWithDefault(msg, 7, 0),
    issubscription: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    productidsList: jspb.Message.toObjectList(msg.getProductidsList(),
    proto.wuxiaworld.api.v2.PlatformProductId.toObject, includeInstance),
    reviewinfo: (f = msg.getReviewinfo()) && proto.wuxiaworld.api.v2.ProductItem.ProductReviewInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wuxiaworld.api.v2.ProductItem}
 */
proto.wuxiaworld.api.v2.ProductItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wuxiaworld.api.v2.ProductItem;
  return proto.wuxiaworld.api.v2.ProductItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wuxiaworld.api.v2.ProductItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wuxiaworld.api.v2.ProductItem}
 */
proto.wuxiaworld.api.v2.ProductItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new Protos_types_pb.DecimalValue;
      reader.readMessage(value,Protos_types_pb.DecimalValue.deserializeBinaryFromReader);
      msg.setPrice(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuantity(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    case 7:
      var value = /** @type {!proto.wuxiaworld.api.v2.ProductType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIssubscription(value);
      break;
    case 9:
      var value = new proto.wuxiaworld.api.v2.PlatformProductId;
      reader.readMessage(value,proto.wuxiaworld.api.v2.PlatformProductId.deserializeBinaryFromReader);
      msg.addProductids(value);
      break;
    case 10:
      var value = new proto.wuxiaworld.api.v2.ProductItem.ProductReviewInfo;
      reader.readMessage(value,proto.wuxiaworld.api.v2.ProductItem.ProductReviewInfo.deserializeBinaryFromReader);
      msg.setReviewinfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wuxiaworld.api.v2.ProductItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wuxiaworld.api.v2.ProductItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wuxiaworld.api.v2.ProductItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.ProductItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPrice();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Protos_types_pb.DecimalValue.serializeBinaryToWriter
    );
  }
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getEnabled();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getIssubscription();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getProductidsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.wuxiaworld.api.v2.PlatformProductId.serializeBinaryToWriter
    );
  }
  f = message.getReviewinfo();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.wuxiaworld.api.v2.ProductItem.ProductReviewInfo.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wuxiaworld.api.v2.ProductItem.ProductReviewInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.wuxiaworld.api.v2.ProductItem.ProductReviewInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wuxiaworld.api.v2.ProductItem.ProductReviewInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.ProductItem.ProductReviewInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    count: jspb.Message.getFieldWithDefault(msg, 1, 0),
    rating: (f = msg.getRating()) && google_protobuf_wrappers_pb.DoubleValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wuxiaworld.api.v2.ProductItem.ProductReviewInfo}
 */
proto.wuxiaworld.api.v2.ProductItem.ProductReviewInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wuxiaworld.api.v2.ProductItem.ProductReviewInfo;
  return proto.wuxiaworld.api.v2.ProductItem.ProductReviewInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wuxiaworld.api.v2.ProductItem.ProductReviewInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wuxiaworld.api.v2.ProductItem.ProductReviewInfo}
 */
proto.wuxiaworld.api.v2.ProductItem.ProductReviewInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.DoubleValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.DoubleValue.deserializeBinaryFromReader);
      msg.setRating(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wuxiaworld.api.v2.ProductItem.ProductReviewInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wuxiaworld.api.v2.ProductItem.ProductReviewInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wuxiaworld.api.v2.ProductItem.ProductReviewInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.ProductItem.ProductReviewInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getRating();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.DoubleValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 count = 1;
 * @return {number}
 */
proto.wuxiaworld.api.v2.ProductItem.ProductReviewInfo.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wuxiaworld.api.v2.ProductItem.ProductReviewInfo} returns this
 */
proto.wuxiaworld.api.v2.ProductItem.ProductReviewInfo.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.DoubleValue rating = 2;
 * @return {?proto.google.protobuf.DoubleValue}
 */
proto.wuxiaworld.api.v2.ProductItem.ProductReviewInfo.prototype.getRating = function() {
  return /** @type{?proto.google.protobuf.DoubleValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.DoubleValue, 2));
};


/**
 * @param {?proto.google.protobuf.DoubleValue|undefined} value
 * @return {!proto.wuxiaworld.api.v2.ProductItem.ProductReviewInfo} returns this
*/
proto.wuxiaworld.api.v2.ProductItem.ProductReviewInfo.prototype.setRating = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.ProductItem.ProductReviewInfo} returns this
 */
proto.wuxiaworld.api.v2.ProductItem.ProductReviewInfo.prototype.clearRating = function() {
  return this.setRating(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.ProductItem.ProductReviewInfo.prototype.hasRating = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.wuxiaworld.api.v2.ProductItem.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wuxiaworld.api.v2.ProductItem} returns this
 */
proto.wuxiaworld.api.v2.ProductItem.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.wuxiaworld.api.v2.ProductItem.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wuxiaworld.api.v2.ProductItem} returns this
 */
proto.wuxiaworld.api.v2.ProductItem.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional customTypes.DecimalValue price = 3;
 * @return {?proto.customTypes.DecimalValue}
 */
proto.wuxiaworld.api.v2.ProductItem.prototype.getPrice = function() {
  return /** @type{?proto.customTypes.DecimalValue} */ (
    jspb.Message.getWrapperField(this, Protos_types_pb.DecimalValue, 3));
};


/**
 * @param {?proto.customTypes.DecimalValue|undefined} value
 * @return {!proto.wuxiaworld.api.v2.ProductItem} returns this
*/
proto.wuxiaworld.api.v2.ProductItem.prototype.setPrice = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.ProductItem} returns this
 */
proto.wuxiaworld.api.v2.ProductItem.prototype.clearPrice = function() {
  return this.setPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.ProductItem.prototype.hasPrice = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 quantity = 4;
 * @return {number}
 */
proto.wuxiaworld.api.v2.ProductItem.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.wuxiaworld.api.v2.ProductItem} returns this
 */
proto.wuxiaworld.api.v2.ProductItem.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional google.protobuf.StringValue description = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.wuxiaworld.api.v2.ProductItem.prototype.getDescription = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.wuxiaworld.api.v2.ProductItem} returns this
*/
proto.wuxiaworld.api.v2.ProductItem.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.ProductItem} returns this
 */
proto.wuxiaworld.api.v2.ProductItem.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.ProductItem.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool enabled = 6;
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.ProductItem.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wuxiaworld.api.v2.ProductItem} returns this
 */
proto.wuxiaworld.api.v2.ProductItem.prototype.setEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional ProductType type = 7;
 * @return {!proto.wuxiaworld.api.v2.ProductType}
 */
proto.wuxiaworld.api.v2.ProductItem.prototype.getType = function() {
  return /** @type {!proto.wuxiaworld.api.v2.ProductType} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.wuxiaworld.api.v2.ProductType} value
 * @return {!proto.wuxiaworld.api.v2.ProductItem} returns this
 */
proto.wuxiaworld.api.v2.ProductItem.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional bool isSubscription = 8;
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.ProductItem.prototype.getIssubscription = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wuxiaworld.api.v2.ProductItem} returns this
 */
proto.wuxiaworld.api.v2.ProductItem.prototype.setIssubscription = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * repeated PlatformProductId productIds = 9;
 * @return {!Array<!proto.wuxiaworld.api.v2.PlatformProductId>}
 */
proto.wuxiaworld.api.v2.ProductItem.prototype.getProductidsList = function() {
  return /** @type{!Array<!proto.wuxiaworld.api.v2.PlatformProductId>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wuxiaworld.api.v2.PlatformProductId, 9));
};


/**
 * @param {!Array<!proto.wuxiaworld.api.v2.PlatformProductId>} value
 * @return {!proto.wuxiaworld.api.v2.ProductItem} returns this
*/
proto.wuxiaworld.api.v2.ProductItem.prototype.setProductidsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.wuxiaworld.api.v2.PlatformProductId=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wuxiaworld.api.v2.PlatformProductId}
 */
proto.wuxiaworld.api.v2.ProductItem.prototype.addProductids = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.wuxiaworld.api.v2.PlatformProductId, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wuxiaworld.api.v2.ProductItem} returns this
 */
proto.wuxiaworld.api.v2.ProductItem.prototype.clearProductidsList = function() {
  return this.setProductidsList([]);
};


/**
 * optional ProductReviewInfo reviewInfo = 10;
 * @return {?proto.wuxiaworld.api.v2.ProductItem.ProductReviewInfo}
 */
proto.wuxiaworld.api.v2.ProductItem.prototype.getReviewinfo = function() {
  return /** @type{?proto.wuxiaworld.api.v2.ProductItem.ProductReviewInfo} */ (
    jspb.Message.getWrapperField(this, proto.wuxiaworld.api.v2.ProductItem.ProductReviewInfo, 10));
};


/**
 * @param {?proto.wuxiaworld.api.v2.ProductItem.ProductReviewInfo|undefined} value
 * @return {!proto.wuxiaworld.api.v2.ProductItem} returns this
*/
proto.wuxiaworld.api.v2.ProductItem.prototype.setReviewinfo = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wuxiaworld.api.v2.ProductItem} returns this
 */
proto.wuxiaworld.api.v2.ProductItem.prototype.clearReviewinfo = function() {
  return this.setReviewinfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wuxiaworld.api.v2.ProductItem.prototype.hasReviewinfo = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wuxiaworld.api.v2.PlatformProductId.prototype.toObject = function(opt_includeInstance) {
  return proto.wuxiaworld.api.v2.PlatformProductId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wuxiaworld.api.v2.PlatformProductId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.PlatformProductId.toObject = function(includeInstance, msg) {
  var f, obj = {
    platform: jspb.Message.getFieldWithDefault(msg, 1, 0),
    productid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wuxiaworld.api.v2.PlatformProductId}
 */
proto.wuxiaworld.api.v2.PlatformProductId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wuxiaworld.api.v2.PlatformProductId;
  return proto.wuxiaworld.api.v2.PlatformProductId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wuxiaworld.api.v2.PlatformProductId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wuxiaworld.api.v2.PlatformProductId}
 */
proto.wuxiaworld.api.v2.PlatformProductId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.wuxiaworld.api.v2.ProductPlatform} */ (reader.readEnum());
      msg.setPlatform(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wuxiaworld.api.v2.PlatformProductId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wuxiaworld.api.v2.PlatformProductId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wuxiaworld.api.v2.PlatformProductId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wuxiaworld.api.v2.PlatformProductId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlatform();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getProductid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional ProductPlatform platform = 1;
 * @return {!proto.wuxiaworld.api.v2.ProductPlatform}
 */
proto.wuxiaworld.api.v2.PlatformProductId.prototype.getPlatform = function() {
  return /** @type {!proto.wuxiaworld.api.v2.ProductPlatform} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.wuxiaworld.api.v2.ProductPlatform} value
 * @return {!proto.wuxiaworld.api.v2.PlatformProductId} returns this
 */
proto.wuxiaworld.api.v2.PlatformProductId.prototype.setPlatform = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string productId = 2;
 * @return {string}
 */
proto.wuxiaworld.api.v2.PlatformProductId.prototype.getProductid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wuxiaworld.api.v2.PlatformProductId} returns this
 */
proto.wuxiaworld.api.v2.PlatformProductId.prototype.setProductid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.wuxiaworld.api.v2.ProductPlatform = {
  WEBPRODUCT: 0,
  APPLEPRODUCT: 1,
  ANDROIDPRODUCT: 2
};

/**
 * @enum {number}
 */
proto.wuxiaworld.api.v2.ProductType = {
  UNKNOWNPRODUCT: 0,
  KARMAPRODUCT: 1,
  EBOOKPRODUCT: 2,
  VIPPRODUCT: 3,
  AUDIOBOOKPRODUCT: 4
};

goog.object.extend(exports, proto.wuxiaworld.api.v2);
