import { Box, ClickAwayListener, Paper, Popper } from '@material-ui/core';
import * as React from 'react';
import { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import CustomLink from '../link';
import { StyledArrow, StyledNavLink } from './styles';

export type MegaMenuProps = {
    children?: React.ReactNode;
    height?: number;
    width?: number;
    link: {
        href: string;
        label: string;
    };
};

export type MegaMenuContextType = {
    dismissMenu: () => void;
};

const MegaMenuContext = React.createContext<MegaMenuContextType>(null! as MegaMenuContextType);

export const useMegaMenu = () => useContext(MegaMenuContext);

function MegaMenu({ link, height, width, children }: MegaMenuProps) {
    const [menuOpen, setMenuOpen] = useState(false);

    const anchorEl = useRef<HTMLDivElement>(null);
    const linkEl = useRef<HTMLElement>(null);
    const innerRef = useRef<HTMLElement>(null);

    const handleMouseEnter = useCallback(() => {
        if (isMobile) {
            return;
        }

        setMenuOpen(true);
    }, []);

    const handleMouseLeave = useCallback((e: React.MouseEvent) => {
        if (isMobile) {
            return;
        }

        if (e.relatedTarget instanceof HTMLElement) {
            if (
                !innerRef.current?.contains(e.relatedTarget) &&
                !anchorEl.current?.contains(e.relatedTarget) &&
                e.relatedTarget !== linkEl.current
            ) {
                setMenuOpen(false);
            }
        }
    }, []);

    const handleNavClick = useCallback(() => {
        setMenuOpen(false);
    }, []);

    const handleClose = useCallback(() => {
        setMenuOpen(false);
    }, []);

    const megaMenuContext = useMemo<MegaMenuContextType>(
        () => ({
            dismissMenu: handleClose,
        }),
        [handleClose]
    );

    return (
        <MegaMenuContext.Provider value={megaMenuContext}>
            <div ref={anchorEl} />
            <CustomLink
                ref={linkEl}
                onMouseOver={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={handleNavClick}
                css={StyledNavLink}
                href={link.href}
            >
                {link.label}
            </CustomLink>
            <ClickAwayListener onClickAway={handleClose}>
                <Popper
                    open={menuOpen}
                    style={{ zIndex: 9999 }}
                    onMouseLeave={handleMouseLeave}
                    transition
                    anchorEl={{
                        getBoundingClientRect: () => {
                            if (!anchorEl.current) {
                                return {
                                    top: 0,
                                    left: 0,
                                    width: 0,
                                    height: 0,
                                    right: 0,
                                    bottom: 0,
                                };
                            }

                            const rect = anchorEl.current.getBoundingClientRect();

                            return {
                                top: rect.top + 25,
                                left: rect.left + (width || 0) / 2 - 50,
                                right: rect.left + (width || 0) / 2 - 50,
                                bottom: rect.bottom + 25,
                                height: 0,
                                width: 0,
                            };
                        },
                    }}
                    modifiers={[
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 24],
                            },
                        },
                    ]}
                >
                    <Box
                        ref={innerRef}
                        style={{
                            zIndex: 9999,
                            height: `${height}px`,
                            width: `${width}px`,
                        }}
                    >
                        <Box
                            sx={{
                                borderBottom: '1px solid transparent',
                                marginTop: '-32px',
                                marginBottom: '-2px',
                            }}
                        >
                            <div css={StyledArrow} />
                        </Box>
                        <Paper
                            variant="outlined"
                            css={{
                                border: '2px solid #0CA0FF',
                                borderRadius: '15px',
                            }}
                        >
                            <Box overflow="hidden" display="flex">
                                {children}
                            </Box>
                        </Paper>
                    </Box>
                </Popper>
            </ClickAwayListener>
        </MegaMenuContext.Provider>
    );
}

MegaMenu.defaultProps = {
    height: 500,
    width: 800,
};

export default MegaMenu;
