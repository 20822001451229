import { useTheme } from '@emotion/react';
import { Breakpoint, useMediaQuery } from '@material-ui/core';
import * as React from 'react';

export type Breakpoints = {
    [K in `${Breakpoint}Up`]?: boolean;
} &
    {
        [K in `${Breakpoint}Down`]?: boolean;
    };

export type BreakpointResult = {
    type: 'Up' | 'Down';
    value: Breakpoint;
};

export type HiddenProps = Breakpoints & {
    children?: React.ReactNode;
};

const BreakpointRegex = /(?<breakpoint>.+)(?<type>Up|Down)/;

function getBreakpoint(breakpoints: Breakpoints): BreakpointResult | null {
    for (const breakpoint of Object.entries<boolean>(breakpoints).filter(f => f[1])) {
        const key = breakpoint[0];

        const match = key.match(BreakpointRegex);

        if (match?.groups) {
            return {
                type: match.groups['type'] === 'Up' ? 'Up' : 'Down',
                value: match.groups['breakpoint'] as Breakpoint,
            };
        }
    }

    return null;
}

function HiddenComponent(props: HiddenProps) {
    const { children, ...breakpoints } = props;

    const theme = useTheme();
    const breakpoint = getBreakpoint(breakpoints);

    if (!breakpoint) {
        throw new Error('invalid breakpoint');
    }

    const { type, value } = breakpoint;

    const shouldHide = useMediaQuery(type === 'Up' ? theme.breakpoints.up(value) : theme.breakpoints.down(value));

    if (shouldHide) {
        return null;
    }

    return <>{children}</>;
}

export const Hidden = React.memo(HiddenComponent);
