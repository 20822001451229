import {
    Box,
    Grid,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    MenuItemProps,
    MenuList,
    Typography,
} from '@material-ui/core';
import * as React from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMegaMenu } from './mega.menu';
import { StyledListItem, StyledMenuItem, StyledMenuText, StyledNovelsMenuBox } from './styles';

type NovelMenuItemType = 'genres' | 'languages';

const Genres = [
    'Action',
    'Alchemy',
    'Cheat Systems',
    'Comedy',
    'Cooking',
    'Crafting',
    'Cultivation',
    'Esports',
    'Fantasy',
    'Female Protagonist',
    'Harem',
    'Kingdom Building',
    'Mature',
    'Modern Setting',
    'Mystery',
    'Political Intrigue',
    'Post-apocalyptic',
    'Romance',
    'Sci-fi',
    'Superpowers',
    'Thriller',
    'Tragedy',
    'Virtual Reality',
    'Wuxia',
    'Xianxia',
    'Xuanhuan',
];

const Languages = ['Chinese', 'Korean', 'Original', 'Japanese'];

function CustomMenuItem({ children, ...rest }: MenuItemProps) {
    return (
        <MenuItem
            sx={{
                '&:first-of-type': {
                    borderTopLeftRadius: '15px',
                },
            }}
            css={StyledMenuItem}
            {...rest}
            disableRipple
        >
            <Typography css={StyledMenuText} variant="h4" variantMapping={{ h4: 'span' }}>
                {children}
            </Typography>
        </MenuItem>
    );
}

function GenresList() {
    const { dismissMenu } = useMegaMenu();
    const history = useHistory();

    const goToGenre = (genre: string) => {
        const search = new URLSearchParams();
        search.set('genre', genre);

        history.push({
            pathname: '/novels',
            search: search.toString(),
        });

        dismissMenu();
    };

    return (
        <List component="div">
            <Grid height="500px" container flexDirection="column" flexWrap="wrap">
                {Genres.map((genre, ctr) => (
                    <Grid item key={genre}>
                        <ListItem
                            css={StyledListItem}
                            onClick={() => goToGenre(genre)}
                            sx={{ borderRadius: '4px' }}
                            component="div"
                            button
                        >
                            <ListItemText>{genre}</ListItemText>
                        </ListItem>
                    </Grid>
                ))}
            </Grid>
        </List>
    );
}

function LanguagesList() {
    const { dismissMenu } = useMegaMenu();
    const history = useHistory();

    const goToLanguage = (genre: string) => {
        const search = new URLSearchParams();
        search.set('language', genre);

        history.push({
            pathname: '/novels',
            search: search.toString(),
        });

        dismissMenu();
    };

    return (
        <List component="div">
            <Grid height="500px" container flexDirection="column" flexWrap="wrap">
                {Languages.map((language, ctr) => (
                    <Grid item key={language}>
                        <ListItem
                            css={StyledListItem}
                            onClick={() => goToLanguage(language)}
                            sx={{ borderRadius: '4px' }}
                            component="div"
                            button
                        >
                            <ListItemText>{language}</ListItemText>
                        </ListItem>
                    </Grid>
                ))}
            </Grid>
        </List>
    );
}

export default function NovelsMenu() {
    const [selectedItem, setSelectedItem] = useState<NovelMenuItemType>('genres');

    return (
        <Grid container flexDirection="row">
            <Grid flex={1} item>
                <MenuList disablePadding>
                    <CustomMenuItem
                        selected={selectedItem === 'genres'}
                        onMouseOver={() => setSelectedItem('genres')}
                        onClick={() => setSelectedItem('genres')}
                    >
                        Genres
                    </CustomMenuItem>
                    <CustomMenuItem
                        selected={selectedItem === 'languages'}
                        onMouseOver={() => setSelectedItem('languages')}
                        onClick={() => setSelectedItem('languages')}
                    >
                        Languages
                    </CustomMenuItem>
                </MenuList>
            </Grid>
            <Grid flex={2} item>
                <Box css={StyledNovelsMenuBox} paddingLeft="5px">
                    {selectedItem === 'genres' && <GenresList />}
                    {selectedItem === 'languages' && <LanguagesList />}
                </Box>
            </Grid>
        </Grid>
    );
}
