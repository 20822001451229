import { Message } from 'google-protobuf';
import type { Empty } from 'google-protobuf/google/protobuf/empty_pb';
import { StringValue } from 'google-protobuf/google/protobuf/wrappers_pb';
import * as grpc from 'grpc-web';
import { useRef } from 'react';
import useConstant from 'use-constant';
import { ApiErrorDetailItem } from '../_proto/Protos/errors_pb';

/**
 * Custom.
 *
 * Error details key in the gRPC response / message
 */
const ErrorDetailsKey = 'errordetails-bin';

if (typeof window === 'undefined') {
    global.XMLHttpRequest = require('xhr2');
}

/**
 * Custom type.
 */
export type GrpcClientType<T> = new (hostname: string) => T;

/**
 * Custom type.
 */
export type GrpcRequestType<T> = new () => T;

/**
 * Custom hook.
 *
 * Creates gRPC client pointing to the gRPC api host
 */
export const useGrpcClient = <T>(ClientType: GrpcClientType<T>): T => {
    const client = useConstant(() => {
        return new ClientType(process.env.REACT_APP_PUBLIC_GRPC_HOST!);
    });

    return client;
};

/**
 * Custom type.
 */
export type AsObject<T> = T extends { toObject: () => infer U } ? { [key in keyof U]?: U[key] } : never;

/**
 * Custom type.
 */
export type ProtoMessageGetFunction<K> = `get${Capitalize<string & K>}`;

/**
 * Custom type.
 */
export type ProtoMessage<T> = T extends Array<infer U>
    ? U extends { toObject: () => unknown }
        ? MessageObject<U>[]
        : T
    : T extends { toObject: () => infer U }
    ? {
          [K in keyof U]?: ProtoMessageGetFunction<K> extends keyof T
              ? T[ProtoMessageGetFunction<K>] extends () => infer W
                  ? W extends Message
                      ? MessageObject<W>
                      : ProtoMessage<W>
                  : T[ProtoMessageGetFunction<K>]
              : never;
      }
    : T;

/**
 * Custom type.
 */
export type MessageObject<T> = { message: () => T } & ProtoMessage<T>;

/**
 * Custom type.
 */
export type AsMessageOrObject<T> = T extends { message: () => infer W } ? MessageObject<W> : ProtoMessage<T>;

/**
 * Custom function.
 *
 * Just checks if the options provided is of gRPC Message object type.
 */
function isMessageObject(options: any): options is { message: () => Message } {
    return typeof options == 'object' && options !== null && 'message' in options;
}

/**
 * Custom function.
 *
 * Sets gRPC request options.
 */
export function setMessageFields<TRequest extends Message>(request: TRequest, options: AsMessageOrObject<TRequest>) {
    for (const field of Object.keys(options)) {
        const fieldName = `${field[0].toUpperCase()}${field.substring(1)}`;

        const fieldValue = options[field];
        const setFunc: Function = request[`set${fieldName}`];

        if (isMessageObject(fieldValue)) {
            const { message, ...fieldOptions } = fieldValue;

            const fieldValueMessage = message();

            setMessageFields(fieldValueMessage, fieldOptions);

            setFunc.call(request, fieldValueMessage || undefined);
        } else if (Array.isArray(fieldValue)) {
            const values: Message[] = [];

            for (const arrVal of fieldValue) {
                if (isMessageObject(arrVal)) {
                    const { message, ...fieldOptions } = arrVal;

                    const fieldValueMessage = message();

                    setMessageFields(fieldValueMessage, fieldOptions);

                    values.push(fieldValueMessage);
                } else {
                    values.push(arrVal);
                }
            }

            setFunc.call(request, values || undefined);
        } else {
            setFunc.call(request, options[field] || undefined);
        }
    }

    return request;
}

/**
 * Custom function.
 */
export const areGrpcRequestsEqual = <TRequest extends Message>(
    a: AsMessageOrObject<TRequest>,
    b: AsMessageOrObject<TRequest>
) => {
    if (!a && !b) {
        return true;
    }

    if (!a || !b) {
        return false;
    }

    const aKeys = Object.keys(a);
    const bKeys = Object.keys(b);

    if (aKeys.length !== bKeys.length) {
        return false;
    }

    if (!aKeys.every(a => bKeys.includes(a))) {
        return false;
    }

    for (const field of aKeys) {
        const firstField = a[field];
        const secondField = b[field];

        if (firstField instanceof Message || secondField instanceof Message) {
            continue;
        }

        let isEqual = firstField === secondField;

        if (isMessageObject(firstField) || isMessageObject(secondField)) {
            isEqual = areGrpcRequestsEqual(firstField, secondField);

            if (!isEqual) {
                return false;
            }
        }

        if (!isEqual) {
            return false;
        }
    }

    return true;
};

/**
 * Custom function.
 *
 * Creates gRPC request.
 */
export const createGrpcRequest = <TRequest extends Message>(
    request: TRequest,
    options: AsMessageOrObject<TRequest>
): TRequest => {
    setMessageFields(request, options);

    return request;
};

/**
 * Custom function.
 *
 * Returns protobuf StringValue of the given string.
 */
export const createStringValue = (strVal?: string | null) => {
    if (!strVal) {
        return undefined;
    }

    const value = new StringValue();
    value.setValue(strVal);

    return value;
};

/**
 * Custom hook.
 *
 * Use it to create gRPC request.
 * Returns original request in case of duplicate requests.
 */
export const useGrpcRequest = <TRequest extends Message>(
    getRequest: TRequest | (() => TRequest),
    options: AsMessageOrObject<TRequest>
): TRequest => {
    const initialRequest = useConstant(() => {
        const innerRequest = typeof getRequest === 'function' ? getRequest() : getRequest;

        return setMessageFields(innerRequest, options);
    });

    const requestOptions = useRef(options);
    const request = useRef(initialRequest);

    const { current: currentRequest } = request;

    if (!areGrpcRequestsEqual(requestOptions.current, options)) {
        requestOptions.current = options;

        const newMessage = setMessageFields(initialRequest.clone(), options);

        if (!Message.equals(currentRequest, newMessage)) {
            request.current = newMessage;
        }
    }

    return request.current;
};

/**
 * Custom type.
 */
export type GrpcRequestFunc<TRequest, TResponse> = (
    req: TRequest,
    metadata: grpc.Metadata,
    cb: (err: grpc.Error, res: TResponse) => void
) => grpc.ClientReadableStream<TResponse>;

/**
 * Custom.
 */
let enableDevTools = clients => {};

if (typeof window !== 'undefined') {
    enableDevTools = (window as any).__GRPCWEB_DEVTOOLS__ || (() => {});
}

/**
 * Custom function.
 *
 * Returns gRPC host url depending on whether the client is a browser or not.
 */
export function getGrpcHost(): string {
    return typeof window !== 'undefined' ? process.env.REACT_APP_GRPC_HOST! : process.env.GRPC_HOST!;
}

/**
 * Custom function.
 *
 * Makes gRPC request and instead of returning protobuf Message,
 * it resolves the protobuf Message to object and returns that as promise.
 */
export function grpcRequestAsObject<TClient, TRequest extends Message = Empty, TResponse extends Message = Empty>(
    Client: GrpcClientType<TClient>,
    getMethodInfo: (client: TClient) => GrpcRequestFunc<TRequest, TResponse>,
    request: TRequest,
    metadata: grpc.Metadata = {}
): Promise<AsObject<TResponse>> {
    return grpcRequest(Client, getMethodInfo, request, metadata).then(res => res.toObject() as AsObject<TResponse>);
}

/**
 * Custom function.
 *
 * Makes gRPC request and returns promise of protobuf Message type.
 */
export function grpcRequest<TClient, TRequest extends Message = Empty, TResponse extends Message = Empty>(
    Client: GrpcClientType<TClient>,
    getMethodInfo: (client: TClient) => GrpcRequestFunc<TRequest, TResponse>,
    request: TRequest,
    metadata: grpc.Metadata = {}
): Promise<TResponse> {
    let requestStream: grpc.ClientReadableStream<TResponse> | null = null as any;

    const client = new Client(getGrpcHost());

    enableDevTools([client]);

    const promise = new Promise<TResponse>((resolve, reject) => {
        let respMetadata: grpc.Metadata | null | undefined = null;

        const methodInfo = getMethodInfo(client);

        let error: grpc.Error | null = null;
        let response: TResponse | null = null;

        const stream: grpc.ClientReadableStream<TResponse> = methodInfo.call(client, request, metadata, (err, res) => {
            if (err) {
                error = err;
            } else if (res) {
                response = res;

                resolve(response!);
            }
        });

        requestStream = stream;

        stream.on('status', status => {
            respMetadata = status.metadata;

            if (error) {
                if (respMetadata && respMetadata[ErrorDetailsKey]) {
                    const bin = respMetadata[ErrorDetailsKey];

                    const u8Array = Message.bytesAsU8(bin);
                    const errorDetails = ApiErrorDetailItem.deserializeBinary(u8Array);

                    reject(new GrpcError(errorDetails.getDescription(), error.code, errorDetails));
                } else {
                    const response = new ApiErrorDetailItem();
                    response.setDescription('A network error has occurred.');
                    response.setErrorsList(error.message ? [error.message] : []);

                    reject(new GrpcError(error.message, error.code, response));
                }
            }
        });
    });

    (promise as any).cancel = requestStream?.cancel;

    return promise;
}

/**
 * Custom class.
 *
 * It extends the error object.
 */
export class GrpcError extends Error {
    constructor(message: string, public status: number, public response: ApiErrorDetailItem | null = null) {
        super(message);
    }
}
