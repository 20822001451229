import { css, Theme } from '@emotion/react';

export const StyledSwitchContainer = (theme: Theme) =>
    css({
        cursor: 'pointer',
        //outline: '0!important',
        //boxShadow: 'none!important',
        userSelect: 'none',
        WebkitTapHighlightColor: 'transparent',
    });
