import { css, Theme } from '@emotion/react';

export const ContentCenter = (theme: Theme) =>
    css({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
    });

export const ContentAlignCenter = (theme: Theme) =>
    css({
        display: 'flex',
        alignItems: 'center',
    });

export const HeroHighlightContainer = (theme: Theme) =>
    css({
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        //paddingBottom: theme.spacing(1.5),
        position: 'relative',
        background: theme.palette.mode === 'light' ? '#F6F6F6' : '#0c0c0c',
        '&:before': {
            right: 0,
        },
        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            height: 'calc(100% + 38px)',
            width: '100vw',
            margin: '-38px',
            top: 0,
            bottom: 0,
            background: theme.palette.mode === 'light' ? '#F6F6F6' : '#0c0c0c',
            zIndex: -1,
        },
        '&:after': {
            left: 0,
        },
        '& .MuiBreadcrumbs-root': {
            //marginBottom: theme.typography.pxToRem(25),
        },
    });

export const StyledNetworkErrorContainer = (theme: Theme) =>
    css(ContentCenter(theme), {
        padding: '5px',
        color: theme.palette.background.default,
        backgroundColor: theme.palette.text.primary,
        position: 'fixed',
        top: '0',
        left: '0',
        right: '0',
        zIndex: 9999,
        textAlign: 'center',
    });

export const ContentSection = (theme: Theme) =>
    css({
        margin: `${theme.spacing(6, 0)} !important`,
        '& > *': {
            margin: `${theme.spacing(2, 0)} !important`,
        },
        '& a': {
            color: '#0ca0ff',
        },
    });
